import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  translations: {},
  currentLocale: 'en-GB',
};

export const languageSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    setTranslations: (state, action) => {
      state.translations = { ...state.translations, ...action.payload };
    },
    setCurrentLocale: (state, action) => {
      state.currentLocale = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTranslations, setCurrentLocale } = languageSlice.actions;

export default languageSlice.reducer;
