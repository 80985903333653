import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { COMPANY_ROUTES, ROUTES } from '../../../app/config/constants';
import SpinnerLogo from '../../../components/UI/Spinner/SpinnerLogo';
import { useStore } from '../../../hooks';

const RenderRoutes = ({ routes }) => {
  const { isAuth, companySelected, user, hasToken } = useStore();
  const isRoute = sessionStorage.getItem('location');

  const renderRoute = ({ path, exact, private: isPrivate, isForGuest, component: Component }) => {
    const currentSpace = localStorage.getItem('space');
    const isPage =
      path !== ROUTES.registrationStep2DefineSpace &&
      path !== ROUTES.registrationStep1Back &&
      path !== ROUTES.createDB &&
      path !== ROUTES.spaceChoice &&
      path !== ROUTES.registrationStep3aGetOneTimePassword &&
      path !== ROUTES.registrationStep3aValidationPassword;
    return (
      <Route
        key={path}
        path={`${isPrivate && isPage ? COMPANY_ROUTES[companySelected] : ''}${path}`}
        exact={exact}
        render={(props) => {
          if (isRoute && hasToken) {
            <Redirect to={isRoute} />;
          } else if (
            isAuth &&
            user?.id &&
            user?.databasePreference === 'own' &&
            user?.status === 'active' &&
            !user?.database &&
            path !== ROUTES.createDB
          ) {
            return <Redirect to={ROUTES.createDB} />;
          } else if (isAuth && user?.id && user?.status === 'verification' && isPage) {
            return <Redirect to={ROUTES.registrationStep2DefineSpace} />;
          } else if (
            user?.databasePreference &&
            user?.status === 'active' &&
            !currentSpace &&
            !user?.databases &&
            isPage
          ) {
            return <Redirect to={ROUTES.spaceChoice} />;
          } else if (isPrivate && !hasToken && !isForGuest) {
            return <Redirect to={ROUTES.login} />;
          } else if (
            !isPrivate &&
            isAuth &&
            isForGuest &&
            (currentSpace || (user?.databasePreference === ('none' || 'request') && user?.databases.length === 0))
          ) {
            return <Redirect to={ROUTES.home} />;
          }

          return <Component {...props} />;
        }}
      />
    );
  };

  return (
    <Suspense fallback={<SpinnerLogo size={50} fullPage />}>
      <Switch>
        {routes.map(renderRoute)}
        <Redirect to={`${COMPANY_ROUTES[companySelected] || COMPANY_ROUTES.Roof}${ROUTES.home}`} />
      </Switch>
    </Suspense>
  );
};

export default RenderRoutes;
