import { makeStyles } from '@material-ui/styles';

import {
  DRAWER_CLOSE,
  DRAWER_WIDTH,
  SCREEN_HEIGHT_1024,
  SCREEN_HEIGHT_1280,
  TOOLBAR_HEIGHT,
} from '../../../app/config/constants';
import fontSizes from '../../../theme/fontSizes';
import pallete from '../../../theme/pallete';

const useStyles = makeStyles((theme) => {
  return {
    appBarShift: {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      position: 'relative',
      width: DRAWER_WIDTH,
      flexShrink: 1,
      height: '100%',
      whiteSpace: 'nowrap',
      '& *': {
        fontSize: `${theme.fontSizes.sm}px`,
        fontFamily: 'Open Sans',
        lineHeight: '19px',
        fontWeight: 600,
        borderRight: 'none',
      },
    },
    drawerOpen: {
      background: pallete.backgroundWhite,
      position: 'absolute',
      top: TOOLBAR_HEIGHT / 2,
      overflow: 'hidden',
      left: 0,
      height: '100%',
      boxShadow: (props) =>
        props.big ? 'none' : '4px 2px 10px 5px rgb(0 0 0 / 35%)',
      minHeight: (props) =>
        props.small ? SCREEN_HEIGHT_1280 - 40 : SCREEN_HEIGHT_1024 - 40,
      width: DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      zIndex: 100,
    },
    drawerClose: {
      background: pallete.primary,
      position: 'absolute',
      top: TOOLBAR_HEIGHT / 2,
      overflow: 'hidden',
      left: 0,
      height: '100%',
      minHeight: (props) =>
        props.small ? SCREEN_HEIGHT_1280 - 40 : SCREEN_HEIGHT_1024 - 40,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: DRAWER_CLOSE,
    },
    iconContainer: {
      '&>*': {
        marginRight: -3,
      },
    },
    iconContainerOpen: {
      transition: 'all 0.2s ease-in-out',
      '&>*': {
        marginRight: -3,
      },
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
    toolbar: {
      padding: '12px 15px 30px 10px',
      marginLeft: 'auto',
    },
    helpButton: {
      position: 'absolute',
      bottom: '45px',
      left: '17px',
      marginBottom: 45,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '85px',
      height: '25px',
      borderRadius: '10px',
      backgroundColor: pallete.primary,
      transition: 'all 0.2s ease-in-out',
      '&>svg': {
        marginRight: '12px',
      },
      '&>p': {
        color: pallete.backgroundWhite,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: fontSizes.sm,
        lineHeight: '19px',
      },
    },
    helpButtonVertical: {
      transform: 'rotate(-90deg)',
      bottom: '80px',
      left: '-19px',
    },
    iconButton: {
      padding: 'initial',
    },
    drawerDivider: {
      backgroundColor: pallete.backgroundWhite,
    },
  };
});

export default useStyles;
