import { makeStyles } from '@material-ui/styles';

import {
  DRAWER_CLOSE,
  DRAWER_WIDTH,
  SCREEN_HEIGHT_1024,
  SCREEN_HEIGHT_1280,
  TOOLBAR_HEIGHT,
} from '../../app/config/constants';
import fontSizes from '../../theme/fontSizes';
import imageBlue from '../../theme/images/Pattern-blue-S.png';
import imageGrey from '../../theme/images/Pattern-grey-S.png';
import pallete from '../../theme/pallete';

const useStyles = makeStyles(() => ({
  containerActiveMessageContainer: {
    justifySelf: 'center',
    border: `2px solid ${pallete.backgroundWhite}`,
    background: pallete.backgroundGrey,
    minHeight: 150,
    width: 820,
    minWidth: 820,
    overflowX: 'auto',
    height: 225,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    margin: '10vh auto 0',
  },
  containerActiveMessageContainerTooSmall: {
    position: 'absolute',
    top: '5%',
    left: 0,
    right: 0,
    padding: '25px 10px',
    border: `2px solid ${pallete.backgroundWhite}`,
    background: pallete.backgroundGrey,
  },
  pageContainerSmall: {
    background: pallete.backgroundGrey,
    position: 'relative',
    width: 1024,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pageContainerLarge: {
    width: 1280,
  },
  page: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: (props) => (props.isAuth ? DRAWER_CLOSE : 0),
    paddingTop: TOOLBAR_HEIGHT,
  },
  biggerPage: {
    paddingLeft: `${DRAWER_WIDTH}px !important`,
  },
  pageNoPadding: {
    paddingLeft: '0 !important',
  },
  mainContent: {
    display: 'grid',
    width: '100%',
    minHeight: (props) => (props.big ? SCREEN_HEIGHT_1280 : SCREEN_HEIGHT_1024),
    position: 'relative',
  },
  footer: {
    width: '100%',
    zIndex: 1300,
    height: '19px',
    backgroundColor: pallete.secondary1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 15px',
    '& p': {
      color: pallete.backgroundWhite,
      fontFamily: 'Open Sans',
      fontWeight: 500,
      lineHeight: '15px',
      fontSize: fontSizes.extra11,
    },
  },
  bgPattern: {
    background: (props) => {
      return (props?.big || props.isChoice) && !props.smaller ? `url(${imageGrey})` : `url(${imageBlue})`;
    },
    backgroundColor: `${pallete.grey3} !important`,
    backgroundSize: (props) => ((props?.big || props.isChoice) && !props.smaller ? '150px' : '200px'),
    height: '100vh',
    overflowY: 'auto',
  },
  appContent: {
    margin: '0 auto',
    boxShadow: '6px 5px 12px 3px rgba(0,0,0,0.64)',
  },
}));

export default useStyles;
