import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import axios from 'axios';
import { toast } from 'react-toastify';

import { API_HOST } from '../app/config/constants';

// eslint-disable-next-line no-undef
const { buildAxiosFetch } = require('@lifeomic/axios-fetch');

const httpLink = createUploadLink({
  // eslint-disable-next-line no-undef
  uri: `${process.env.REACT_APP_GRAPHQL_URL || API_HOST || ''}`,
  fetch: buildAxiosFetch(axios, (config, input, init) => ({
    ...config,
    onUploadProgress: init.onUploadProgress,
    onError: (e) => console.log('ERROR IN FETCH...', e),
  })),
});
const errorLink = onError(({ networkError, graphQLErrors }) => {
  toast(`${networkError || ''}${graphQLErrors?.[0].message || ''}`, {
    type: 'error',
  });
  console.log('graphQLErrors', graphQLErrors);
  console.log('networkError', networkError);
  // if (networkError || graphQLErrors[0]) {
  //   useErrorsStore.setState({
  //     hasError: true,
  //     error: networkError || graphQLErrors[0],
  //   });
  // }
});
const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
