import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    },
    updateUserData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData, updateUserData } = userSlice.actions;

export default userSlice.reducer;
