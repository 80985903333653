import React from 'react';

import pallete from '../../pallete';

const Wizards = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <path
          {...st0}
          d='M28.3,4.9l-0.9,2.7c-0.1,0.3-0.1,0.6,0.1,0.9l1.7,2.3c0.5,0.7,0,1.6-0.8,1.6l-2.9,0c-0.3,0-0.6,0.2-0.8,0.4
		l-1.7,2.4c-0.5,0.7-1.5,0.5-1.7-0.3l-0.9-2.8c-0.1-0.3-0.3-0.6-0.6-0.6L17,10.7c-0.8-0.2-0.9-1.3-0.3-1.7l2.4-1.7
		c0.3-0.2,0.4-0.5,0.4-0.8l0-2.9c0-0.8,0.9-1.3,1.6-0.8l2.3,1.7c0.3,0.2,0.6,0.2,0.9,0.1l2.7-0.9C27.8,3.4,28.6,4.2,28.3,4.9z'
        />
        <line {...st0} x1='20.3' y1='11.7' x2='2.6' y2='29.4' />
        <g>
          <line {...st1} x1='6.6' y1='7.6' x2='10.4' y2='7.6' />
          <line {...st1} x1='8.5' y1='5.7' x2='8.5' y2='9.5' />
        </g>
        <line {...st1} x1='14.5' y1='3.3' x2='14.5' y2='5' />
        <line {...st1} x1='12' y1='12.9' x2='12' y2='14.6' />
        <g>
          <line {...st1} x1='17.4' y1='21.5' x2='21.2' y2='21.5' />
          <line {...st1} x1='19.3' y1='19.6' x2='19.3' y2='23.4' />
        </g>
        <line {...st1} x1='25.3' y1='17.3' x2='25.3' y2='18.9' />
        <line {...st1} x1='22.8' y1='26.9' x2='22.8' y2='28.5' />
      </g>
    </svg>
  );
};

export default Wizards;
