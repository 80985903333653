import React from 'react';

import pallete from '../../pallete';

const Recepies = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
  className,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
      className={className}
    >
      <g>
        <g>
          <path
            {...st0}
            d='M25.6,28.1c0,0.7-0.5,1.2-1.2,1.2h-3.8H7.5c-0.3,0-0.6-0.1-0.8-0.4c-0.2-0.2-0.3-0.5-0.3-0.8V3.9
			c0-0.7,0.5-1.2,1.2-1.2h12.2l5.9,5.9V28.1z'
          />
          <path
            {...st0}
            d='M25.6,8.6H21c-0.7,0-1.2-0.5-1.2-1.2V2.7L25.6,8.6z'
          />
        </g>
        <g>
          <path
            {...st1}
            d='M11.6,14.3L11.6,14.3c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h0c0.5,0,0.9,0.4,0.9,0.9v0
			C12.5,13.9,12.1,14.3,11.6,14.3z'
          />
          <line {...st1} x1='14.8' y1='13.4' x2='21.3' y2='13.4' />
          <path
            {...st1}
            d='M11.6,18.9L11.6,18.9c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h0c0.5,0,0.9,0.4,0.9,0.9v0
			C12.5,18.5,12.1,18.9,11.6,18.9z'
          />
          <line {...st1} x1='14.8' y1='18' x2='21.3' y2='18' />
          <path
            {...st1}
            d='M11.6,23.5L11.6,23.5c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h0c0.5,0,0.9,0.4,0.9,0.9v0
			C12.5,23.1,12.1,23.5,11.6,23.5z'
          />
          <line {...st1} x1='14.8' y1='22.6' x2='21.3' y2='22.6' />
        </g>
      </g>
    </svg>
  );
};

export default Recepies;
