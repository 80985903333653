export { default as createObjByPath } from './createObjByPath';
export * from './date';

export const getLanguageDirection = (language) => {
  let direction = 'ltr';

  if (language === 'ar') {
    direction = 'rtl';
  }

  return direction;
};

export const getToken = () => localStorage.getItem('token');
export const setToken = (token) => localStorage.setItem('token', token);
export const removeToken = () => localStorage.removeItem('token');

export const setLanguage = (language) => {
  const direction = getLanguageDirection(language);

  document.body.classList.add(direction);

  if (direction === 'rtl') {
    document.body.classList.remove('ltr');
  } else {
    document.body.classList.remove('rtl');
  }
};
