import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: Number(localStorage.getItem('space')) || null,
  name: '',
  recalculateData: '',
  howOften: '',
  declareTracesOfAllergens: false,
  numberFormat: '',
  countOfDecimalDigits: '',
  mainUnit: null,
  usedUnits: [],
};

export const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    setSpaceSettings: (state, action) => {
      // Get selected units in use for the current space
      const selectedUnitsIds = action.payload.getSelectedDatabaseUnits.map((item) => item.id);
      const selectedUnits = action.payload.usedUnits.filter((item) => selectedUnitsIds.includes(item.id));

      const declareTraces = action.payload.declareTracesOfAllergens === 'yes';

      state.name = action.payload.name;
      state.recalculateData = action.payload.recalculateData;
      state.howOften = action.payload.howOften;
      state.declareTracesOfAllergens = declareTraces;
      state.numberFormat = action.payload.numberFormat;
      state.countOfDecimalDigits = action.payload.countOfDecimalDigits;
      state.mainUnit = action.payload.unit;
      state.usedUnits = selectedUnits;
    },
    setSpaceId: (state, action) => {
      // Set space id when logging into specific space
      state.id = Number(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSpaceSettings, setSpaceId } = spaceSlice.actions;

export default spaceSlice.reducer;
