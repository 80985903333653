import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  missingFIDModal: {
    data: {},
    isOpen: false,
    reinitializeFIDs: false,
  },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setReinitializeFIDs: (state, action) => {
      const { reinitializeFIDs, modalName } = action.payload;
      state[modalName].reinitializeFIDs = reinitializeFIDs;
    },

    setModalOpen: (state, action) => {
      const { isOpen, modalName } = action.payload;
      state[modalName].isOpen = isOpen;
    },
    setModalData: (state, action) => {
      const { modalData, modalName } = action.payload;
      state[modalName].data = modalData;
    },
    resetModalData: (state, action) => {
      const { modalName } = action.payload;
      state[modalName].data = initialState[modalName].data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setModalOpen, setModalData, resetModalData, setReinitializeFIDs } = modalSlice.actions;

export default modalSlice.reducer;
