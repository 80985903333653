import { makeStyles } from '@material-ui/styles';

import fontSizes from '../../../theme/fontSizes';
import pallete from '../../../theme/pallete';

const useStyles = makeStyles(() => {
  return {
    typoStyled: ({
      fontFamily = "'Open Sans', sans-serif",
      fontSize = 'md',
      letterSpacing = 0,
      textAlign = 'left',
      wrap = false,
      color = 'primary',
      fontWeight = 'normal',
      isCursorPointer,
      lineHeight,
      m,
    }) => ({
      fontFamily,
      fontWeight,
      color: pallete[color],
      fontSize: fontSizes[fontSize],
      letterSpacing,
      textAlign,
      lineHeight: lineHeight ? `${lineHeight}px` : 1.5,
      flexWrap: wrap ? 'wrap' : 'nowrap',
      margin: m ? m : 'initial',

      ...(isCursorPointer
        ? {
            '&:hover': {
              cursor: 'pointer',
            },
          }
        : {}),
    }),
  };
});

export default useStyles;
