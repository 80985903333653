import { gql } from '@apollo/client';

export const SignInGQL = gql`
  query signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      user {
        id
        firstName
        lastName
        avatar
        email
        status
        country
        languageId
        databasePreference
        location
        database {
          id
          name
          owner {
            avatar
          }
        }
        internalRoles {
          internalRoleId
        }
      }
    }
  }
`;

export const GetCurrentUserGQL = gql`
  query {
    getCurrentUser {
      id
      firstName
      lastName
      avatar
      email
      status
      country
      languageId
      databasePreference
      location
      database {
        id
        name
        owner {
          avatar
        }
      }
      internalRoles {
        internalRoleId
      }
    }
  }
`;
export const GetUserNotificationsGQL = gql`
  query {
    getUserNotificationSettings {
      emailNotifications
      pushNotifications
      typeEmailNotifications
    }
  }
`;
export const GetLanguagesGQL = gql`
  query {
    getLanguages {
      name
      fullName
      available
    }
  }
`;

export const getUsersGQL = gql`
  query getUsers($search: String!) {
    getUsers(search: $search) {
      id
      firstName
      lastName
      avatar
      email
      status
      country
      languageId
      language {
        name
        fullName
        available
      }
      database {
        id
      }
      databasePreference
    }
  }
`;
export const getUnitsGQL = gql`
  query {
    getUnits {
      name
      type
      fullName
      coefficient
    }
  }
`;

export const getUserDataBasesGQL = gql`
  query getUserDatabases {
    getUserDatabases {
      id
      owner {
        avatar
      }
      name
      description
    }
  }
`;

export const getOwnersGQL = gql`
  query getOwners($search: String!) {
    getOwners(search: $search) {
      id
      firstName
      lastName
      avatar
      email
      status
      country
      location
      languageId
      database {
        id
      }
      language {
        name
      }
      databasePreference
    }
  }
`;

export const getUserDataBaseReferenceGQL = gql`
  query getUserDatabaseReferences {
    getUserDatabaseReferences {
      id
      database {
        id
        name
        country
        owner {
          firstName
          lastName
          avatar
          email
          id
        }
      }
      status
      isAdminRequested
    }
  }
`;

export const getOwnerDataBaseReferenceGQL = gql`
  query getOwnerDatabaseReferences {
    getOwnerDatabaseReferences {
      id
      userId {
        avatar
        firstName
        lastName
        email
        id
      }
      database {
        id
        name
        country
      }
      status
      isAdminRequested
    }
  }
`;

export const getTableOptionsForUpload = gql`
  query {
    getListOfDatabases {
      name
    }
  }
`;

export const getBoughtItemsGQL = gql`
  query getBoughtItems($input: GetBoughtItemsInput!) {
    getBoughtItems(input: $input) {
      data {
        rows {
          id
          name
          relatedIngredient {
            name
            id
          }
          databaseId
        }
        count
      }
      settings
    }
  }
`;

export const getProductsGQL = gql`
  query getProducts($input: GetProductInput!) {
    getProducts(input: $input) {
      data {
        rows {
          id
          name
          group {
            name
            id
          }
          referenceName
          referenceType
          databaseId
        }
        count
      }
      settings
    }
  }
`;

export const getIngridientsGQL = gql`
  query getIngredients($input: GetIngredientsInput!) {
    getIngredients(input: $input) {
      data {
        rows {
          id
          name
          group {
            name
            id
          }
          databaseId
        }
        count
      }
      settings
    }
  }
`;

export const getResipesGQL = gql`
  query getRecipes($input: GetRecipeInput!) {
    getRecipes(input: $input) {
      data {
        rows {
          id
          name
          group {
            name
            id
          }
          databaseId
        }
        count
      }
      settings
    }
  }
`;

export const getTagsGQL = gql`
  query getTags($databaseId: Int!, $limit: Int!, $page: Int!) {
    getTags(databaseId: $databaseId, limit: $limit, page: $page) {
      id
      name
      databaseId
    }
  }
`;

export const getFIDDatabasesGQL = gql`
  query getFIDDatabases($exclude: [Int]) {
    getFIDDatabases(exclude: $exclude) {
      name
      id
    }
  }
`;

export const getFIDListItemsGQL = gql`
  query getFIDListItems($FIDDatabaseId: Int!, $search: String, $page: Int) {
    getFIDListItems(FIDDatabaseId: $FIDDatabaseId, search: $search, page: $page) {
      id
      type
      mainName
      languageId
      FIDDatabaseId
    }
  }
`;

export const getSelectedUnitsGQL = gql`
  query getSelectedUnits($databaseId: Int!) {
    getSelectedUnits(databaseId: $databaseId) {
      id
      name
      fullName
      type
    }
  }
`;

export const getFIDFoodSymbolsGQL = gql`
  query getFIDFoodSymbols($FIDId: ID!) {
    getFIDFoodSymbols(FIDId: $FIDId) {
      vegan
      vegetarian
      lactoseFree
      glutenFree
    }
  }
`;

// TODO: Refactor this file. Divide into folders by modules

export const getDatabaseSettings = gql`
  query getDatabaseSettings($databaseId: Int!) {
    getDatabaseSettings(databaseId: $databaseId) {
      name
      recalculateData
      howOften
      declareTracesOfAllergens
      numberFormat
      countOfDecimalDigits
      unit {
        name
        id
        type
        fullName
        coefficient
        databaseId
      }
      usedUnits {
        id
        name
        fullName
        coefficient
        databaseId
        type
      }
      getSelectedDatabaseUnits {
        id
      }
    }
  }
`;

export const getListItemsById = gql`
  query getFIDListItems($FIDDatabaseId: Int!, $search: String, $page: Int) {
    getFIDListItems(FIDDatabaseId: $FIDDatabaseId, search: $search, page: $page) {
      id
      mainName
      type
      eNumber {
        name: eNumber
      }
    }
  }
`;

export const getFIDAllergens = gql`
  query ($FIDId: ID!, $required: Boolean) {
    getFIDAllergens(FIDId: $FIDId, required: $required) {
      fullName
      id
      required
    }
  }
`;

export const getAllFIDAllergens = gql`
  query ($required: Boolean) {
    getAllFIDAllergens(required: $required) {
      fullName
      id
      required
    }
  }
`;

export const getAllFIDNutriasValues = gql`
  query getAllFIDNutriasValues($required: Boolean) {
    getAllFIDNutriasValues(required: $required) {
      id
      isRequired: required
      label: fullName
      fullName
      unit {
        fullName
        coefficient
        name
      }
    }
  }
`;

export const getFIDNutriasValues = gql`
  query getFIDNutriasValues($FIDId: ID!) {
    getFIDNutriasValues(FIDId: $FIDId) {
      energy
      fat
      saturates
      monoUnsaturates
      polyunsaturates
      transFat
      carbohydrate
      sugars
      polyols
      starch
      fibre
      addedSugars
      protein
      salt
      vitaminA
      vitaminD
      vitaminE
      vitaminK
      vitaminC
      thiamin
      riboflavin
      niacin
      vitaminB6
      folicAcid
      vitaminB12
      biotin
      pantothenicAcid
      potassium
      sodium
      chloride
      calcium
      phosphorus
      magnesium
      iron
      zinc
      copper
      manganese
      fluoride
      selenium
      chromium
      molybdenum
      iodine
    }
  }
`;

export const getBoughtItemGQL = gql`
  query getBoughtItem($boughtItemId: Int!, $databaseId: Int!) {
    getBoughtItem(boughtItemId: $boughtItemId, databaseId: $databaseId) {
      name
      boughtItemSourceListItems {
        FIDId
        FID {
          FIDDatabase {
            name
          }
          mainName
          languageId
          type
          eNumber {
            FIDId
            eNumber
          }
          FIDDatabaseId
        }
      }
      # Package kind of and content section
      selectedDBUnit {
        id
        name
        type
        fullName
      }
      amountInPackage
      selectedUnitOfPackage {
        name
        translationId
        fullName
      }
      # Allergens section
      allergenSource {
        id
        FIDDatabaseId
        FIDDatabase {
          name
          id
        }
      }
      boughtItemAllergenItems {
        boughtItemId
        FIDAllergenItemId
        traces
        containsAllergen
        allergen: FIDAllergenItem {
          fullName
          isRequired: required
        }
      }
      # Nutritional Values section
      nutritionalValueSource {
        mainName
        FIDDatabase {
          name
        }
      }
      boughtItemNutriasItems {
        energy
        fat
        saturates
        monoUnsaturates
        polyunsaturates
        transFat
        carbohydrate
        sugars
        polyols
        starch
        fibre
        addedSugars
        protein
        salt
        vitaminA
        vitaminD
        vitaminE
        vitaminK
        vitaminC
        thiamin
        riboflavin
        niacin
        vitaminB6
        folicAcid
        vitaminB12
        biotin
        pantothenicAcid
        potassium
        sodium
        chloride
        calcium
        phosphorus
        magnesium
        iron
        zinc
        copper
        manganese
        fluoride
        selenium
        chromium
        molybdenum
        iodine
      }
      # Ingredient List Section
      ingredientListSource {
        FIDDatabase {
          name
          id
        }
      }
      declarationName
      kindOfIngredientList
      boughtItemIngredientListItems {
        FIDId
        FID {
          mainName
          type
        }
        type
        tierSelected
        amount
        eNumber
        additiveClass
        organic
        declareType
        hierarchicalPath
      }
      asTextIngredientList
      boughtItemIngredientAdditivesListItems {
        FIDId
        FID {
          mainName
          type
        }
        type
        tierSelected
        eNumber
        additiveClass
        organic
        declareType
        hierarchicalPath
      }
      # Food Symbols Section
      foodSymbolsSource {
        id
        FIDDatabase {
          name
        }
      }
      isReconstituted
      reconstitutedIngredientName {
        mainName
        type
        FIDItemId: FIDDatabaseId
      }
      reconstitutedAmountOfProduct
      reconstitutedAmountOfWater
      boughtItemFoodSymbolsItems {
        vegan
        vegetarian
        lactoseFree
        glutenFree
      }
      confirmationSymbols
    }
  }
`;

export const getTranslationsByLanguage = gql`
  query getTranslationsByLanguage($languageCode: String!) {
    translations: getTranslationsByLanguage(languageCode: $languageCode)
  }
`;

export const getIngredientGroupsByDatabase = gql`
  query getIngredientGroupsByDatabase(
    $search: String
    $limit: Int
    $page: Int
    $sortBy: String
    $sortType: String
    $databaseId: Int!
  ) {
    ingredientsGroups: getIngredientGroupsByDatabase(
      search: $search
      limit: $limit
      page: $page
      sortBy: $sortBy
      sortType: $sortType
      databaseId: $databaseId
    ) {
      data {
        rows {
          id
          name
          databaseId
        }
        count
      }
      settings
    }
  }
`;

export const getBoughtItemUnitOfPackage = gql`
  query getBoughtItemUnitOfPackage {
    packageUnits: getBoughtItemUnitOfPackage {
      fullName
      name
      translationId
    }
  }
`;

export const getAdditiveClasses = gql`
  query getAdditiveClasses($search: String, $page: Int!, $limit: Int!) {
    data: getAdditiveClasses(search: $search, page: $page, limit: $limit) {
      name
    }
  }
`;
