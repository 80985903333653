const fontSizes = {
  extra8: 8,
  extra9: 9,
  extra10: 10,
  extra11: 11,
  xs: 12,
  extra13: 13,
  sm: 14,
  extra15: 15,
  ms: 16,
  extra17: 17,
  md: 18,
  ml: 20,
  lg: 22,
  xl: 26,
};

export default fontSizes;
