import React from 'react';

import pallete from '../../pallete';

const Home = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
      style={{
        background: 'transparent',
      }}
    >
      <g>
        <path
          {...st0}
          d='M26.2,7.6L28,8.9c1.3,1,2.1,2.5,2.1,4.2v12.4c0,2.9-2.3,5.2-5.2,5.2H7.2c-2.9,0-5.2-2.3-5.2-5.2V13.1
		C2,11.5,2.7,9.9,4,8.9l8.8-6.7c1.9-1.4,4.5-1.4,6.3,0l4.5,3.4V2.6'
        />
        <g>
          <circle {...st1} cx='13.2' cy='15.9' r='0.4' />
          <circle {...st1} cx='18.8' cy='15.9' r='0.4' />
          <circle {...st1} cx='13.2' cy='20.5' r='0.4' />
          <circle {...st1} cx='18.8' cy='20.5' r='0.4' />
        </g>
      </g>
    </svg>
  );
};

export default Home;
