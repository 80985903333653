const pallete = {
  primary: '#2C2E65',
  secondary1: '#BABC16',
  secondary2: '#700268',
  attention: '#840909',
  attention2: '#BD7800',
  correct: '#177A00',
  error: '#BC0900',
  grey1: '#3B3b3B',
  grey2: '#656665',
  grey3: '#ABABAB',
  textPrimary: '#273339',
  success1: '#99CC33',
  success2: '#2A7D1D',
  backgroundWhite: '#ffffff',
  snowDrift: '#f9f9f9',
  backgroundGrey: '#E5E5E5',
  logoSecondary: '#2B3165',
  slightyGreyBackground: '#F3F3F3',
  bardic: '#840909',
  bloodRed: '#940a00',
  carbonGrey: '#5e5e5e',
  opacityBlack: '#0000008a',
  gainsboro: '#dbdbdb',
  iron: '#d8d8d8',
  apple: '#669933',
  info: '#0EA7C9',
  nutriError: '#CC2200',
  nutriCorrect: '#2A7D1D',
  nutriFont: '#222222',
  semiOliveYellow: '#babc163d',
  lavanderMist: '#E9EBEB', // text-primary with opacity 0.1
  nutriLightgrey: '#D2D2D2',
  nutriLightBlack: '#404040',
  seashell: '#f0f1f1',
  gunsmoke: '#7d8588',
  oasis: '#f1f2d0',
  black: '#000000',
  mercury: '#e5e7e7',
  lightGrey: '#DCDCDC',
  almostBlack: '#020203',
};

export default pallete;
