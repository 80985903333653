import { Box, List, ListItem } from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { useGetCrossAuthToken } from '../../../graphql';
import useStyles from './styles';

function useOutsideAlerter(ref, setOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setOpen]);
}

const CompaniesDropdown = ({ children, content, setValue }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef(null);
  const [getCrossAuthTokenMutation] = useGetCrossAuthToken();

  const handleChange = (listItem) => {
    if (listItem?.value === 'FoodScale') {
      getCrossAuthTokenMutation();
    } else if (!listItem.disabled) {
      setValue(listItem);
      setVisible(false);
    }
  };

  useOutsideAlerter(wrapperRef, setVisible);
  return (
    <div ref={wrapperRef} className={classes.relativeContainer}>
      <Box className={classes.value} onClick={() => setVisible(!visible)}>
        {children}
      </Box>
      {visible && (
        <Box className={classes.drodownContainer}>
          <Box className={classes.contentContainer}>
            <List style={{ padding: 0 }}>
              {content.map((listItem) => {
                const Icon = listItem.icon;
                return (
                  <ListItem
                    key={listItem.value}
                    className={classNames(classes.listItem)}
                    onClick={() => handleChange(listItem)}
                  >
                    <Icon disabled={listItem?.disabled} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default CompaniesDropdown;
