import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedItems: [],
};

export const overviewTablesSlice = createSlice({
  name: 'overviewTables',
  initialState,
  reducers: {
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedItems } = overviewTablesSlice.actions;

export default overviewTablesSlice.reducer;
