import {
  ExitToApp,
  Person,
  Settings,
  Storage,
  SwapHoriz,
} from '@material-ui/icons';

import CookingKids from '../../components/UI/CompaniesDropdown/companyLogosInteractive/CookingKids';
import Declaratio from '../../components/UI/CompaniesDropdown/companyLogosInteractive/Declaratio';
import FoodIngridientsData from '../../components/UI/CompaniesDropdown/companyLogosInteractive/FoodIngridientsData';
import FoodScale from '../../components/UI/CompaniesDropdown/companyLogosInteractive/FoodScale';
import InternalSettings from '../../components/UI/CompaniesDropdown/companyLogosInteractive/InternalSettings';
import Nutrimero from '../../components/UI/CompaniesDropdown/companyLogosInteractive/Nutrimero';
import { ROUTES } from './constants';

export const LanguageMenuOptions = [
  { value: 'de-DE', title: 'DE' },
  { value: 'en-GB', title: 'EN' },
];

export const CompanyOptions = [
  { value: 'Nutrimero', icon: Nutrimero },
  { value: 'Declaratio', icon: Declaratio },

  {
    value: 'FoodIngridientsData',

    icon: FoodIngridientsData,
  },
  { value: 'CookingKids', icon: CookingKids, disabled: true },

  { value: 'FoodScale', icon: FoodScale },
  { value: 'InternalSettings', icon: InternalSettings, disabled: true },
];

export const AvatarMenuOPtions = [
  { title: 'Switch/space', value: 'switch', icon: SwapHoriz },
  { title: 'Notifications', value: ROUTES.myNotifications, icon: Settings },
  { title: 'Profile', value: ROUTES.myProfile, icon: Person },
  { title: 'My DBs', value: ROUTES.extSpacesList, icon: Storage },
  { title: 'Logout', value: 'logout', icon: ExitToApp },
];

export const DashboardNotificationMenuOptions = [
  { value: 'all-notification', title: 'All Notifications' },
];
