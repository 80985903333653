import React from 'react';

import pallete from '../../pallete';

const Nutritions = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <g>
          <path
            {...st0}
            d='M21.1,27.7h8.3c1.3,0,2.1-1.4,1.5-2.5l-3.2-5.5L22,10l-4.6-7.9c-0.7-1.1-2.3-1.1-2.9,0L10,10l-5.6,9.6
			l-3.2,5.5c-0.6,1.1,0.2,2.5,1.5,2.5h8.3'
          />
          <line {...st0} x1='22' y1='10' x2='10' y2='10' />
          <line {...st0} x1='27.6' y1='19.6' x2='18.9' y2='19.6' />
          <polyline {...st0} points='14.8,19.6 13.1,19.6 4.4,19.6 		' />
        </g>
        <g>
          <path
            {...st1}
            d='M16.2,20c0,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.4-0.1-0.7-0.3-1.1-0.5
			c-0.4-0.3-0.8-0.7-1-1.2c-0.4-0.9,0.1-1.7,0.1-1.8c0.1-0.1,0.2-0.1,0.3-0.1c1.1,0.4,1.8,0.9,2.1,1.7C16.6,19.1,16.2,20,16.2,20z'
          />
          <path
            {...st1}
            d='M22,23.9c0,1-0.4,2.5-1,3.8c-0.8,1.7-2,3.1-3.5,3.1c-0.3,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.7-0.1-1,0
			c-0.4,0.1-0.7,0.2-1.1,0.2c-1.5,0-2.7-1.4-3.5-3.1c-0.6-1.3-1-2.8-1-3.8c0-0.5,0.1-1.6,0.6-2.6c0.5-1.1,1.4-1.6,2.6-1.6
			c1.2,0,2.2,0.4,2.4,0.4c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0,0.6-0.1c0.5-0.2,1.4-0.4,2.3-0.4
			c1.2,0,2,0.6,2.6,1.6C22,22.2,22,23.4,22,23.9z'
          />
        </g>
      </g>
    </svg>
  );
};

export default Nutritions;
