import { makeStyles } from '@material-ui/styles';

import { TOOLBAR_HEIGHT } from '../../../app/config/constants';
import fontSizes from '../../../theme/fontSizes';
import pallete from '../../../theme/pallete';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: TOOLBAR_HEIGHT,
    maxHeight: TOOLBAR_HEIGHT,
    minHeight: TOOLBAR_HEIGHT,
    backgroundColor: pallete.secondary1,
    zIndex: theme.zIndex.drawer + 1,
    '&>*': {
      maxHeight: TOOLBAR_HEIGHT,
    },
  },
  badge: {
    '&>span': {
      background: pallete.success1,
    },
  },
  textLink: {
    fontSize: fontSizes.sm,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    lineHeight: '19px',
    margin: 5,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  appBar: {
    position: 'fixed',
    top: 0,
    width: (props) => {
      return props.larger ? 1280 : 1024;
    },
    height: TOOLBAR_HEIGHT,
    maxHeight: TOOLBAR_HEIGHT,
    minHeight: TOOLBAR_HEIGHT,
    right: 'auto',
    left: 'auto',
    zIndex: 1300,
  },
  searchInputContainer: {
    position: 'relative',
    '& svg': {
      position: 'absolute',
      right: '11px',
      top: '8px',
    },
    '& > .Mui-focused': {
      border: `1px solid ${pallete.primary}`,
    },
  },
  searchInput: {
    border: '1px solid transparent',
    padding: '5px 19px',
    width: 290,
    height: 27,
    borderRadius: '30px',
    background: pallete.backgroundWhite,
    fontFamily: 'Open Sans',
    fontSize: fontSizes.sm,
    fontWeight: 600,
  },
  chevy: {
    '&:hover': {
      '&> *': {
        color: 'red !important',
        fill: 'red !important',
      },
    },
  },
  homeLink: {
    height: '100%',
    padding: (props) => (props.isHome ? '10px' : '9px 0 12px 22px'),
    marginRight: '18px',
    background: (props) => (props.isHome ? pallete.backgroundWhite : 'transparent'),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  slogan: {
    padding: '11px 0 8px',
    lineHeight: '27px',
  },
  userProfile: {
    maxWidth: 'initial',
  },
  userEmail: {
    maxWidth: 140,
    flexWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  userName: {
    maxWidth: 140,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  userProfileArrow: {
    paddingLeft: 16,
  },
}));

export default useStyles;
