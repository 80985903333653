import React from 'react';

import pallete from '../../pallete';

const Tools = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <path
          {...st0}
          d='M13.2,27.7l-1-16c2.7-1,4-4,3-6.7c-0.4-1-1.1-1.9-2-2.5v4.3l-2.8,1.4L7.5,6.9V2.5C5.1,4.1,4.4,7.3,6,9.7
		c0.6,0.9,1.5,1.6,2.5,2l-1,16c-0.1,1.6,1.2,3,2.9,3h0C12,30.8,13.3,29.4,13.2,27.7z'
        />
        <g>
          <path
            {...st1}
            d='M23.9,30.8c-1.7,0-3-1.3-3-3l0,0v-8l0,0c0.5-0.5,0.5-1.4,0-2l0,0v-1.5h6v1.5l0,0c-0.5,0.5-0.5,1.4,0,2l0,0v8
			C26.9,29.4,25.5,30.8,23.9,30.8L23.9,30.8z'
          />
          <line {...st1} x1='23.9' y1='16.3' x2='23.9' y2='3.8' />
          <g>
            <path
              stroke={color}
              d='M24.6,1.2l0.5,2.6c0.1,0.7-0.3,1.3-1,1.4s-1.3-0.3-1.4-1c0-0.2,0-0.3,0-0.4l0.5-2.6c0.1-0.4,0.5-0.7,0.9-0.6
				C24.3,0.7,24.6,0.9,24.6,1.2z'
            />
          </g>
        </g>
        <circle {...st0} cx='10.4' cy='27.2' r='0.4' />
      </g>
    </svg>
  );
};

export default Tools;
