import React from 'react';

import pallete from '../../pallete';

const ExternalSpaces = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <path
        {...st0}
        d='M15.3,9.6c-0.6,0-1.1,0-1.7,0C7.1,9.7,1.8,8.4,1.8,6.9c0-1.5,5.3-2.8,11.9-2.8c0.5,0,1,0,1.6,0'
      />
      <path {...st0} d='M25.5,21c0,1.5-5.3,2.8-11.9,2.8S1.8,22.6,1.8,21' />
      <path {...st0} d='M1.8,14c0,1.3,3.6,2.3,8.6,2.7' />
      <path {...st0} d='M25.5,27.7c0,1.5-5.3,2.8-11.9,2.8S1.8,29.3,1.8,27.7' />
      <line {...st0} x1='1.8' y1='6.9' x2='1.8' y2='27.7' />
      <polyline {...st0} points='25.5,16.3 25.5,21 25.5,21 25.5,27.7 ' />
      <path
        {...st1}
        d='M30.2,3.1C30.2,3.1,30.2,3.1,30.2,3.1c0-0.1-0.1-0.3-0.1-0.4c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.2-0.1-0.2
	c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0,0-0.1,0
	c-0.2-0.1-0.5-0.1-0.7-0.1H21c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.4l-8.7,8.9c-0.8,0.8-0.8,2,0,2.8c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6
	l8.6-8.9v2.4c0,1.1,0.9,2,2,2s2-0.9,2-2V3.5C30.2,3.4,30.2,3.2,30.2,3.1z'
      />
    </svg>
  );
};

export default ExternalSpaces;
