import React, { useState } from 'react';

import pallete from '../../../../theme/pallete';

const Declaratio = ({
  color = pallete.secondary1,
  height = 25,
  width = 132,
  secondaryColor = pallete.logoSecondary,
  selected,
  disabled,
  nonIA = false,
}) => {
  let color1 = color;
  let secondaryColor2 = secondaryColor;
  const [hovered, setHovered] = useState(false);
  if (selected || hovered) {
    color1 = pallete.backgroundWhite;
  } else if (disabled) {
    color1 = '#B2B2B2';
    secondaryColor2 = '#777777';
  }
  return (
    <svg
      version='1.1'
      id='Ebene_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={width}
      height={height}
      viewBox='0 0 453.8 85.2'
      // style='enable-background:new 0 0 453.8 85.2;'
      xmlSpace='preserve'
      onMouseEnter={() => !nonIA && setHovered(true)}
      onMouseLeave={() => !nonIA && setHovered(false)}
    >
      <path
        fill={secondaryColor2}
        d='M67.3,0c1.7,0.5,3.4,0.8,5.1,1.4c3,1.2,4.1,2.6,4.1,5.8c0,23.3,0,46.6,0.1,69.8c0,2.5-1.1,4.2-2.8,5.6
 c-1.9,1.5-4.1,2.3-6.6,2.1c-0.6-0.1-0.8-0.3-0.8-0.9c0-0.3,0-0.6,0-0.8c0-27.5,0-54.9,0-82.4c0-0.2,0-0.5,0-0.7C66.7,0,67,0,67.3,0z
 '
      />
      <path
        fill={color1}
        d='M64,42.5c0,13.6,0,27.3,0,40.9c0,1.1-0.3,1.4-1.4,1.4c-8.5,0-17,0.4-25.4-0.2c-10.3-0.7-19.9-3.9-28-10.8
 C-3.2,63.2-3.3,41.6,12.7,31.4c5.9-3.7,12.4-5.8,19.1-7.2c4.9-1,9.9-0.9,14.9-1.2c2.3-0.1,4.6-0.2,6.8-0.2c0.9,0,1.1-0.3,1-1.1
 c0-4.7,0.2-9.5-0.1-14.2c-0.2-3.2,1.6-4.7,4-5.9c1.3-0.6,2.9-0.9,4.3-1.3c0.9-0.3,1.2,0,1.2,1c0,6.9,0,13.8,0,20.7
 C64,28.8,64,35.7,64,42.5z M54.3,53.9c0-4.8,0-9.6,0-14.3c0-0.5,0.1-1.1-0.8-1.1c-5.2,0.1-10.5-0.2-15.7,0.3
 c-4.8,0.4-9,2.2-12.3,5.9c-5.3,5.8-5.1,14.1,0.5,19.6c3.9,3.8,9,4.7,14.1,5.1c4.3,0.3,8.7,0,13.1,0.1c1,0,1.2-0.3,1.1-1.2
 C54.3,63.4,54.3,58.7,54.3,53.9z'
      />
      <path
        fill={color1}
        d='M127.1,74.6c-1.4,2.5-2.9,4.8-5.1,6.5c-6.5,5.2-15.3,4.1-20.9-2.5c-3.2-3.9-5.1-8.4-6-13.2
 c-1.6-8.5-1.3-16.9,1.8-25c1.7-4.4,4.3-8.3,8.3-11c5.7-3.8,13-3.4,17.8,0.8c1.2,1,2.1,2.3,2.9,3.8c0.3-0.5,0.2-0.9,0.2-1.3
 c0-9.5,0-19,0-28.6c0-1.1,0.3-1.4,1.4-1.4c2.8,0.1,5.6,0.1,8.4,0c1,0,1.3,0.3,1.3,1.3c0,14.6,0,29.2,0,43.8c0,9.2,0,18.4,0.1,27.6
 c0,2.3,0.1,4.6,0.2,6.9c0,0.7-0.1,0.9-0.8,0.9c-2.7,0-5.4,0-8.2,0c-0.7,0-0.8-0.3-0.9-0.9C127.4,79.7,127.2,77.2,127.1,74.6z
  M126,55.3c0-1.6,0-3.2,0-4.8c-0.1-3.5-0.9-6.7-3-9.5c-3.3-4.3-9.1-4.7-12.9-0.8c-1.6,1.6-2.6,3.4-3.3,5.5c-1.8,5.2-1.9,10.5-1,15.9
 c0.5,3.1,1.6,6.1,3.7,8.5c3.4,3.9,9,4.1,12.6,0.4c1.6-1.7,2.6-3.7,3.2-5.9C126.2,61.5,126,58.4,126,55.3z'
      />
      <path
        fill={secondaryColor2}
        d='M453.6,55c-0.1,6.4-1,12.5-4,18c-4,7.2-10,11.3-18.4,11.4c-7.9,0-13.7-4-17.6-10.7c-3.1-5.4-4.1-11.3-4.1-17.5
 c0-6.9,1.1-13.5,4.8-19.4c5.9-9.5,18-12.9,27.3-7.5c5.2,3,8.2,7.7,10.1,13.3C453.1,46.7,453.6,50.9,453.6,55z M442.2,54.8
 c0-1.4-0.1-3.7-0.6-5.9c-0.6-3.2-1.6-6.3-3.7-8.9c-3.2-3.9-8.6-4.1-12.1-0.6c-1.6,1.5-2.6,3.4-3.3,5.5c-2,6.1-2.2,12.3-0.8,18.6
 c0.6,2.9,1.7,5.6,3.7,7.9c3.5,3.9,8.7,3.9,12.1,0c0.9-1,1.6-2.2,2.2-3.4C441.6,64.1,442.2,59.9,442.2,54.8z'
      />
      <path
        fill={secondaryColor2}
        d='M343.8,76.8c-0.7,0.9-1.2,1.6-1.8,2.3c-4,4.4-8.8,6.2-14.7,4.8c-3.2-0.7-5.6-2.7-7.3-5.4
 c-4.6-6.8-3.9-17.7,1.4-23.9c3.4-3.9,7.9-5.8,12.9-6.8c2.5-0.5,5-0.7,7.5-0.7c0.8,0,1.1-0.1,1-1c-0.1-1.3-0.2-2.5-0.5-3.8
 c-1.3-4.3-5-6.4-10.1-5.7c-3.9,0.5-6,1.4-9.9,3.9c-0.7-2.9-1.4-5.8-2.1-8.7c-0.1-0.5,0.4-0.6,0.7-0.9c3.3-2.2,6.9-3.5,10.8-4
 c3.5-0.5,6.9-0.5,10.3,0.6c5.8,1.8,8.8,6.1,10.5,11.7c1.1,3.7,1.4,7.4,1.4,11.2c0,8.4,0.1,16.8,0.1,25.2c0,2.2,0.1,4.3,0.4,6.4
 c0.1,0.8-0.1,1-0.9,1c-2.7,0-5.4,0-8,0c-0.7,0-1-0.3-1-0.9C344.2,80.5,344,78.8,343.8,76.8z M343.1,60.8c0-1.2,0-2.5,0-3.7
 c0-0.7-0.2-0.9-0.9-0.9c-1.5,0-2.9,0.1-4.4,0.3c-7,0.9-10.5,5-9.8,11.7c0.5,5.5,5.5,7.9,10.2,5c2.3-1.4,3.7-3.6,4.4-6.2
 C343.2,65,343.1,62.9,343.1,60.8z'
      />
      <path
        fill={color1}
        d='M271.3,77.1c-1.2,1.4-2.3,2.7-3.6,3.8c-4.9,4.2-12.2,4.7-17,1c-3.2-2.4-5-5.8-5.7-9.7
 c-1.1-6.1-0.4-11.9,3.4-16.9c3.1-4.1,7.4-6.1,12.3-7.3c2.8-0.7,5.7-1,8.6-0.9c0.9,0,1.1-0.3,1.1-1.2c-0.1-1-0.1-2.1-0.4-3.1
 c-1.1-4.6-4.9-6.9-10.2-6.2c-3.3,0.5-6.4,1.5-9.2,3.5c-0.6,0.4-0.8,0.4-1-0.4c-0.5-2.5-1.1-5-1.8-7.4c-0.2-0.7,0.2-1,0.7-1.3
 c3.2-2.2,6.8-3.4,10.7-4c3.1-0.5,6.3-0.5,9.4,0.3c5.4,1.4,8.9,5,10.8,10.1c1.3,3.4,1.9,7,1.9,10.6c0.1,8.1,0,16.2,0.1,24.3
 c0,3.3,0.1,6.6,0.5,9.9c0.1,0.8-0.1,0.9-0.8,0.9c-2.7,0-5.4,0-8.2,0c-0.7,0-0.9-0.3-1-1C271.7,80.5,271.5,78.8,271.3,77.1z
  M270.5,61.4c0-1.6,0-3,0-4.3c0-0.8-0.2-0.9-1-0.9c-1.4,0-2.9,0.1-4.3,0.3c-7.2,0.9-10.8,5.4-9.7,12.3c0.7,4.2,3.9,6.4,7.9,5.3
 c3.8-1,5.7-3.8,6.8-7.4C270.7,64.9,270.5,63,270.5,61.4z'
      />
      <path
        fill={color1}
        d='M167.7,59.5c-4.4,0-8.7,0-13.1,0c-1.1,0-1.5,0.2-1.4,1.4c0.3,2.8,1,5.4,2.6,7.7c2.2,3.1,5.4,4.7,9.1,5
 c4.3,0.3,8.6-0.2,12.7-2c0.8-0.4,1-0.3,1.2,0.6c0.4,2.6,0.8,5.2,1.3,7.8c0.1,0.5,0.1,0.9-0.5,1.1c-7.3,3.2-14.8,4.3-22.5,1.7
 c-5.4-1.8-9.3-5.7-11.4-11c-4.9-12.2-4.3-24.2,2.1-35.7c2.8-5,7.1-8.3,13-9.3c7.9-1.3,14.2,1.9,18,9c2.4,4.6,3.5,9.6,3.8,14.7
 c0.1,2.5,0.1,5.1-0.1,7.6c-0.1,1-0.5,1.1-1.3,1.1C176.7,59.5,172.2,59.5,167.7,59.5z M162.8,49.7c2.9,0,5.8,0,8.6,0c0.6,0,1,0,1-0.8
 c-0.1-3.2-0.7-6.4-2.5-9.1c-2.8-4.3-8.5-4.9-12.1-1.3c-2.9,2.9-3.9,6.6-4.4,10.5c-0.1,0.8,0.4,0.7,0.9,0.7
 C157.1,49.7,159.9,49.7,162.8,49.7z'
      />
      <path
        fill={secondaryColor2}
        d='M385.5,72.2c0,3.4,0,6.8,0,10.1c0,0.5-0.3,0.7-0.7,0.8c-3.9,1.2-7.9,1.7-11.9,0.2c-4.2-1.5-6.3-5-7.3-9.1
 c-0.8-3.2-1-6.4-1-9.6c0-8.4,0-16.7,0-25.1c0-0.9-0.2-1.3-1.2-1.2c-1.4,0.1-2.8,0-4.2,0c-0.6,0-0.9-0.1-0.9-0.8c0-2.9,0-5.9,0-8.8
 c0-0.6,0.2-0.8,0.8-0.8c1.4,0,2.9-0.1,4.3,0c1,0.1,1.2-0.3,1.1-1.2c0-3.4,0-6.9,0-10.3c0-0.8,0.2-1.1,1-1.3c2.9-0.8,5.9-1.8,8.8-2.7
 c0.8-0.2,1-0.1,1,0.7c0,4.5,0,9.1,0,13.6c0,0.9,0.2,1.1,1.1,1.1c2.8-0.1,5.6,0,8.4,0c0.7,0,1,0.1,1,0.9c-0.1,2.9-0.1,5.7,0,8.6
 c0,0.8-0.3,1-1,1c-2.8,0-5.5,0-8.3,0c-0.8,0-1.2,0.1-1.2,1.1c0,7.9,0,15.8,0,23.7c0,2.1,0.1,4.1,0.8,6.1c0.9,2.5,2.4,3.6,5.1,3.6
 C382.7,72.9,384,72.6,385.5,72.2z'
      />
      <path
        fill={color1}
        d='M208,84.3c-7.7-0.1-14.4-4.4-18-12.9c-4.4-10.5-4.3-21.2,0.6-31.5c4.2-9.1,12.6-13.8,22.6-13
 c2.7,0.2,5.3,0.9,7.7,2c0.7,0.3,0.9,0.7,0.8,1.5c-0.6,2.8-1.1,5.7-1.6,8.6c-0.1,0.9-0.4,0.8-1.1,0.5c-2.6-1.3-5.4-1.9-8.3-1.7
 c-4.8,0.4-8.2,3-10.2,7.3c-3.1,6.8-3.2,13.8-0.2,20.6c3,6.8,9.6,9.3,17,6.8c0.8-0.3,1.5-0.6,2.2-0.9c0.5-0.2,0.7-0.3,0.8,0.4
 c0.4,3,0.9,6.1,1.4,9.1c0.1,0.7-0.4,0.8-0.8,1C217.3,83.6,213.6,84.2,208,84.3z'
      />
      <path
        fill={color1}
        d='M227,42.9c0-13,0-25.9,0-38.9c0-1.1,0.2-1.4,1.4-1.4c2.8,0.1,5.7,0.1,8.5,0c1,0,1.2,0.3,1.2,1.3
 c0,24.7,0,49.3,0,74c0,1.4,0,2.9,0,4.3c0,0.7-0.2,0.9-0.9,0.9c-3.1,0-6.2-0.1-9.4,0c-1.1,0-0.9-0.7-0.9-1.3c0-11.5,0-23,0-34.6
 C227,45.9,227,44.4,227,42.9z'
      />
      <path
        fill={secondaryColor2}
        d='M298.6,38.7c0.6-0.6,0.6-1.2,0.8-1.7c1.1-3.1,2.7-5.9,5.4-8c2.1-1.7,4.5-2.4,7.3-2.2c0.9,0.1,1.4,0.3,1.3,1.4
 c-0.1,3.7-0.1,7.3,0,11c0,0.8-0.1,1-1,0.8c-4.7-0.9-8.3,1-10.5,5.2c-1.6,3.1-1.9,6.5-1.9,9.9c0,8.9,0,17.9,0,26.8
 c0,1-0.2,1.3-1.3,1.3c-2.8-0.1-5.7-0.1-8.5,0c-1,0-1.3-0.3-1.3-1.3c0-11,0-22.1,0-33.1c0-6.6,0-13.2-0.4-19.7c0-0.8,0.1-1.1,1-1.1
 c2.6,0.1,5.1,0.1,7.7,0c0.7,0,0.9,0.2,1,0.9c0,2.8,0.2,5.6,0.3,8.4C298.6,37.7,298.6,38.1,298.6,38.7z'
      />
      <path
        fill={secondaryColor2}
        d='M403.3,55.6c0,8.8,0,17.5,0,26.3c0,1-0.3,1.3-1.3,1.3c-2.9-0.1-5.8,0-8.8,0c-0.8,0-1.1-0.1-1.1-1
 c0-17.7,0-35.5,0-53.2c0-0.8,0.3-1,1-1c3,0,6,0.1,9,0c0.9,0,1.1,0.2,1.1,1.1C403.3,37.9,403.3,46.8,403.3,55.6z'
      />
      <path
        fill={secondaryColor2}
        d='M403.9,12.8c0,1.6-0.4,3.2-1.3,4.7c-2.4,3.6-7.5,3.6-9.7-0.1c-1.9-3.2-1.9-6.5,0.1-9.6c2.3-3.8,7.5-3.6,9.8,0.3
 C403.6,9.4,403.9,11,403.9,12.8z'
      />
    </svg>
  );
};

export default Declaratio;
