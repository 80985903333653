import { Box, List, useMediaQuery } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { COMPANY_ROUTES } from '../../../app/config/constants';
import { useLocalize, useStore } from '../../../hooks';
import pallete from '../../../theme/pallete';
import useStyles from './styles';

const DrawerMenuItem = ({
  listItem,
  isDrawerOpen,
  openDrawer,
  extraPadding,
  expand,
  setExpand,
}) => {
  const isBigScreen = useMediaQuery('@media(min-width:1280px)');
  const classes = useStyles();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const format = useLocalize();
  const { companySelected } = useStore();
  useEffect(() => {
    if (!isDrawerOpen) {
      setExpand('');
    }
  }, [isDrawerOpen, setExpand]);
  const Icon = listItem.icon;
  const activeClass = useMemo(
    () =>
      isDrawerOpen ? classes.activeMenuItemExpand : classes.activeMenuItem,
    [classes.activeMenuItem, classes.activeMenuItemExpand, isDrawerOpen],
  );
  const passiveClass = useMemo(
    () => (isDrawerOpen ? classes.menuItemExpand : classes.menuItem),
    [classes.menuItem, classes.menuItemExpand, isDrawerOpen],
  );

  const changeLocation = (menuLink) => {
    if (!isBigScreen) {
      openDrawer(false);
    }
    sessionStorage.setItem(
      'location',
      `${COMPANY_ROUTES[companySelected]}${menuLink}`,
    );
    push(`${COMPANY_ROUTES[companySelected]}${menuLink}`);
  };

  const handleChangeDrawerTopLevel = () => {
    if (listItem?.subMenu && !expand) {
      setExpand(listItem.label);
      openDrawer(true);
    } else if (listItem.subMenu && expand === listItem.label) {
      setExpand('');
    } else if (listItem.subMenu && expand) {
      setExpand(listItem.label);
    } else {
      changeLocation(listItem.link);
    }
  };

  const handleChangeDrawerNestedLevel = (menuLink) => {
    changeLocation(menuLink);
  };

  return (
    <>
      <ListItem
        key={`${Math.random()}`}
        className={classNames(
          pathname.includes(listItem.link) ||
            (listItem.subMenu &&
              listItem.subMenu.some((item) => pathname.includes(item.link)))
            ? activeClass
            : passiveClass,
          extraPadding && classes.extraPadding,
        )}
        button
        onClick={handleChangeDrawerTopLevel}
      >
        <ListItemIcon>
          <Icon
            {...((pathname.includes(listItem.link) && isDrawerOpen) ||
            (!isDrawerOpen &&
              !pathname.includes(listItem.link) &&
              listItem?.subMenu &&
              !listItem.subMenu.some((item1) =>
                pathname.includes(item1.link),
              )) ||
            (listItem.subMenu &&
              isDrawerOpen &&
              listItem.subMenu.some((item2) =>
                pathname.includes(item2.link),
              )) ||
            (!listItem.subMenu &&
              !isDrawerOpen &&
              !pathname.includes(listItem.link))
              ? {
                  color: pallete.backgroundWhite,
                  color2: pallete.backgroundWhite,
                }
              : {})}
            width={28}
            height={28}
          />
        </ListItemIcon>
        <ListItemText primary={format(listItem.label)} />
        {!!listItem.subMenu && (
          <ListItemIcon style={{ marginRight: -40 }}>
            {!expand ? (
              <KeyboardArrowDownIcon style={{ fontSize: 17 }} color='primary' />
            ) : (
              <KeyboardArrowUpIcon style={{ fontSize: 17 }} color='primary' />
            )}
          </ListItemIcon>
        )}
      </ListItem>
      <Collapse in={expand === listItem.label} timeout='auto' unmountOnExit>
        <List style={{ padding: '0' }}>
          {listItem?.subMenu &&
            listItem.subMenu.map((subMenuItem) => {
              const SubmenuIcon = subMenuItem.icon;
              return (
                <ListItem
                  key={`${Math.random()}`}
                  className={classNames(
                    pathname.includes(subMenuItem.link)
                      ? classes.activeSubMenuItem
                      : classes.subMenuItem,
                  )}
                  button
                  onClick={() =>
                    handleChangeDrawerNestedLevel(subMenuItem.link)
                  }
                >
                  <Box minWidth={5} />
                  <ListItemIcon
                    className={classNames(
                      !!pathname.includes(listItem.link) &&
                        classes.activeMenuItemIcon,
                    )}
                  >
                    {SubmenuIcon ? <SubmenuIcon /> : <div />}
                  </ListItemIcon>
                  <ListItemText primary={format(subMenuItem.label)} />
                </ListItem>
              );
            })}
        </List>
      </Collapse>
    </>
  );
};

export default DrawerMenuItem;
