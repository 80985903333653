import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import classNames from 'classnames';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '../../../app/config/constants';
import { client } from '../../../graphql';
import { useDispatch, useStore } from '../../../hooks';
import useStyles from './styles';

function useOutsideAlerter(ref, setOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setOpen]);
}

const CustomDropdown = ({
  children,
  content,
  setValue,
  compact = false,
  wide = false,
  textSize,
  marginTop,
  className,
}) => {
  const { user } = useStore();
  const { push } = useHistory();
  const classes = useStyles({ wide, textSize, marginTop });
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setVisible);

  const [currentLocation, setCurrentLocation] = useState();

  const handleChangeLocation = (e, listItem) => {
    setCurrentLocation(listItem.value);
    e.stopPropagation();
    setVisible(false);
    setValue(listItem.value);
    if (listItem.value === 'logout') {
      client && client.clearStore();
      dispatch({ type: 'LOGOUT' });
    }
    if (listItem.value === 'switch') {
      localStorage.removeItem('space');
      sessionStorage.removeItem('location');
      push(ROUTES.spaceChoice);
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={clsx(classes.relativeContainer, className)}
    >
      <Box onClick={() => setVisible(!visible)}>{children}</Box>
      {visible && (
        <Box className={classes.drodownContainer}>
          <Box className={classes.contentContainer}>
            <List style={{ padding: 0 }}>
              {content
                .filter((item) => {
                  if (
                    (item.value === 'switch' && user?.databases?.length > 0) ||
                    item.value !== 'switch'
                  ) {
                    return item;
                  }
                })
                .map((listItem) => {
                  const Icon = listItem.icon;
                  return (
                    <ListItem
                      key={listItem.value}
                      className={classNames(
                        classes.listItem,
                        compact && classes.listItemCompact,
                      )}
                      onClick={
                        currentLocation !== listItem.value
                          ? (e) => handleChangeLocation(e, listItem)
                          : null
                      }
                    >
                      {Icon && (
                        <ListItemIcon>
                          <Icon />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        secondary={listItem.title}
                        className={classNames(
                          classes.textItem,
                          compact && classes.textItemCompact,
                        )}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Box>
          <span className={classes.toolTipArrow} />
        </Box>
      )}
    </div>
  );
};

export default CustomDropdown;
