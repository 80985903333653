import { makeStyles } from '@material-ui/styles';

import pallete from '../../../theme/pallete';

const useStyles = makeStyles(() => ({
  relativeContainer: {
    position: 'relative',
  },
  drodownContainer: {
    position: 'absolute',
    top: '100%',
    marginTop: 7,
    right: '5%',
    width: 'auto',
    height: 'auto',
    color: 'white',
    zIndex: 1,
    displey: 'flex',
    flexDirection: 'column',
    background: pallete.slightyGreyBackground,
    padding: 3,
    boxShadow:
      '-3px 3px 5px 0px rgba(0,0,0,0.35), 3px 3px 5px 0px rgba(0,0,0,0.35)',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexBasis: '100%',
    overflow: 'hidden',
  },
  listItemCompact: {
    padding: 3,
  },
  selectedItem: {},
  listItem: {
    height: '36px',
    // padding: '15px 5px',
    borderBottom: `1px solid ${pallete.grey3}`,
    '& *': {
      color: pallete.secondary1,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: pallete.secondary1,
      '& *': {
        color: pallete.primary,
        backgroundColor: pallete.secondary1,
      },
    },
  },
  value: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
