import './styles.css';

import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import useStyles from './styles';

const SpinnerLogo = ({ size = 150, fullPage = false }) => {
  const classes = useStyles({ size, fullPage });
  return (
    <Box className={classes.container}>
      <svg
        version='1.1'
        id='Ebene_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width={size}
        height={size}
        viewBox='0 0 350 300'
        // style='enable-background:new 0 0 350 300;'
        xmlSpace='preserve'
      >
        <g>
          <path
            className={classNames(classes.st0, classes.row4)}
            id='el1'
            d='M309.1,70.8h-27.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C319.5,66.3,315.2,70.8,309.1,70.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row1)}
            id='el2'
            d='M93.7,70.8H66.4c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C104.2,66.3,99.9,70.8,93.7,70.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row2)}
            // ROW 2
            id='el3'
            d='M165.5,70.8h-27.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C176,66.3,171.7,70.8,165.5,70.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row3)}
            // style={{animationDelay:'2s'}}
            id='el4'
            d='M237.3,70.8H210c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C247.8,66.3,243.5,70.8,237.3,70.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row7)}
            // style={{animationDelay:'2s'}}
            id='el5'
            d='M276,277.9h-27.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C286.4,273.4,282.1,277.9,276,277.9z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row4)}
            // style={{animationDelay:'2s'}}
            id='el6'
            d='M60.6,277.9H33.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C71.1,273.4,66.8,277.9,60.6,277.9z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row5)}
            id='el7'
            d='M132.4,277.9h-27.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2H138c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C142.8,273.4,138.5,277.9,132.4,277.9z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row6)}
            id='el8'
            d='M204.2,277.9h-27.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C214.6,273.4,210.3,277.9,204.2,277.9z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row6)}
            // style={{animationDelay:'5s'}}
            id='el9'
            d='M287,208.8h-27.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C297.5,204.3,293.2,208.8,287,208.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row3)}
            id='el10'
            d='M71.7,208.8H44.4c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C82.1,204.3,77.8,208.8,71.7,208.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row4)}
            id='el11'
            d='M143.4,208.8h-27.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2H149c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C153.9,204.3,149.6,208.8,143.4,208.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row5)}
            id='el12'
            d='M215.2,208.8h-27.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C225.7,204.3,221.4,208.8,215.2,208.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row5)}
            id='el13'
            d='M298.1,139.8h-27.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C308.5,135.3,304.2,139.8,298.1,139.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row2)}
            id='el14'
            d='M82.7,139.8H55.4c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C93.1,135.3,88.8,139.8,82.7,139.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st0, classes.row3)}
            id='el15'
            d='M154.5,139.8h-27.3c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C164.9,135.3,160.6,139.8,154.5,139.8z'
          />
        </g>
        <g>
          <path
            className={classNames(classes.st1, classes.row4)}
            id='el16'
            d='M226.3,139.8H199c-6.1,0-10.8-5-10.3-11.2l4.4-27.3c1.6-6.1,5.9-11.2,12.1-11.2h26.7c6.1,0,10.8,5,10.3,11.2
		l-3.8,27.3C236.7,135.3,232.4,139.8,226.3,139.8z'
          />
        </g>
      </svg>
    </Box>
  );
};

export default SpinnerLogo;
