import React from 'react';

import pallete from '../../pallete';

const Translations = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <g>
          <path
            {...st0}
            d='M19.3,12.8h9.8c1,0,1.9,0.8,1.9,1.9v10.8c0,1-0.8,1.9-1.9,1.9V31l-5.7-3.6h-9.2c-1,0-1.9-0.9-1.9-1.9v-6.4'
          />
          <path
            {...st1}
            d='M19.8,10.1c0,5-4.1,9.1-9.1,9.1c-1.2,0-2.4-0.2-3.5-0.7l-4.1,2l1.2-3.9c-1.7-1.6-2.7-3.9-2.7-6.5
			c0-5,4.1-9.1,9.1-9.1S19.8,5.1,19.8,10.1z'
          />
        </g>
        <g>
          <polyline {...st0} points='19.8,23.6 22.8,16.7 25.7,23.6 		' />
          <line {...st0} x1='20' y1='20.5' x2='25.4' y2='20.5' />
        </g>
        <path {...st0} d='M1,13.1' />
        <g>
          <line {...st1} x1='8.1' y1='8.5' x2='13.2' y2='8.5' />
          <path {...st1} d='M10.7,6.2v4.4c0,1.2-1,2.1-2.1,2.1' />
          <path {...st1} d='M10.7,6.2v4.4c0,1.2,1,2.1,2.1,2.1' />
        </g>
      </g>
    </svg>
  );
};

export default Translations;
