import { createTheme } from '@material-ui/core/styles';

import { FONT_FAMILIES } from '../app/config/constants';
import fontSizes from './fontSizes';
import fontWeights from './fontWeights';
import pallete from './pallete';

const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'Open Sans Condensed', 'sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      xs: 1024,
      md: 1024,
      sm: 1024,
      lg: 1280,
      xl: 1280,
    },
  },
  overrides: {
    // GLOBAL STYLES START
    MuiCssBaseline: {
      '@global': {
        body: {
          color: pallete.primary,
          fontSize: 14,
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,

          '& img': {
            maxWidth: '100%',
          },

          '& a': {
            textDecoration: 'none',
            color: 'inherit',
          },
          '& a:visited': {},
          '& *': {
            boxSizing: 'border-box!important',
          },

          '& .slick-slide': {
            opacity: 0,
            transition: 'all 0.3s',

            '&.slick-active': {
              opacity: 1,
            },
          },
        },

        '.bordered-window': {
          display: 'flex',
          flexDirection: 'column',
          padding: 25,
          background: pallete.backgroundWhite,
          border: `2px solid ${pallete.secondary1}`,

          '&.small': {
            maxWidth: 295,
          },
          '&.medium': {
            maxWidth: 376,
          },
          '&.large': {
            maxWidth: 435,
          },
        },
        '.link': {
          textDecoration: 'underline',
          '&:hover': {
            cursor: 'pointer',
          },
        },
        '.underlined': {
          textDecoration: 'underline',
        },
      },
    },
    // GLOBAL STYLES FINISH

    // ...overrides,
    MuiButton: {
      root: {
        '&.centered': {
          textAlign: 'center',
        },
      },
      containedSizeLarge: {
        paddingTop: 9,
        paddingBottom: 9,
        color: pallete.backgroundWhite,
        fontSize: fontSizes.md,
        fontWeight: fontWeights.BOLD,
        //                 lineHeight={21.13}
        //                 fontWeight={fontWeights.BOLD}
      },
      contained: {
        backgroundColor: pallete.secondary1,
        textTransform: 'none',
      },
      outlined: {
        borderColor: pallete.secondary1,
        textTransform: 'none',
      },
      outlinedSecondary: {
        borderColor: pallete.secondary1,
        color: pallete.primary,
        borderRadius: 0,
        '&:hover': {
          borderColor: pallete.primary,
          color: pallete.secondary1,
          backgroundColor: 'rbga(0,0,0,0.3)',
        },
      },
      containedSecondary: {
        backgroundColor: pallete.secondary1,
        '&:hover': {
          backgroundColor: pallete.primary,
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        margin: '6px 6px 6px 17px',
        padding: 0,
        color: pallete.grey3,
        '&$checked': pallete.secondary1,
        '&.Mui-checked': {
          color: pallete.secondary1,
        },
      },
    },

    MuiInputLabel: {
      outlined: {
        // Upped because of small height of TextFields when global styles enabled.
        transform: 'translate(14px, 12px) scale(1)',
        borderColor: pallete.primary,
        '&.MuiInputLabel-shrink': {
          fontSize: fontSizes.xs,
          transform: 'translate(11px, -5px) scale(0.75)',
          fontWeight: fontWeights.BOLD,
          color: pallete.primary,
        },
      },
      formControl: {
        transform: 'translate(10px, -4px) scale(0.75)',
        background: 'white',
        zIndex: 1,
        fontSize: fontSizes.xs,
        fontWeight: fontWeights.BOLD,
      },
    },
    MuiSelect: {
      root: {
        paddingTop: 10,
        paddingBottom: 10,
      },
      outlined: {
        '&.MuiSelect-outlined': {
          paddingTop: 13,
          paddingLeft: 4,
          paddingBottom: 9,
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        '& > legend': {
          maxWidth: 0,
        },
      },
      input: {
        fontSize: fontSizes.xs,
        lineHeight: '16.34px',
        fontWeight: fontWeights.REGUlAR,
        padding: '19.5px 14px 17.5px 8px',
      },
    },
    MuiSwitch: {},
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '1.0rem',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: 12,
      },
    },
    MuiTypography: {
      h1: {
        fontWeight: fontWeights.BOLD,
      },
      h3: {
        fontWeight: fontWeights.BOLD,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&.Mui-checked': { color: pallete.secondary1 },
      },
    },
    MuiTooltip: {
      tooltip: {
        border: (props) => (props.errorType ? `1px solid ${pallete.nutriError}` : `1px solid ${pallete.textPrimary}`),
        backgroundColor: pallete.backgroundWhite,
        borderRadius: 0,
        fontWeight: 500,
        fontSize: `${fontSizes.ms}px!important`,
        lineHeight: `${fontSizes.xl}px`,
        fontFamily: FONT_FAMILIES.REGULAR,
        color: (props) => (props.errorType ? pallete.nutriError : pallete.textPrimary),
        padding: `5px 15px`,
      },
      arrow: {
        '&:before': {
          backgroundColor: pallete.backgroundWhite,
          border: (props) => (props.errorType ? `1px solid ${pallete.nutriError}` : `1px solid ${pallete.textPrimary}`),
        },
      },
    },
  },
  pallete,
  fontSizes,
});

export default theme;
