import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import useStyles from './styles';

const Typo = ({
  children,
  onClick,
  style,
  className = '',
  lineHeight,
  m,
  ...props
}) => {
  const classes = useStyles({
    ...props,
    m,
    lineHeight,
  });
  let content;
  if (typeof children === 'string') {
    content = children.split('<br>');
  } else {
    content = [children];
  }
  return (
    <>
      {content.map((textLine, index) => {
        return (
          <Typography
            key={index}
            className={classNames(classes.typoStyled, className)}
            onClick={onClick}
            style={style}
          >
            {textLine}
          </Typography>
        );
      })}
    </>
  );
};

export default Typo;
