import uniqid from 'uniqid';

export const API_HOST =  process.env.API_HOST;
export const BASE_URL = process.env.API_HOST;
export const FOOD_SCALE_URL = process.env.API_HOST;
export const QUERY_LIMIT = 10;

export const DEFAULT_LOCALE = 'en';
export const LOCALE_OPTIONS = [
  { value: 'en-GB', label: 'EN' },
  { value: 'de-DE', label: 'DE' },
];

export const DRAWER_WIDTH = 280;
export const DRAWER_CLOSE = 48;
export const TOOLBAR_HEIGHT = 46;
export const SCREEN_HEIGHT_1280 = 1000;
export const SCREEN_HEIGHT_1024 = 900;

export const COMPANY_ROUTES = {
  Roof: '/root',
  CookingKids: '/cookingkids',
  Declaratio: '/declaratio',
  FoodIngridientsData: '/fid',
  FoodScale: '/foodscale',
  InternalSettings: '/internalsettings',
  Nutrimero: '/nutrimero',
};

export const ROUTES = {
  /** Authorization */
  // LOGIN
  login: '/login',
  myNotifications: '/notifications',
  requestAccess: '/request-access',
  // REGISTER
  registration: '/registration',
  registrationStep1: '/registration/step1',
  registrationStep1Back: '/registration/step1edit',
  registrationStep2DefineSpace: '/registration/define-space',
  createDB: '/create-db',
  registrationStep3aGetOneTimePassword: '/get_otp',
  registrationStep3aValidationPassword: '/validate_otp',
  restorePassword: '/restore-password',
  setNewPassword: '/set-new-password/:token',
  // SPACE CHOICE
  spaceChoice: '/chose-space',

  home: '/home',
  incompleteDataIngredients: '/incomplete-data/ingredients',
  incompleteDataRecipes: '/incomplete-data/recipes',
  tasks: '/tasks',
  messages: '/messages',

  tooldNutrimero: '/tools/nutrimero',
  toolsDeclaratio: '/tools/declaratio',
  toolsFoodscale: '/tools/foodscale',

  myProfile: '/my-profile',
  myProfileDBIbelong: '/db-i-belong',
  mySpaceCreate: '/my-space/create',
  mySpaceDb: '/my-space/db',
  mySpaceBillingA: '/my-space/billing-address',
  mySpaceSubs: '/my-space/ubscriptions',
  mySpaceInvUsers: '/my-space/invited-users',
  mySpaceNotifications: '/my-space/notifications',
  mySpaceTags: '/my-space/management-tags',

  extSpacesList: '/ext-space/list',
  extSpacesNotifications: '/ext-space/notifications',
  print: '/print',
  export: '/export',
  recipeExchange: '/recipe-exchange',
  settings: '/settings',
  // Nutri
  ingridientsOverview: '/ingridients-overview',
  ingridientDetails: '/ingridient-item-details/:id',
  ingridientCreate: '/create-ingridient-item',
  ingridientsGroups: '/ingridients-groups',
  boughtItemsOverview: '/bought-items-overview',
  boughtItemDetails: '/bought-item-details/:id',
  boughtItemCreate: '/create-bought-item',
  recipesOverview: '/recipes-overview',
  recipesGroups: '/recipes-groups',
  recipesVersions: '/recipes-versions',
  recipesImport: '/recipes-import',
  productsOverview: '/products-overview',
  productsGroups: '/products-groups',
  wizardsImportRecipes: '/wizard-import-recipes',
  wizardsImportIngridients: '/wizard-import-ingridient',
  wizardsExportIngridients: '/wizard-export-ingridient',
  // Declaratio
  declarationsOverview: '/declarations-overview',
  declarationsGroup: '/declarations-group',
  templatesOverview: '/templates-overview',
  templatesSettings: '/templates-settings',
  // FID
  productsCompanies: '/products-companies',
  technicalData: '/technical-data',
  translationsOverview: '/translations-overview',
  translationsImport: '/translations-import',
  nutritionsOverview: '/nutritions-overview',
  nutritionsSingleEntries: '/nutritions-single-entry',
  nutritionsImport: '/nutritions-import',
  nutritionsVersions: '/nutriotions-versions',
  manUserNames: '/man-user-names',
  manUserValues: '/man-user-nutri-values',
  manUserProduct: '/man-user-brand-prod',
  manUserOther: '/man-user-other',
  history: '/history',
};

export const REGISTRATION_SPACES = {
  CREATE_MY_OWN: 'createMyOwn',
  ACCESS_TO_EXISTING: 'accessToExisting',
  NO_ACCESS: 'noAccess',
};

export const SUBSCRIPTION_PLANS = {
  FREE: 'Free',
  BASIC25: 'Basic25',
};

export const UNITS = {
  KILOGRAM: 'kg',
  GRAMS: 'g',
  POUNDS: 'lb',
  OUNCES: 'oz',
};

export const FONT_FAMILIES = {
  REGULAR: "'Open Sans', sans-serif",
  CONDENSED: "'Open Sans Condensed', sans-serif",
};

export const CBorderedWindowSizes = {
  width: {
    small: 295,
    medium: 375,
    large: 435,
  },
  height: {
    small: 500,
    medium: 660,
    large: 805,
  },
};

export const getTierType = (level = '') => {
  if (!level) return 'Level 0';
  return 'Level ' + (Number(level.split(' ')[1]) + 1).toString();
};

export const getTierInterface = (type) => {
  return {
    id: uniqid(),
    type: getTierType(type),
    tierSelected: false,
    declarationName: null,
    amount: '',
    eNumber: '',
    additiveClass: null,
    organic: false,
    declareType: '',
    tiers: [],
  };
};

export const getTierAdditiveInterface = (type) => {
  return {
    id: uniqid(),
    type: getTierType(type),
    tierSelected: false,
    declarationName: null,
    eNumber: '',
    additiveClass: '',
    organic: false,
    declareType: '',
    tiers: [],
  };
};

export const ADDITIVE_CLASSES = [
  { label: 'Emulsifier', value: 'emulsifier' },
  { label: 'Preservative', value: 'preservative' },
  { label: 'Colorant', value: 'colorant' },
];

export const DECLARE_TYPE = [
  { label: 'always effective', value: 'alwaysEffective' },
  {
    label: 'not effective in final product',
    value: 'notEffectiveInFinalProduct',
  },
  { label: 'manually', value: 'manually' },
];

export const NESTING_LIMIT = 3;
