import React, { useState } from 'react';

import pallete from '../../../../theme/pallete';

const FoodScale = ({
  color = pallete.secondary1,
  height = 25,
  width = 132,
  secondaryColor = pallete.logoSecondary,
  selected,
  disabled,
  nonIA = false,
}) => {
  let color1 = color;
  let secondaryColor2 = secondaryColor;
  const [hovered, setHovered] = useState(false);
  if (selected || hovered) {
    color1 = pallete.backgroundWhite;
  } else if (disabled) {
    color1 = '#B2B2B2';
    secondaryColor2 = '#777777';
  }
  return (
    <svg
      version='1.1'
      id='Ebene_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={width}
      height={height}
      viewBox='0 0 453.8 85.2'
      // style='enable-background:new 0 0 453.8 85.2;'
      xmlSpace='preserve'
      onMouseEnter={() => !nonIA && setHovered(true)}
      onMouseLeave={() => !nonIA && setHovered(false)}
    >
      <path
        fill={color1}
        d='M0.5,81.9C0.9,81.5,0.9,81,1,80.5c1.6-5.9,7.6-10.1,13.7-9.6c0.4,0,0.7,0.2,1,0.5c7.5,6.6,16.3,9.2,26.2,8.7
   c8.3-0.4,15.5-3.3,21.7-8.8c0.2-0.2,0.5-0.4,0.8-0.4c7.4-0.8,14.1,4.6,14.9,11.9c0.1,1-0.2,1.2-1.2,1.2c-25.5,0-50.9,0-76.4,0
   c-0.4,0-0.9,0-1.3,0C0.5,83.3,0.5,82.6,0.5,81.9z'
      />
      <path
        fill={secondaryColor2}
        d='M267,50.1c0,10.9,0,21.8,0,32.8c0,1.1-0.3,1.3-1.3,1.3c-7.9,0-15.8-0.1-23.6,0c-4.6,0.1-8.2-1.8-11-5.3
   c-3.7-4.7-5.6-10.1-6.2-15.9c-0.7-5.7-0.3-11.2,1.9-16.6c1.5-3.7,3.6-7.1,6.8-9.7c2.5-2.1,5.5-3.1,8.7-3.1c4.3,0,8.6,0,13,0
   c1,0,1.2-0.3,1.2-1.2c0-5.1,0-10.2,0-15.3c0-0.9,0.2-1.2,1.2-1.2c2.7,0.1,5.4,0.1,8.2,0c1,0,1.3,0.2,1.3,1.3
   C267,28.2,267,39.1,267,50.1z M256,58.8c0-4.4,0-8.8,0-13.1c0-1.1-0.3-1.3-1.3-1.3c-2.8,0.1-5.7,0-8.5,0c-2.7,0-4.9,1.1-6.5,3.1
   c-2.2,2.7-3.2,5.8-3.5,9.2c-0.3,4.7,0.5,9.1,3.2,13.1c1.5,2.2,3.6,3.3,6.3,3.3c3.1,0,6.2-0.1,9.2,0c1,0,1.2-0.3,1.2-1.2
   C256,67.5,256,63.1,256,58.8z'
      />
      <path
        fill={color1}
        d='M396.6,65.8c0,6.5,0,11.9,0,17.3c0,0.8-0.2,1.1-1.1,1.1c-9.5,0-18.9,0-28.4,0c-4.7,0-8.1-2.5-10-6.4
   c-3.2-6.6-2.8-13.2,1.4-19.3c1.9-2.8,4.7-4.1,8.1-4.1c5.5,0,11,0,16.5,0c2.1,0,2.1,0,2.1-2.2c0-1.6-0.4-3-1.2-4.4
   c-1.1-1.9-2.6-2.9-4.9-2.9c-6.6,0-13.2,0-19.8,0c-1.6,0-1.6,0-1.6-1.7c0-2.8,0-5.7,0-8.5c0-0.7,0.1-1,0.9-1c7.1,0,14.2,0,21.3,0
   c4.8,0,9.3,1.1,12.4,5.2c2.3,2.9,3.3,6.3,3.7,9.9C396.9,54.8,396.4,60.9,396.6,65.8z M377.7,73.8c2.2,0,4.5,0,6.7,0
   c0.5,0,0.9,0.1,0.9-0.7c0-2.8,0-5.6,0-8.5c0-0.5-0.2-0.7-0.7-0.7c-4.5,0-9-0.1-13.5,0c-2.7,0.1-4.4,2.4-4.3,5.3
   c0.1,2.6,2,4.5,4.6,4.5C373.5,73.8,375.6,73.8,377.7,73.8z'
      />
      <path
        fill={secondaryColor2}
        d='M173.9,60.2c0-7.3,1.2-13,5-17.8c5.4-6.8,12.7-9.3,21.2-8.7c5.8,0.4,10.9,2.5,14.9,6.9
   c3.4,3.7,5.1,8.2,5.7,13.1c0.7,6,0.4,11.9-1.7,17.6c-3.3,8.5-9.6,13-18.6,13.9c-3.7,0.4-7.4,0.1-11-0.9c-7.8-2.2-12.2-7.5-14.2-15.1
   C174.2,65.9,173.9,62.4,173.9,60.2z M186.1,58.8c0,4.7,0.7,8.6,3.1,12c2.5,3.6,6,5.1,10.4,4.4c4-0.6,6.4-3,7.8-6.6
   c1-2.5,1.3-5.2,1.5-7.8c0.3-4.3-0.2-8.4-2.8-12.1c-4.2-6-12.5-6.1-16.9-0.2C186.8,51.8,186,55.5,186.1,58.8z'
      />
      <path
        fill={secondaryColor2}
        d='M170.1,58.9c-0.1,5.1-0.7,10.2-3.1,14.8c-3.6,7-9.5,10.7-17.3,11.5c-3.5,0.4-6.9,0.2-10.4-0.6
   c-6.7-1.5-11.5-5.4-14-11.8c-2.9-7.4-3.4-15-1.3-22.6c2.5-9.2,10.1-15.5,19.6-16.4c4.8-0.5,9.4,0,13.8,2c7.2,3.4,10.9,9.3,12.2,16.9
   C169.9,54.8,170.1,56.8,170.1,58.9z M135,58.4c0,5,0.7,9,3.2,12.4c2.5,3.5,5.9,5,10.2,4.4c4-0.6,6.4-2.9,7.9-6.5
   c1-2.5,1.4-5.1,1.5-7.7c0.3-4.4-0.2-8.6-2.8-12.3c-4.2-6-12.5-6.2-16.9-0.2C135.8,51.7,135,55.4,135,58.4z'
      />
      <path
        fill={color1}
        d='M286.9,84.3c-4.4,0-8.8,0-13.2,0c-0.9,0-1.3-0.1-1.2-1.2c0.1-2.3,0.1-4.6,0-6.9c0-0.9,0.2-1.1,1.1-1.1
   c6.8,0,13.6-0.1,20.4,0c5.6,0.1,7.3-4.3,5.7-8.7c-0.9-2.4-3-3.2-5.4-3.3c-3.8-0.1-7.7-0.1-11.5,0c-2.4,0-4.3-0.8-6-2.4
   c-4.3-4-5.7-9-5-14.7c0.3-2.6,0.9-5.1,2.1-7.4c1.9-3.5,4.8-5.3,8.8-5.3c8.3,0,16.6,0,24.9,0c0.9,0,1.1,0.3,1.1,1.2
   c-0.1,2.5-0.1,4.9,0,7.4c0,0.9-0.3,1-1.1,1c-7.1,0-14.1,0-21.2,0c-1.7,0-2.8,0.7-3.5,2.2c-0.9,2-1,4,0,6c0.9,1.7,2.2,2.6,4.2,2.6
   c3.5-0.1,7,0,10.6,0c7.3,0,11.6,3.4,13.1,10.5c1.2,5.7,0.5,11.2-3.2,16c-2.1,2.7-4.8,4.2-8.3,4.2C295.3,84.3,291.1,84.3,286.9,84.3z
   '
      />
      <path
        fill={color1}
        d='M441.7,65.1c-3.6,0-7.1,0-10.7,0c-0.9,0-1.1,0.2-1,1.1c0.1,1.9,0.5,3.7,1.6,5.3c1.7,2.6,4.2,3.4,7.1,3.4
   c4.6,0.1,9.3,0.1,13.9,0c1,0,1.2,0.3,1.2,1.3c-0.1,2.5,0,4.9,0,7.4c0,0.7-0.2,0.9-0.9,0.9c-6.2,0-12.4,0-18.6,0
   c-4.6-0.1-8.1-2.5-10.8-6c-4.6-5.8-6.5-12.4-6.3-19.7c0.2-7.1,2.3-13.5,6.9-19c2.6-3.1,5.7-5.5,9.9-6c0.9-0.1,1.8-0.1,2.6-0.1
   c5.3,0,10.6,0,15.9,0c1,0,1.2,0.3,1.2,1.2c-0.1,2.7-0.1,5.5,0,8.3c0,0.8-0.2,1.1-1.1,1.1c-4,0-8.1,0-12.1,0c-1.3,0-2.6,0.1-3.9,0.4
   c-4.3,1-6.6,5.5-6.6,9.6c0,0.7,0.5,0.6,0.9,0.6c3.2,0,6.3,0,9.5,0c4.1,0,8.2,0,12.2,0c0.8,0,1,0.2,1,1c0,2.9,0,5.7,0,8.6
   c0,0.8-0.2,1-1,1C449,65.1,445.4,65.1,441.7,65.1z'
      />
      <path
        fill={secondaryColor2}
        d='M100.1,64.6c0-6.1,0-12.1,0-18.2c0-1.1-0.3-1.3-1.3-1.3c-1.9,0.1-3.8,0-5.6,0c-0.7,0-1-0.2-1-0.9
   c0-2.7,0.1-5.5,0-8.3c0-0.9,0.3-1,1.1-1c2,0,3.9,0,5.9,0c0.8,0,1-0.2,1-1c0-3-0.3-6,0.4-8.9c0.6-2.5,1.8-4.7,3.6-6.5
   c1.8-1.9,4-2.5,6.5-2.6c3.8-0.1,7.6,0,11.4,0c0.7,0,0.9,0.3,0.8,0.9c0,2.9,0,5.8,0,8.7c0,0.7-0.2,0.9-0.9,0.9
   c-2.4,0-4.7-0.1-7.1,0.2c-1.3,0.2-2.2,0.8-2.7,2.1c-0.8,1.9-0.6,3.8-0.6,5.8c0,0.7,0.6,0.5,1,0.5c3,0,5.9,0,8.9,0
   c0.9,0,1.3,0.2,1.2,1.2c-0.1,2.7-0.1,5.3,0,8c0,0.8-0.2,1-1,1c-3,0-5.9,0-8.9,0c-1,0-1.2,0.3-1.2,1.2c0,12.2,0,24.3,0,36.5
   c0,1.1-0.2,1.4-1.4,1.4c-3-0.1-6-0.1-9,0c-0.9,0-1.2-0.2-1.2-1.2C100.1,76.8,100.1,70.7,100.1,64.6z'
      />
      <path
        fill={color1}
        d='M341.4,84.1c-2.8-0.1-6.2,0.2-9.5-0.2c-6.6-0.8-11.5-4.4-14.2-10.4c-4.6-10-4.5-20.2,0.6-30
   c3.5-6.7,9.4-10,17-10c5.3,0,10.5,0,15.8,0c0.8,0,1.1,0.2,1.1,1c-0.1,3.3,0,6.5,0,9.8c0,0.8-0.2,0.9-1,0.9c-4.7,0-9.4,0-14.1,0
   c-2.8,0-5,1.1-6.9,3.1c-5,5.6-5.5,14.9-1,20.9c1.9,2.6,4.4,4,7.7,4c4.7-0.1,9.4,0,14.1,0c0.8,0,1.1,0.1,1.1,1c-0.1,2.9,0,5.9,0,8.8
   c0,0.8-0.2,1-1,1C348.2,84.1,345.1,84.1,341.4,84.1z'
      />
      <path
        fill={color1}
        d='M43.4,9.5c6.8,0.8,13,3.1,18.3,7.5C72.1,25.7,76.6,36.8,74,50c-2.7,13.6-11.2,22.6-24.6,26.5
   c-19,5.6-38.6-5.5-43.7-24.3C0.9,34.4,11.8,15.7,30,10.6c1.9-0.5,3.8-0.8,5.7-1.2C38.3,9.4,40.9,9.3,43.4,9.5z M38.4,13.9
   c-1.2,0-3.6,0.3-5.9,0.8C16.5,18.3,6.1,34.6,10,50.3c5.2,21.1,28.7,28.5,45.1,19c10.6-6.1,16.5-18.1,14.7-29.8
   C67.5,24.7,54.8,13.9,38.4,13.9z'
      />
      <path
        fill={color1}
        d='M402.1,50.1c0-11,0-21.9,0-32.9c0-1,0.2-1.4,1.3-1.3c2.9,0.1,5.8,0.1,8.7,0c0.9,0,1.2,0.2,1.2,1.1
   c0,22,0,44.1,0,66.1c0,0.8-0.2,1.1-1.1,1.1c-3,0-6-0.1-9,0c-1,0-1.2-0.3-1.2-1.2C402.1,72,402.1,61,402.1,50.1z'
      />
      <path
        fill={secondaryColor2}
        d='M43.4,9.5c-2.6-0.2-5.1-0.1-7.7,0c-0.3-1-0.2-2.1-0.1-3.2c0-1-0.3-1.2-1.2-1.2C27,5.1,19.5,5,12.1,5
   C8.4,5,5.3,3.9,2.7,1.3C2.5,1.2,2.4,1.1,2,0.7c25.2,0.5,50,0.5,74.9,0c0.1,0.5-0.2,0.7-0.5,0.9C73.8,4,70.7,5,67.2,5
   c-7.4,0-14.9,0.1-22.3,0.1c-0.9,0-1.2,0.2-1.2,1.2C43.7,7.3,44,8.5,43.4,9.5z'
      />
      <path
        fill={color1}
        d='M39.7,25.5c0.9,1.2,1.4,2.5,1.4,4c-0.1,3.5,0,6.9,0,10.4c0,0.6,0.1,1,0.6,1.4c1.3,1.2,1.4,3,0.2,4.2
   c-1.2,1.2-3.1,1.2-4.3,0c-1.2-1.2-1.2-3.1,0.2-4.2c0.5-0.5,0.6-0.9,0.6-1.5c0-3.5,0-7,0-10.5C38.3,27.6,38.4,27.3,39.7,25.5z'
      />
      <path
        fill={secondaryColor2}
        d='M14.6,42.3c0.8,0.1,1.4,0.5,1.4,1.3c0,0.8-0.5,1.3-1.4,1.3c-0.8,0-1.3-0.5-1.3-1.3
   C13.3,42.9,13.8,42.4,14.6,42.3z'
      />
      <path
        fill={secondaryColor2}
        d='M39.6,70.2c0-0.9,0.5-1.4,1.3-1.4c0.9,0,1.5,0.5,1.5,1.4c0,0.9-0.5,1.3-1.4,1.4C40.2,71.5,39.6,71.1,39.6,70.2z
   '
      />
      <path
        fill={secondaryColor2}
        d='M39.6,20.5c-0.8-0.1-1.4-0.5-1.3-1.4c0-0.8,0.5-1.3,1.3-1.3c0.8,0,1.4,0.5,1.4,1.3C41,20,40.5,20.5,39.6,20.5z'
      />
      <path
        fill={secondaryColor2}
        d='M64.6,45c-0.8-0.1-1.4-0.5-1.3-1.4c0-0.8,0.5-1.3,1.3-1.3c0.8,0,1.4,0.5,1.4,1.3C66,44.5,65.4,44.9,64.6,45z'
      />
    </svg>
  );
};

export default FoodScale;
