import { gql } from '@apollo/client';

export const CreateUserGQL = gql`
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      token
      user {
        id
        firstName
        lastName
        avatar
        email
        status
        country
        languageId
        databasePreference
        location
        database {
          id
          name
          owner {
            avatar
          }
        }
        internalRoles {
          internalRoleId
        }
      }
    }
  }
`;

export const UpdateUserGQL = gql`
  mutation updateUser($input: UserUpdateInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      avatar
      email
      status
      country
      languageId
      databasePreference
      location
      database {
        id
        name
        owner {
          avatar
        }
      }
      internalRoles {
        internalRoleId
      }
    }
  }
`;

export const ChangePasswordGQL = gql`
  mutation ChangePassword($input: UserChangePasswordInput!) {
    changePassword(input: $input) {
      id
    }
  }
`;

export const UpdateNotificationSettingsGQL = gql`
  mutation setUserNotificationSettings($input: UserNotificationSettingsInput) {
    setUserNotificationSettings(input: $input) {
      emailNotifications
      pushNotifications
      typeEmailNotifications
    }
  }
`;

export const UpdateEmailGQL = gql`
  mutation changeEmail($input: UserChangeEmailInput!) {
    changeEmail(input: $input) {
      email
    }
  }
`;

export const setEmailGQL = gql`
  mutation setNewEmail($input: setNewEmailInput!) {
    setNewEmail(input: $input) {
      email
    }
  }
`;

export const CreateDabaseGQL = gql`
  mutation CreateDataBase($input: DatabaseInput!) {
    createDatabase(input: $input) {
      id
      owner {
        id
        firstName
        lastName
        avatar
      }
      name
      description
      language {
        name
        fullName
        available
      }
      unit {
        name
        type
        fullName
      }
      country
    }
  }
`;

export const AvatarUpload = gql`
  mutation changeAvatar($image: Upload!) {
    changeAvatar(image: $image) {
      avatar
    }
  }
`;

export const DeleteAvatarGQL = gql`
  mutation {
    deleteAvatar {
      avatar
    }
  }
`;

export const SetDBPreference = gql`
  mutation SetDBPreference($statusPreference: String!) {
    setUserDatabasePreference(statusPreference: $statusPreference) {
      status
    }
  }
`;

export const userRequestDBAccessGQL = gql`
  mutation createRequestAccessDatabaseForUser($input: RequestAccessDatabaseForUser!) {
    createRequestAccessDatabaseForUser(input: $input) {
      status
    }
  }
`;

export const ownerRequestDBAccessGQL = gql`
  mutation createRequestAccessDatabaseForOwner($input: RequestAccessDatabaseForOwner!) {
    createRequestAccessDatabaseForOwner(input: $input) {
      status
    }
  }
`;

export const userAcceptRequestDBAccessGQL = gql`
  mutation acceptDatabaseReferencesForUser($input: acceptDatabaseReferencesForUser!) {
    acceptDatabaseReferencesForUser(input: $input) {
      status
    }
  }
`;

export const ownerAcceptRequestDBAccessGQL = gql`
  mutation acceptDatabaseReferencesForOwner($input: acceptDatabaseReferencesForOwner!) {
    acceptDatabaseReferencesForOwner(input: $input) {
      status
    }
  }
`;

export const userDeleteRequestDBAccessGQL = gql`
  mutation deleteDatabaseReferencesForUser($input: deleteDatabaseReferencesForUser!) {
    deleteDatabaseReferencesForUser(input: $input) {
      status
    }
  }
`;

export const ownerDeleteRequestDBAccessGQL = gql`
  mutation deleteDatabaseReferencesForOwner($input: deleteDatabaseReferencesForOwner!) {
    deleteDatabaseReferencesForOwner(input: $input) {
      status
    }
  }
`;

export const GetOptCode = gql`
  mutation sendOTPNumber($input: SendOTPNumberInput!) {
    sendOTPNumber(input: $input) {
      status
    }
  }
`;

export const createTagGQL = gql`
  mutation createTag($input: TagInput!) {
    createTag(input: $input) {
      id
      name
      databaseId
    }
  }
`;

export const editTagGQL = gql`
  mutation editTag($input: TagEditInput!) {
    editTag(input: $input) {
      id
      name
      databaseId
    }
  }
`;

export const mergeTagGQL = gql`
  mutation mergeTag($input: TagMergeInput!) {
    mergeTag(input: $input) {
      id
      name
      databaseId
    }
  }
`;

export const deleteTagsGQL = gql`
  mutation deleteTags($input: TagDeleteInput!) {
    deleteTags(input: $input) {
      status
    }
  }
`;

export const uploadFileToDataBaseGQL = gql`
  mutation uploadFileToFillDatabase($input: UploadFileToFillDatabase!) {
    uploadFileToFillDatabase(input: $input)
  }
`;

export const ValidateOptCode = gql`
  mutation validateOTPNumber($input: OTPNumberInput!) {
    validateOTPNumber(input: $input) {
      token
    }
  }
`;

export const setNewPasswordGQL = gql`
  mutation setNewPassword($password: String!, $passwordResetToken: String!) {
    setNewPassword(password: $password, passwordResetToken: $passwordResetToken) {
      status
    }
  }
`;

export const RestorePasswordGQL = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      status
    }
  }
`;

export const setSettingsBoughtItemsGQL = gql`
  mutation saveBoughtItemSettings($settings: BoughtItemsSettingsInput!, $databaseId: String!) {
    saveBoughtItemSettings(settings: $settings, databaseId: $databaseId) {
      status
    }
  }
`;

export const setSettingsProductsGQL = gql`
  mutation saveProductSettings($settings: ProductSettingsInput!, $databaseId: String!) {
    saveProductSettings(settings: $settings, databaseId: $databaseId) {
      status
    }
  }
`;

export const setSettingsIngridientsGQL = gql`
  mutation saveIngredientSettings($settings: IngredientsSettingsInput!, $databaseId: String!) {
    saveIngredientSettings(settings: $settings, databaseId: $databaseId) {
      status
    }
  }
`;

export const setSettingsResipesGQL = gql`
  mutation saveRecipeSettings($settings: RecipeSettingsInput!, $databaseId: String!) {
    saveRecipeSettings(settings: $settings, databaseId: $databaseId) {
      status
    }
  }
`;

export const getCrossAuthTokenGQL = gql`
  mutation getCrossAuthToken {
    getCrossAuthToken {
      token
    }
  }
`;

export const setDatabaseSettings = gql`
  mutation createDatabaseSettings($input: DatabaseSettingsInput!) {
    createDatabaseSettings(input: $input) {
      status
    }
  }
`;

export const createBoughtItemGQL = gql`
  mutation (
    $name: String!
    $databaseId: Int!
    $boughtItemSourceListItems: [BoughtItemSourceListItem]
    $selectedUnitOfPackage: String
    $amountInPackage: Float
    $selectedDBUnit: Int
    $allergenSource: String
    $boughtItemAllergenItems: [BoughtItemAllergenItem]!
    $nutritionalValueSource: String
    $boughtItemNutriasItems: BoughtItemNutriasItem
    $ingredientListSource: String
    $declarationName: String
    $kindOfIngredientList: String
    $asTextIngredientList: String
    $boughtItemIngredientListItems: [BoughtItemIngredientListItem]!
    $boughtItemIngredientAdditivesListItems: [BoughtItemIngredientAdditivesListItem]!
    $isReconstituted: Boolean
    $reconstitutedIngredientName: String
    $reconstitutedAmountOfProduct: Float
    $reconstitutedAmountOfWater: Float
    $foodSymbolsSource: String
    $boughtItemFoodSymbolsItems: BoughtItemFoodSymbolsItem
    $confirmationSymbols: Boolean
  ) {
    createBoughtItem(
      name: $name
      databaseId: $databaseId
      boughtItemSourceListItems: $boughtItemSourceListItems
      selectedUnitOfPackage: $selectedUnitOfPackage
      amountInPackage: $amountInPackage
      selectedDBUnit: $selectedDBUnit
      allergenSource: $allergenSource
      boughtItemAllergenItems: $boughtItemAllergenItems
      nutritionalValueSource: $nutritionalValueSource
      boughtItemNutriasItems: $boughtItemNutriasItems
      ingredientListSource: $ingredientListSource
      declarationName: $declarationName
      kindOfIngredientList: $kindOfIngredientList
      asTextIngredientList: $asTextIngredientList
      boughtItemIngredientListItems: $boughtItemIngredientListItems
      boughtItemIngredientAdditivesListItems: $boughtItemIngredientAdditivesListItems
      isReconstituted: $isReconstituted
      reconstitutedIngredientName: $reconstitutedIngredientName
      reconstitutedAmountOfProduct: $reconstitutedAmountOfProduct
      reconstitutedAmountOfWater: $reconstitutedAmountOfWater
      foodSymbolsSource: $foodSymbolsSource
      boughtItemFoodSymbolsItems: $boughtItemFoodSymbolsItems
      confirmationSymbols: $confirmationSymbols
    ) {
      status
    }
  }
`;

export const updateBoughtItemGQL = gql`
  mutation (
    $name: String!
    $boughtItemId: Int!
    $databaseId: Int!
    $boughtItemSourceListItems: [BoughtItemSourceListItem]
    $selectedUnitOfPackage: String
    $amountInPackage: Float
    $selectedDBUnit: Int
    $allergenSource: String
    $boughtItemAllergenItems: [BoughtItemAllergenItem]
    $nutritionalValueSource: String
    $boughtItemNutriasItems: BoughtItemNutriasItem
    $ingredientListSource: String
    $declarationName: String
    $kindOfIngredientList: String
    $asTextIngredientList: String
    $boughtItemIngredientListItems: [BoughtItemIngredientListItem]
    $boughtItemIngredientAdditivesListItems: [BoughtItemIngredientAdditivesListItem]
    $isReconstituted: Boolean
    $reconstitutedIngredientName: String
    $reconstitutedAmountOfProduct: Float
    $reconstitutedAmountOfWater: Float
    $foodSymbolsSource: String
    $boughtItemFoodSymbolsItems: BoughtItemFoodSymbolsItem
    $confirmationSymbols: Boolean
  ) {
    updateBoughtItem(
      name: $name
      boughtItemId: $boughtItemId
      databaseId: $databaseId
      boughtItemSourceListItems: $boughtItemSourceListItems
      selectedUnitOfPackage: $selectedUnitOfPackage
      amountInPackage: $amountInPackage
      selectedDBUnit: $selectedDBUnit
      allergenSource: $allergenSource
      boughtItemAllergenItems: $boughtItemAllergenItems
      nutritionalValueSource: $nutritionalValueSource
      boughtItemNutriasItems: $boughtItemNutriasItems
      ingredientListSource: $ingredientListSource
      declarationName: $declarationName
      kindOfIngredientList: $kindOfIngredientList
      asTextIngredientList: $asTextIngredientList
      boughtItemIngredientListItems: $boughtItemIngredientListItems
      boughtItemIngredientAdditivesListItems: $boughtItemIngredientAdditivesListItems
      isReconstituted: $isReconstituted
      reconstitutedIngredientName: $reconstitutedIngredientName
      reconstitutedAmountOfProduct: $reconstitutedAmountOfProduct
      reconstitutedAmountOfWater: $reconstitutedAmountOfWater
      foodSymbolsSource: $foodSymbolsSource
      boughtItemFoodSymbolsItems: $boughtItemFoodSymbolsItems
      confirmationSymbols: $confirmationSymbols
    ) {
      status
    }
  }
`;

export const saveIngredientGroupsSettings = gql`
  mutation saveIngredientGroupsSettings($settings: IngredientGroupsSettingsInput!, $databaseId: Int!) {
    ingredientGroups: saveIngredientGroupsSettings(settings: $settings, databaseId: $databaseId) {
      status
    }
  }
`;

export const createIngredientGroup = gql`
  mutation createIngredientGroup($name: String, $databaseId: Int!) {
    createIngredientGroup(name: $name, databaseId: $databaseId) {
      status
    }
  }
`;

export const updateIngredientGroup = gql`
  mutation updateIngredientGroup($newName: String!, $ingredientGroupId: Int!, $databaseId: Int!) {
    updateIngredientGroup(newName: $newName, ingredientGroupId: $ingredientGroupId, databaseId: $databaseId) {
      status
    }
  }
`;

export const deleteIngredientGroup = gql`
  mutation deleteIngredientGroup($ingredientGroupIds: [Int]!, $databaseId: Int!) {
    deleteIngredientGroup(ingredientGroupIds: $ingredientGroupIds, databaseId: $databaseId) {
      status
    }
  }
`;

export const deleteBoughtItems = gql`
  mutation deleteBoughtItems($databaseId: Int!, $boughtItemIds: [Int]) {
    deleteBoughtItems(databaseId: $databaseId, boughtItemIds: $boughtItemIds) {
      status
    }
  }
`;

export const mergeIngredientGroups = gql`
  mutation mergeIngredientGroups($databaseId: Int!, $ingredientGroupIds: [Int]!, $newName: String!) {
    mergeIngredientGroups(databaseId: $databaseId, ingredientGroupIds: $ingredientGroupIds, newName: $newName) {
      name
      databaseId
      id
    }
  }
`;

export const createFID = gql`
  mutation createFID($name: String!, $databaseId: Int!, $additionalInfo: String) {
    createFID(name: $name, databaseId: $databaseId, additionalInfo: $additionalInfo) {
      status
    }
  }
`;
