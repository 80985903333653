import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
  svgWrap: {
    width: 30,
    height: 30,
    overflow: 'hidden',
    position: 'relative',
  },
}));

const AvatarShape = ({
  color = '#fff',
  height = 30,
  width = 30,
  classNameWrap,
  href = '',
}) => {
  const classes = useStyles();
  const id = Math.random();
  return (
    <div className={clsx(classes.svgWrap, classNameWrap)}>
      <svg
        version='1.1'
        id='Ebene_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        height={height}
        width={width}
        style={{ position: 'absolute', zIndex: 2, top: 0, left: 0 }}
        x='0px'
        y='0px'
        viewBox='0 0 289.6 273.4'
        xmlSpace='preserve'
      >
        <defs>
          <pattern
            id={`imgpattern${id}`}
            x='0'
            y='0'
            width='1'
            height='1'
            viewBox='0 0 1024 576'
            preserveAspectRatio='xMidYMid slice'
          >
            <image width='1024' height='576' xlinkHref={href} href={href} />
          </pattern>
        </defs>
        <path
          fill={`url(#imgpattern${id})`}
          d='
          M199.3,255.2
          H66.4
          c-29.2,0-51.2-23.9-48.8-53.1
          L40,60
          c7.8-29.1,28.2-53.1,57.4-53.1
          h126.8
          c29.2,0,51.2,23.9,48.8,53.1
          l-18,129.6
          C248.9,233.8,228.4,255.2,199.3,255.2z'
        />
        <path
          style={{ fill: color }}
          d='
          M222.5,17.5
          c29.2,0,51.2,23.9,48.8,53.1
          l-18,129.6
          c-7.7,31.7-28.2,53.1-57.4,53.1
          H66.4
          c-29.2,0-51.2-23.9-48.8-53.1
          L38.3,70.6
          c7.8-29.1,28.2-53.1,57.4-53.1
          H222.5 
          M222.5,6.5
          H95.7c-16.7,0-32,6.5-44.4,18.8
          c-10.8,10.7-19,25.4-23.5,42.4
          l-0.1,0.6
          l-0.1,0.6
          L6.8,198.4
          l-0.1,0.4
          l0,0.4
          c-1.4,17.2,4.1,33.5,15.5,45.9
          c11.3,12.3,27.1,19.1,44.3,19.1
          H196
          c16.9,0,32.2-6.1,44.3-17.7
          c11.1-10.6,19.3-25.8,23.7-43.8
          l0.1-0.5
          l0.1-0.6
          l18-129.6
          l0-0.3
          l0-0.3
          c1.4-17.2-4.1-33.5-15.5-45.9
          C255.4,13.3,239.7,6.5,222.5,6.5
          L222.5,6.5z'
        />
      </svg>
    </div>
  );
};

export default AvatarShape;
