import { Box, Typography, useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../../app/config/constants';
import MiniDrawer from '../../components/layoutComponents/Drawer/Drawer';
import TopNavBar from '../../components/layoutComponents/NavBar/Navbar';
import Flex from '../../components/UI/Flex/Flex';
import Typo from '../../components/UI/Typo/Typo';
import { useStore } from '../../hooks';
import theme from '../../theme';
import NutriLogo from '../../theme/images/logo_nutrimero.png';
import useStyles from './styles';

const MainLayout = ({ children }) => {
  const { pathname } = useLocation();
  const isChoice = useMemo(
    () =>
      pathname.includes(ROUTES.login) ||
      pathname.includes(ROUTES.spaceChoice) ||
      pathname.includes(ROUTES.registrationStep2DefineSpace) ||
      pathname.includes(ROUTES.registrationStep1Back) ||
      pathname.includes(ROUTES.registrationStep3aGetOneTimePassword) ||
      pathname.includes(ROUTES.registrationStep3aValidationPassword) ||
      pathname.includes(ROUTES.createDB),
    [pathname],
  );
  const smallScreen = useMediaQuery('@media screen and (max-width:1023px)');
  const tooSmall = useMediaQuery('@media screen and (max-width:800px)');
  const bigScreen = useMediaQuery('@media screen and (min-width:1280px)') && !isChoice;
  const higherScreen = useMediaQuery('@media screen and (max-height:1000px)');
  const { isAuth, user } = useStore();

  const intl = useIntl();
  const classes = useStyles({
    ...{
      big: !smallScreen && !isChoice,
      small: !bigScreen,
      maxH: higherScreen,
      smaller: smallScreen,
      isAuth,
      isChoice,
    },
    ...theme,
  });
  return (
    <Box className={classes.bgPattern}>
      {smallScreen && (
        <Box
          className={
            !tooSmall ? classes.containerActiveMessageContainer : classes.containerActiveMessageContainerTooSmall
          }
        >
          <Flex row fend>
            <img src={NutriLogo} height={50} width={200} alt='Logo' />
          </Flex>
          <Flex column>
            <Typo fontWeight='bold' fontFamily="'Open Sans Condensed', sans-serif" color='logoSecondary' fontSize='ml'>
              {intl.formatMessage({ id: 'tech_browser_too_small_1' })}
            </Typo>
            <Flex column flex={1} style={{ marginTop: 20 }}>
              <Typo fontWeight='lighter' fontSize='ms' style={{ letterSpacing: -0.6 }} color='logoSecondary'>
                {intl.formatMessage({ id: 'tech_browser_too_small_2' })}
              </Typo>
            </Flex>
          </Flex>
        </Box>
      )}
      {!smallScreen && (
        <Box
          className={classNames(
            classes.appContent,
            classes.pageContainerSmall,
            bigScreen && classes.pageContainerLarge,
          )}
        >
          <TopNavBar />
          <Box
            className={classNames(
              classes.page,
              bigScreen && isAuth && classes.biggerPage,
              isChoice && classes.pageNoPadding,
            )}
          >
            {isAuth && user?.databasePreference && !isChoice && <MiniDrawer />}
            <Box className={classes.mainContent}>{children}</Box>
          </Box>
          <footer className={classes.footer}>
            <Flex flex={1}>
              <Typography>© 2021 food IT Consulting</Typography>
            </Flex>
            <Flex flex={0.3} row between>
              <Typography>imprint </Typography>
              <Typography>privacy</Typography>
              <Typography>contact</Typography>
            </Flex>
          </footer>
        </Box>
      )}
    </Box>
  );
};

export default MainLayout;
