import React, { useState } from 'react';

import pallete from '../../../../theme/pallete';

const InternalSettings = ({
  color = pallete.secondary1,
  height = 25,
  width = 132,
  secondaryColor = pallete.logoSecondary,
  selected,
  disabled,
  nonIA = false,
}) => {
  let color1 = color;
  let secondaryColor2 = secondaryColor;
  const [hovered, setHovered] = useState(false);
  if (selected || hovered) {
    color1 = pallete.backgroundWhite;
  } else if (disabled) {
    color1 = '#B2B2B2';
    secondaryColor2 = '#777777';
  }
  return (
    <svg
      version='1.1'
      id='Ebene_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={width}
      height={height}
      viewBox='0 0 453.8 85.2'
      // style='enable-background:new 0 0 453.8 85.2;'
      xmlSpace='preserve'
      onMouseEnter={() => !nonIA && setHovered(true)}
      onMouseLeave={() => !nonIA && setHovered(false)}
    >
      <path
        fill={color1}
        d='M0.4,61.4c0.6-1,1.4-1.5,2.6-1.5c1.1,0,1.7-0.4,2.2-1.5c0.8-1.6,1.2-2.9-0.4-4.3c-0.8-0.7-0.5-1.7,0.3-2.4
	c0.9-0.8,1.8-1.6,2.7-2.3c1.1-0.8,2.2-0.8,3.4,0.2c1,0.8,1.9,1.2,3.1,0.4c0.2-0.1,0.4-0.2,0.6-0.2c1.6-0.2,2.5-0.8,2.5-2.6
	c0-1.2,1-1.8,2.2-1.8c1.3,0,2.6,0.1,3.9,0.2c1.5,0.1,2.2,0.8,2.3,2.2c0.1,1.3,0.4,2.2,1.9,2.4c0.1,0,0.2,0.1,0.3,0.1
	c1.4,1,2.6,0.8,3.9-0.3c0.9-0.8,2.2-0.6,3.2,0.3c0.9,0.8,1.7,1.6,2.5,2.4c1,1,1.1,2,0.1,3c-1.1,1.1-1.3,1.9-0.6,3.4
	c0.6,1.3,1.4,1.7,2.6,1.8c2.1,0.2,2.8,0.9,2.8,3.1c0,0.7,0,1.4,0,2.2c0,1.5-0.8,2.4-2.3,2.4c-1.8-0.1-2.7,0.5-3.3,2.2
	c-0.5,1.2-0.4,1.8,0.4,2.6c1.6,1.7,1.5,2.5-0.3,4c-0.6,0.5-1.1,1-1.7,1.5c-1.2,1-2.3,1-3.6,0.1c-0.4-0.3-0.8-0.6-1.3-0.9
	c-0.2-0.1-0.4-0.4-0.6-0.3c-1.2,0.4-2.5,0.8-3.7,1.3c-0.5,0.2-0.3,0.7-0.3,1.1c-0.3,2.4-1,3-3.4,2.9c-1,0-1.9-0.1-2.9-0.1
	c-1.4-0.1-2.3-0.8-2.3-2.2c-0.1-1.4-0.6-2.3-2.1-2.5c-0.2,0-0.4-0.1-0.6-0.2c-1.2-0.8-2.2-0.7-3.3,0.2c-1.2,1-2.4,0.8-3.6-0.2
	c-0.8-0.7-1.6-1.5-2.4-2.2c-1.1-1.1-1.2-2.1-0.1-3.1c1-0.9,1.3-1.8,0.4-2.9c-0.1-0.1-0.1-0.1-0.1-0.2c-0.3-1.5-1.2-2.2-2.8-2.1
	c-1.1,0-1.8-0.7-2.4-1.6C0.4,64.4,0.4,62.9,0.4,61.4z M21.2,57.5c-0.4,0-0.8,0-1.2,0.1c-2.8,0.4-4.9,1.8-5.9,4.5
	c-0.9,2.4,0,5.1,2.1,6.9c2.4,2.1,6.1,2.5,9,1c3.5-1.8,4.6-5.9,2.4-9.1C26.1,58.6,23.8,57.6,21.2,57.5z'
      />
      <path
        fill={secondaryColor2}
        d='M371.9-0.5c0.3,0.4,0.2,0.9,0.2,1.4c-0.1,2.9-0.2,5.8-0.2,8.7c0,0.7-0.1,1-0.9,1c-2.3-0.1-4.6-0.1-6.8,0
	c-0.8,0-0.9-0.2-0.9-0.9c0.1-3.2,0.1-6.4,0.1-9.6c0-0.2-0.1-0.4-0.1-0.6c0.6,0,1.1,0,1.7,0c2,0.4,4,0.3,6,0
	C371.3-0.5,371.6-0.5,371.9-0.5z'
      />
      <path
        fill={secondaryColor2}
        d='M32.3,47.8c-0.8,0-1.7,0-2.5,0c-1.4,0-2.3-0.6-2.5-2c-0.2-2.2-1.3-3-3.3-3.6c-1.9-0.5-3.1-0.5-4.5,0.9
	c-1.2,1.2-2.4,1.1-3.7,0.1c-1.2-0.9-2.5-1.9-3.7-2.9c-1.3-1.1-1.6-2.1-0.4-3.3c1.4-1.4,1.2-2.5,0.2-4.1c-1-1.8-2-2.4-3.9-2.3
	c-1.9,0.1-2.6-0.7-2.8-2.7c-0.1-1.3-0.3-2.7-0.4-4c-0.1-1.4,0.5-2.3,1.8-2.3c2.5,0,3.4-1.2,4.1-3.5c0.5-1.5,0.4-2.3-0.8-3.2
	c-0.3-0.2-0.5-0.5-0.8-0.8c-0.8-0.9-0.8-1.9,0-2.8c1.1-1.2,2.3-2.4,3.5-3.6c0.9-0.9,2.1-1.1,3-0.3c1.9,1.6,3.6,1.3,5.7,0.3
	C23,7,23.7,6.3,23.6,4.5C23.5,2.7,24.2,2.1,26,2c1.8-0.1,3.5-0.2,5.3-0.2c1.4,0,2.3,0.6,2.4,2c0.2,2.2,1.3,3,3.3,3.7
	c1.8,0.6,3,0.5,4.4-0.8c1.4-1.4,2.5-1.3,4,0c1.2,1,2.5,1.9,3.7,2.9c1.1,0.9,1.4,2,0.3,3.1c-1.5,1.6-1.7,3-0.4,4.9
	c0.8,1.1,1.4,1.6,2.6,1.6c3.8,0.2,3.9,0.3,4.3,4.1c0.1,0.9,0.2,1.7,0.2,2.6c0.1,1.5-0.4,2.1-1.9,2.4c-0.9,0.2-1.7,0.3-2.6,0.4
	c-0.6,0.1-1,0.3-1.1,1c-0.1,0.8-0.4,1.6-0.8,2.2c-0.6,1-0.3,1.5,0.5,2.2c3,2.8,2.9,2.9,0.1,5.8c-0.6,0.6-1.2,1.3-1.8,1.9
	c-1.1,1.1-2.2,1.4-3.3,0.5c-2-1.7-3.8-1.5-6.1-0.4c-1.3,0.7-1.8,1.3-1.7,2.7c0.1,2.5-0.5,3-3,3.1C33.8,47.7,33,47.7,32.3,47.8
	C32.3,47.8,32.3,47.8,32.3,47.8z M29.9,16.6c-0.5,0-0.9,0-1.4,0.1c-3.1,0.5-5.6,2-6.8,5c-1.1,2.8-0.6,5.4,1.3,7.7
	c3.1,3.8,9.2,4.6,13.2,1.8c3.5-2.5,4.3-7.1,1.8-10.7C36,17.8,33.2,16.7,29.9,16.6z'
      />
      <path
        fill={secondaryColor2}
        d='M408.4,68.2c2,1.3,4.1,2.6,6.6,3.1c3.1,0.7,5.2-0.5,6.3-3.5c0.8-2.3,0.9-4.7,1-7.1c0-1.2,0-2.4,0-3.8
	c-0.6,0.9-1,1.7-1.5,2.4c-1.7,2.3-3.8,3.9-6.9,3.3c-1.7-0.3-2.9-1.3-3.9-2.6c-2-2.5-2.9-5.4-3.4-8.4c-1.6-8.8-1.2-17.4,1.7-25.9
	c0.7-2,1.7-3.9,3-5.7c2.5-3.5,6-5,10.2-4.9c3.4,0.1,6.6,1.1,9.7,2.3c0.7,0.3,0.6,0.7,0.6,1.2c-0.2,5.2-0.3,10.4-0.5,15.5
	c-0.4,11.6-0.4,23.2-0.7,34.8c-0.1,3.2-0.5,6.4-1.9,9.4c-1.9,4.4-5.8,6.5-10.5,5.9c-3.2-0.4-6.2-1.1-9.1-2.5c-0.4-0.2-0.8-0.4-0.8-1
	C408.4,76.6,408.4,72.4,408.4,68.2z M423,33.8c0-0.9,0-2.5,0-4.1c0-0.6-0.2-0.8-0.7-1c-2-0.6-3.9-0.1-5.3,1.5
	c-0.7,0.8-1.2,1.8-1.5,2.8c-1.3,3.6-1.5,7.3-1.1,11.1c0.1,1.5,0.4,2.9,1.2,4.2c1.4,2.3,3.3,2.3,4.8,0.1c0.6-1,1-2.1,1.3-3.2
	C422.8,41.7,422.8,38.1,423,33.8z'
      />
      <path
        fill={secondaryColor2}
        d='M385.9,21.9c0.5-0.9,0.8-1.6,1.2-2.3c2.2-3.9,6.1-5.3,9.7-3.8c1.9,0.8,3.1,2.2,3.9,4c1.5,3.2,1.7,6.6,1.6,9.9
	c-0.2,10.6-0.6,21.3-0.6,31.9c0,0.6-0.2,0.8-0.8,0.8c-2.4,0-4.7-0.1-7.1,0c-0.9,0-1-0.3-1-1.1c0.4-7.4,0.5-14.7,0.6-22.1
	c0-2.2,0-4.5-0.6-6.6c-0.4-1.3-0.9-2.7-2.5-2.9c-1.6-0.2-2.3,1.1-2.9,2.3c-1.1,2.2-1.5,4.6-1.7,7c-0.7,7.3-0.7,14.6-0.8,22
	c0,1.2-0.4,1.4-1.5,1.4c-2.2-0.1-4.4-0.1-6.6,0c-0.7,0-1-0.1-1-1c0.9-14.7,1.2-29.5,1.3-44.2c0-0.7,0.1-1,0.9-0.9
	c2.3,0.2,4.6,0.2,6.9,0c0.7-0.1,0.9,0.1,0.9,0.8C385.8,18.6,385.9,20.1,385.9,21.9z'
      />
      <path
        fill={color1}
        d='M108.3,21.8c0.4-0.8,0.8-1.6,1.3-2.4c2.2-3.8,6.1-5.3,9.7-3.7c2.1,0.9,3.3,2.7,4.1,4.7c1.4,3.5,1.4,7.2,1.3,11
	c-0.1,3.9-0.2,7.8-0.3,11.7c-0.1,6.1-0.2,12.1-0.3,18.2c0,0.8-0.2,1.1-1,1.1c-2.3-0.1-4.6-0.1-6.8,0c-0.7,0-1.1-0.1-1-1
	c0.3-7.4,0.5-14.7,0.6-22.1c0-2.2,0-4.5-0.6-6.6c-0.4-1.4-0.9-2.8-2.6-3c-1.6-0.2-2.3,1.2-3,2.4c-1.5,3-1.7,6.3-1.9,9.5
	c-0.4,6.5-0.5,13-0.6,19.5c0,1-0.2,1.3-1.3,1.3c-2.2-0.1-4.5-0.1-6.7,0c-0.9,0-1.1-0.2-1.1-1.1c0.4-5.8,0.6-11.5,0.8-17.3
	c0.3-8.1,0.4-16.2,0.5-24.3c0-0.9,0.1-1.8,0.1-2.6c0-0.6,0.2-0.8,0.9-0.8c2.4,0.2,4.7,0.2,7.1,0c0.6-0.1,0.8,0.2,0.8,0.8
	C108.3,18.6,108.3,20.2,108.3,21.8z'
      />
      <path
        fill={color1}
        d='M203.4,22c0.8-1.6,1.4-3,2.4-4.2c3.5-4,9.4-3.5,12,1.1c1.6,2.8,2,5.9,2,9c0,5.4-0.2,10.8-0.3,16.1
	c-0.1,5.7-0.3,11.4-0.3,17.1c0,1-0.3,1.3-1.3,1.2c-2.2-0.1-4.3-0.1-6.5,0c-1,0-1.2-0.3-1.1-1.2c0.4-7.4,0.5-14.8,0.6-22.2
	c0-2,0-3.9-0.5-5.8c-0.4-1.5-0.8-3.2-2.7-3.4c-1.8-0.2-2.6,1.4-3.2,2.8c-1.4,3.2-1.6,6.6-1.8,10.1c-0.3,6.2-0.5,12.4-0.5,18.6
	c0,1-0.3,1.2-1.2,1.2c-2.3-0.1-4.6-0.1-7,0c-0.8,0-0.9-0.2-0.9-1c0.5-7.2,0.8-14.5,0.9-21.7c0.1-7.4,0.3-14.9,0.4-22.3
	c0-0.9,0.3-1.2,1.2-1.1c2.2,0.2,4.4,0.2,6.6,0c0.8-0.1,1.1,0.1,1,1C203.3,18.7,203.4,20.2,203.4,22z'
      />
      <path
        fill={color1}
        d='M235.9,53.1c-0.2,3.2-0.9,6.3-1.2,9.4c0,0.5-0.4,0.5-0.8,0.6c-4.4,1.4-8-0.2-9.6-4.5
	c-2.1-5.5-2.1-11.1-0.1-16.7c1.3-3.4,3.7-5.7,6.9-7.3c1.5-0.7,3-1.3,4.4-2.1c2.2-1.2,3-3.9,1.9-6.1c-0.5-1-1.3-1.5-2.4-1.4
	c-2.2,0.1-4.2,1-6,2.1c-1,0.6-2,1.3-3,2c-0.1-1.6,0.2-3,0.2-4.4c0.1-2.1,0.3-4.1,0.4-6.2c0-0.4,0-0.7,0.5-0.9
	c3.7-1.5,7.4-2.9,11.4-2.6c4.3,0.4,7,3.1,7.8,7.5c0.4,2.1,0.5,4.3,0.4,6.4c-0.4,10.8-0.7,21.5-0.8,32.3c0,0.8-0.3,1-1,1
	c-2.2-0.1-4.3-0.1-6.5,0c-1,0-1.2-0.2-1.1-1.2c0.3-6.2,0.4-12.4,0.6-18.6c0-0.1,0-0.2-0.1-0.5c-1.6,1.5-3.4,2.3-5,3.6
	c-1.8,1.5-2.3,4.8-1.3,6.8c0.5,1,1.4,1.4,2.5,1.2C234.8,53.5,235.4,53.2,235.9,53.1L235.9,53.1z'
      />
      <path
        fill={secondaryColor2}
        d='M319.1,48.8c-0.3,3.3-0.5,6.5-0.8,9.7c0,0.4-0.1,0.8-0.1,1.2c-0.2,2.5,0.3,2-2.2,2.9c-2.8,1-5.6,1.2-8.5,0.8
	c-3.2-0.5-5.5-2.3-7.1-5.1c-1.7-3-2.5-6.3-3-9.6c-1.1-8.3-0.6-16.5,2-24.5c0.7-2.1,1.7-4,3.2-5.7c4.1-4.8,11.6-4.2,15,1.1
	c1.9,2.9,2.7,6.2,3.1,9.6c0.5,3.7,0.5,7.4,0.2,11.1c-0.1,0.8-0.4,1-1.1,0.9c-4.4,0-8.7,0.1-13.1,0c-1.1,0-1.3,0.4-1.3,1.3
	c0.1,1.9,0.2,3.7,0.9,5.5c1.3,3.2,3.7,4.5,7.1,3.6c1.6-0.4,3.1-1.2,4.6-2.1C318.3,49.2,318.7,49,319.1,48.8z M309.3,34.1
	c1,0,1.9,0,2.9,0c0.5,0,0.7-0.1,0.7-0.6c0-1.8,0-3.6-0.6-5.3c-0.4-1.3-1-2.4-2.5-2.5c-1.5-0.1-2.2,1-2.7,2.1c-0.9,1.7-1,3.6-1.3,5.4
	c-0.1,0.8,0.2,1,0.9,1C307.6,34.1,308.5,34.2,309.3,34.1z'
      />
      <path
        fill={color1}
        d='M169.5,48.8c-0.3,3.3-0.5,6.5-0.8,9.7c0,0.4-0.1,0.7-0.1,1.1c-0.1,2.3-0.1,2.3-2.4,3.1c-3,1-6.1,1.2-9.2,0.5
	c-2.2-0.5-4-1.7-5.3-3.6c-2-2.8-3-6-3.6-9.4c-1.5-8.8-1-17.5,1.8-26.1c0.6-1.9,1.5-3.7,2.8-5.3c4.4-5.4,12.3-4.8,15.7,1.3
	c2,3.6,2.7,7.6,3,11.7c0.2,2.8,0.2,5.6-0.1,8.4c-0.1,0.7-0.3,0.9-1,0.9c-4.4,0-8.8,0-13.2,0c-0.9,0-1.2,0.2-1.2,1.2
	c0.1,1.9,0.2,3.7,0.8,5.5c1.2,3.3,3.8,4.6,7.2,3.7c1.6-0.5,3.1-1.3,4.6-2.1C168.7,49.2,169,49,169.5,48.8z M159.6,34.1c1,0,2,0,3,0
	c0.4,0,0.7-0.1,0.7-0.6c0-1.9,0-3.8-0.7-5.7c-0.4-1.1-1.1-2.1-2.4-2.2c-1.3-0.1-2.1,0.8-2.7,1.9c-1,1.9-1.2,3.9-1.5,6
	c-0.1,0.5,0.2,0.6,0.6,0.6C157.7,34.1,158.6,34.1,159.6,34.1z'
      />
      <path
        fill={secondaryColor2}
        d='M354.4,4.3c0,3.9,0,7.7,0,11.6c0,0.8,0.2,1.1,1,1.1c1-0.1,2.1,0,3.1,0c0.6,0,0.8,0.1,0.8,0.8
	c-0.1,3.6-0.1,7.3-0.1,10.9c0,0.6-0.2,0.8-0.8,0.8c-1.2,0-2.3,0-3.5,0c-0.6,0-0.7,0.2-0.8,0.7c-0.1,5.3-0.4,10.7-0.2,16
	c0.1,4,1.2,4.7,5.3,3.1c-0.2,2.1-0.3,4.1-0.5,6.2c-0.2,1.9-0.3,3.7-0.5,5.6c0,0.4,0,0.9-0.5,1.2c-2,0.9-4,1.6-6.2,1.3
	c-3.3-0.4-5.1-2.3-5.9-6c-0.8-4-0.5-8-0.4-12c0.1-5.1,0.3-10.1,0.5-15.2c0-0.8-0.2-0.9-0.9-0.9c-2.5,0-2.4,0-2.4-2.5
	c0.1-3,0.1-6,0.1-9c0-0.8,0.2-1,1-1c2.7,0,2.7,0,2.8-2.7c0-1.8,0.1-3.7,0.2-5.5c0-0.5,0-0.8,0.6-1C349.5,6.7,352,5.5,354.4,4.3z'
      />
      <path
        fill={secondaryColor2}
        d='M335.4,4.5c0,3.7,0.1,7.4,0,11.1c0,1.2,0.3,1.5,1.4,1.4c1.1-0.1,2.7-0.5,3.3,0.2c0.6,0.6,0.2,2.1,0.2,3.2
	c0,2.7-0.1,5.3-0.1,8c0,0.8-0.3,1-1,1c-1-0.1-2.1,0-3.1,0c-0.6,0-0.9,0.1-0.9,0.8c0,5.5-0.5,11.1-0.1,16.6c0,0.2,0,0.3,0,0.5
	c0.4,2.8,1.7,3.6,4.3,2.3c0.3-0.1,0.5-0.4,0.9-0.3c-0.4,4-0.7,8.1-1.1,12.1c0,0.3,0,0.6-0.4,0.8c-2.1,1-4.2,1.6-6.6,1.3
	c-2.4-0.3-3.9-1.6-4.8-3.7c-1.2-2.9-1.3-6-1.2-9c0.2-6.6,0.4-13.2,0.6-19.8c0-1.2-0.2-1.6-1.4-1.5c-2,0.1-2,0-1.9-1.9
	c0.1-3.2,0.2-6.4,0.2-9.6c0-0.8,0.2-1,1-1c2.6,0,2.7,0,2.8-2.6c0.1-1.8,0.1-3.6,0.1-5.4c0-0.7,0.2-1.1,0.9-1.4
	c2.1-0.9,4.2-1.9,6.3-2.8C334.9,4.6,335.1,4.4,335.4,4.5z'
      />
      <path
        fill={color1}
        d='M145,49.2c-0.2,2.3-0.4,4.5-0.6,6.6c-0.1,1.7-0.3,3.5-0.4,5.2c0,0.7-0.3,1.1-0.9,1.3c-2.1,0.8-4.1,1.4-6.4,1
	c-2.1-0.3-3.5-1.5-4.5-3.4c-1-2.2-1.3-4.5-1.4-6.9c-0.1-7.5,0.4-14.9,0.6-22.3c0-1.3-0.3-1.5-1.5-1.5c-1.9,0.1-1.8,0-1.8-1.9
	c0.1-3.1,0.1-6.3,0.1-9.4c0-0.9,0.3-1.1,1.2-1.1c2.6,0,2.6,0,2.6-2.5c0.1-1.9,0.1-3.8,0.2-5.7c0-0.4,0-0.8,0.5-1
	c2.5-1,5.1-2.1,7.5-3.4c0,1.7,0,3.2,0,4.7c0,2.4,0,4.7-0.1,7.1c0,0.7,0.2,0.9,0.9,0.9c1.1-0.1,2.2,0,3.2,0c0.7,0,0.9,0.2,0.9,0.9
	c-0.1,3.6-0.1,7.2-0.2,10.8c0,0.7-0.3,0.9-0.9,0.8c-1.1,0-2.2,0-3.2,0c-0.7,0-0.9,0.1-0.9,0.9c-0.1,5.2-0.3,10.4-0.2,15.7
	c0,0.8,0.1,1.7,0.4,2.5c0.5,1.7,1.6,2.2,3.2,1.6C143.8,49.8,144.4,49.5,145,49.2z'
      />
      <path
        fill={secondaryColor2}
        d='M453.4,29.1c-1.4-1-3-1.9-4.9-1.9c-2.4,0-3.6,1.5-3.1,3.9c0.2,0.9,0.7,1.7,1.2,2.5c1.3,2,2.7,3.9,3.9,5.9
	c3,5.2,3.5,10.9,1.9,16.6c-1.7,6.1-6.3,8.6-12.6,7c-4.2-1.1-4.2-1.1-4.3-5.4c-0.1-3.1-0.3-6.2-0.5-9.5c0.7,0.5,1.2,0.9,1.7,1.3
	c1,0.7,2,1.4,3.2,1.8c1.3,0.4,2.6,0.6,3.6-0.5c1.1-1.1,1-2.5,0.6-3.8c-0.2-0.6-0.6-1.2-0.9-1.8c-1.2-2-2.5-3.9-3.7-5.9
	c-3.4-5.7-3.8-11.8-1.9-18.1c1.5-4.8,5-6.7,10.3-5.8c1.6,0.3,3.1,0.7,4.6,1.2c0.4,0.1,0.8,0.3,0.8,0.9
	C453.4,21.3,453.4,25.2,453.4,29.1z'
      />
      <path
        fill={secondaryColor2}
        d='M275.2,48.2c1.2,0.8,2.3,1.6,3.4,2.2c0.8,0.5,1.6,0.9,2.6,1c2.5,0.2,3.9-1.3,3.5-3.7c-0.2-0.9-0.6-1.7-1.1-2.5
	c-1.3-2-2.6-4-3.8-6.1c-3.3-5.6-3.7-11.6-1.8-17.7c1.5-5,4.9-6.9,10.3-6c1.6,0.2,3.2,0.7,4.8,1.2c0.4,0.1,0.7,0.2,0.7,0.8
	c0,3.9,0,7.9,0,11.9c-0.2,0-0.5-0.4-0.8-0.6c-1.2-0.7-2.4-1.3-3.8-1.3c-2.8-0.1-4.1,1.6-3.2,4.3c0.2,0.8,0.7,1.4,1.1,2.1
	c1.3,1.9,2.7,3.8,3.9,5.9c3,5.4,3.4,11.1,1.7,16.9c-1.7,5.9-6.3,8.2-12.5,6.7c-1.3-0.3-2.8-0.6-3.8-1.4c-1.1-0.9-0.4-2.4-0.5-3.7
	C275.4,54.7,275.3,51.5,275.2,48.2z'
      />
      <path
        fill={secondaryColor2}
        d='M59.2,71.5c-2,0.2-3.3-0.3-3.6-2.5c0-0.4-0.2-0.6-0.7-0.7c-0.4,0-0.7-0.1-1-0.3c-1.2-0.5-2.2-0.7-3.2,0.4
	c-0.3,0.4-0.9,0.5-1.5,0.4c-1.7-0.4-3-1.4-3.9-2.9c-0.5-0.7-0.1-1.5,0.5-2.1c0.6-0.6,0.5-1.1,0-1.6c-0.2-0.2-0.3-0.4-0.4-0.6
	c-0.4-1.1-1-1.5-2.2-1.5c-1.4,0-2.1-0.9-2.3-2.3c-0.1-0.5-0.1-1-0.1-1.5c-0.1-1.3,0.4-2.2,1.7-2.3c1.2-0.1,1.7-0.7,1.9-1.8
	c0-0.3,0.2-0.6,0.3-0.9c0.3-0.5,0.2-0.9-0.3-1.3c-1.5-1.4-1.4-2.2,0-3.7c2-2.1,2.3-2.1,4.8-0.9c0.4,0.2,0.6,0.2,1,0
	c0.4-0.2,0.8-0.4,1.2-0.6c1.1-0.3,1.8-0.8,1.7-2.2c-0.1-0.9,0.7-1.5,1.6-1.6c1.1-0.2,2.2-0.3,3.2-0.2c1.2,0.1,1.9,0.8,2.1,2
	c0.1,0.9,0.5,1.3,1.4,1.4c0.3,0,0.7,0.2,1,0.3c0.9,0.5,1.5,0.2,2.2-0.4c1.1-0.9,2.1-0.9,3.3-0.1c0.3,0.2,0.6,0.4,0.9,0.6
	c2.2,1.7,2.2,2,0.8,4.3c-0.2,0.4-0.3,0.6,0,0.9c0.2,0.2,0.4,0.5,0.5,0.8c0.4,1,1,1.4,2.1,1.4c1.8,0,2.5,0.9,2.7,2.7
	c0,0.4,0.1,0.9,0.1,1.3c0.1,1.3-0.7,2.1-2.2,2.3c-1,0.1-1.3,0.5-1.7,1.4c-0.5,1.2-0.4,2,0.7,2.9c0.9,0.8,0.8,1.8,0,2.7
	c-0.4,0.4-0.8,0.8-1.2,1.2c-1.1,1.1-2.2,1.4-3.5,0.5c-0.9-0.6-1.6-0.7-2.5-0.1c-0.4,0.3-0.9,0.4-1.4,0.6c-0.3,0.1-0.5,0.3-0.5,0.7
	C62.4,71.3,62.3,71.4,59.2,71.5z M57.6,50.7c-2.2,0.1-4.2,0.9-5.3,3.1c-1,1.9-0.6,3.7,0.7,5.4c1.8,2.3,5.1,3.1,7.8,1.9
	c3.6-1.6,4.4-5.9,1.4-8.6C60.8,51.3,59.3,50.8,57.6,50.7z'
      />
      <path
        fill={color1}
        d='M256.9,0.8c1.2,0,2.3,0.1,3.5,0c0.7-0.1,1,0.1,0.9,0.9c-0.2,5.7-0.4,11.4-0.6,17.1c-0.1,4-0.2,8-0.3,11.9
	c-0.2,10.2-0.6,20.4-0.5,30.6c0,0.9-0.2,1.2-1.1,1.1c-2.4-0.1-4.7,0-7.1,0c-0.5,0-0.9,0-0.8-0.8c0.5-10.3,0.6-20.7,0.9-31
	c0.3-9.5,0.4-19,0.5-28.4c0-1.2,0.3-1.5,1.5-1.3C254.8,0.9,255.9,0.8,256.9,0.8C256.9,0.8,256.9,0.8,256.9,0.8z'
      />
      <path
        fill={color1}
        d='M190.9,30.4c-3.2-1-4.7-0.2-6,3c-0.9,2.2-1.3,4.6-1.4,7c-0.4,6.9-0.6,13.8-0.3,20.7c0,1-0.2,1.4-1.3,1.3
	c-2.2-0.1-4.3-0.1-6.5,0c-0.9,0-1.1-0.3-1.1-1.1c0.2-5.4,0.4-10.7,0.6-16.1c0.3-9.1,0.4-18.2,0.3-27.2c0-1.7,0-1.7,1.7-1.5
	c1.8,0.1,3.7,0.1,5.5,0c0.9-0.1,1.2,0.1,1.1,1.1c-0.1,1.1,0,2.3,0,3.5c0.4-0.3,0.5-0.7,0.7-1.1c0.5-1.1,1.1-2.2,2-3.1
	c0.8-0.8,1.7-1.4,2.8-1.5c1.4-0.1,1.9,0.3,1.9,1.8C190.9,21.4,190.9,25.9,190.9,30.4z'
      />
      <path
        fill={secondaryColor2}
        d='M371.2,39.3c0,7.4,0,14.7,0,22.1c0,0.8-0.2,1-1,1c-2.5-0.1-5,0-7.4,0c-0.7,0-1-0.1-0.9-0.9
	c0.9-14.6,1-29.2,1.1-43.8c0-1.2,0.3-1.5,1.5-1.4c2.2,0.2,4.4,0.2,6.6,0c0.9-0.1,1,0.2,1,1.1C371.7,24.7,371.4,32,371.2,39.3z'
      />
      <path
        fill={color1}
        d='M93.7,39.3c0,7.3,0,14.7,0,22c0,0.9-0.2,1.1-1.1,1.1c-2.4-0.1-4.9,0-7.3,0c-0.7,0-1-0.2-0.9-0.9
	c0.9-14.7,1-29.5,1.1-44.2c0-0.8,0.2-1,1-1c2.4,0.2,4.7,0.2,7.1,0c0.7,0,1,0.1,0.9,0.9C94.2,24.5,93.9,31.9,93.7,39.3z'
      />
      <path
        fill={color1}
        d='M85.9,5c0-1.5,0-2.9,0-4.4c0-0.7,0.2-1,1-0.9c2.3,0,4.6,0,6.9,0c0.7,0,0.9,0.2,0.9,0.9
	c-0.1,3.1-0.2,6.1-0.2,9.2c0,0.6-0.2,0.8-0.8,0.8c-2.3,0-4.6,0-6.9,0c-0.7,0-0.9-0.2-0.9-0.9c0-1.5,0-3.1,0-4.7
	C85.8,5,85.8,5,85.9,5z'
      />
      <path
        fill={color1}
        d='M235.9,53c0,0,0.1-0.1,0.1-0.1c0,0,0,0.1,0.1,0.1C236,53,236,53,235.9,53C235.9,53.1,235.9,53,235.9,53z'
      />
    </svg>
  );
};

export default InternalSettings;
