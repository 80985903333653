import React, { useState } from 'react';

import pallete from '../../../../theme/pallete';

const CookingKids = ({
  color = pallete.secondary1,
  height = 25,
  width = 132,
  secondaryColor = pallete.logoSecondary,
  selected,
  disabled,
  nonIA = false,
}) => {
  const [hovered, setHovered] = useState(false);
  let color1 = color;
  let secondaryColor2 = secondaryColor;
  if (selected || hovered) {
    color1 = pallete.backgroundWhite;
  } else if (disabled) {
    color1 = '#B2B2B2';
    secondaryColor2 = '#777777';
  }
  return (
    <svg
      version='1.1'
      id='Ebene_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={width}
      height={height}
      viewBox='0 0 453.8 85.2'
      // style='enable-background:new 0 0 453.8 85.2;'
      xmlSpace='preserve'
      onMouseEnter={() => !nonIA && setHovered(true)}
      onMouseLeave={() => !nonIA && setHovered(false)}
    >
      <path
        fill={color1}
        d='M52.7-0.6c1.5,0.3,3,0.6,4.5,1c4.1,1.1,7.8,2.9,10.2,6.6c0.4,0.6,0.9,0.5,1.5,0.6c3.9,0.2,7.8,0.9,11.4,2.4
	c2,0.8,3.9,1.9,5.5,3.6c2.6,2.7,3.2,6.1,1.5,9.5c-1.6,3.4-4.5,5.8-7.4,8c-1.6,1.2-1.6,1.2-1.1,3c0.4,1.5,0.9,3.1,1.2,4.6
	c0.4,1.5-0.5,2.4-2,2c-12.1-3.4-24-2-35.7,1.8c-5.8,1.9-11.3,4.6-16.6,7.7c-1.8,1.1-2.6,0.7-3-1.3c-0.5-2-1-4-1.4-6
	c-0.2-1.1-0.6-1.5-1.9-1.2c-6,1.6-12.6-0.9-16.2-5.9C1.9,33.9,1,32,0.7,29.8c0-0.3,0.1-0.6-0.3-0.7c0-0.7,0-1.4,0-2.2
	c0.3-0.1,0.2-0.4,0.3-0.6c0.5-2.8,1.8-5.2,3.9-7.2c2.4-2.4,5.3-3.9,8.5-5c4.9-1.8,10-2.6,15.2-2.9c0.9-0.1,1.5-0.3,1.8-1.3
	c0.5-1.5,1.5-2.8,2.6-3.9C35.5,3,39,1.4,42.9,0.4c1.6-0.4,3.2-0.6,4.7-1C49.3-0.6,51-0.6,52.7-0.6z M76.5,37c-0.4-1.6-0.8-3-1.2-4.5
	c-0.4-1.7-0.3-1.9,1.1-2.9c1.2-0.8,2.3-1.6,3.4-2.4c1.9-1.5,3.6-3.1,4.7-5.2c1.3-2.5,1-4.7-1.1-6.7c-1.2-1.1-2.6-1.9-4-2.5
	c-3.8-1.6-7.8-2.3-11.9-2.4c-0.8,0-1.6-0.1-2-1c-1.1-2.2-2.9-3.6-5-4.7c-4.2-2.2-8.7-2.7-13.4-2.2C43.2,3,39.7,4.2,36.5,6.5
	C34.6,7.9,33,9.6,32.4,12c-0.5,1.7-0.7,1.9-2.5,2c-3.8,0.2-7.6,0.6-11.3,1.5c-4.3,1-8.4,2.4-11.7,5.4c-2.5,2.3-3.9,5.2-3.3,8.6
	c0.8,4.6,5,8.6,9.7,9.4c2.6,0.4,5.1,0.6,7.4-1.3c0.6-0.5,1.5-0.2,2,0.5c0.3,0.5,0.5,1,0.7,1.5c0.5,2,1.1,4,1.5,6
	c0.2,1,0.5,1.2,1.4,0.6c4.4-2.5,9-4.7,13.8-6.3c8.6-3,17.4-4.6,26.6-4.2C69.9,35.9,73.1,36.3,76.5,37z'
      />
      <path
        fill={color1}
        d='M298.7,74.2c1.7,1.6,3.5,2.8,5.6,3.5c5.1,1.7,10.8,0.3,12-7.1c0.2-1.2,0.2-2.4,0.3-3.6c0.1-0.8-0.2-1.1-1-1.1
	c-1.8,0.1-3.5,0-5.3,0c-7.9,0-13.5-3.8-16.1-11.3c-2.4-6.9-2.3-13.9,0.8-20.6c2.9-6.1,7.8-9.3,14.7-9.3c4.6,0,9.1,0,13.7,0
	c0.8,0,1.1,0.2,1.1,1c0,13.7,0,27.3,0,41c0,4.3-0.8,8.4-3.5,11.8c-3.6,4.6-8.5,5.8-14,5.4c-3.5-0.3-6.6-1.8-9.3-4
	c-0.6-0.5-0.9-1-0.5-1.8C297.7,76.9,298.1,75.6,298.7,74.2z M316.5,45.4c0-4.6,0-9.2,0-13.9c0-0.8-0.2-1-1-1c-2,0.1-4.1,0-6.1,0
	c-2.3,0-4.2,1-5.6,2.8c-1,1.2-1.6,2.6-2,4c-1,3.5-1.1,7.1-0.9,10.8c0.2,2.5,0.5,4.9,1.6,7.2c1.5,3.2,3.9,5,7.5,4.9
	c1.7,0,3.4-0.1,5.1,0c1,0,1.3-0.2,1.3-1.2C316.5,54.4,316.5,49.9,316.5,45.4z'
      />
      <path
        fill={secondaryColor2}
        d='M418.9,38.8c0,9.2,0,18.3,0,27.5c0,1-0.2,1.4-1.3,1.3c-4.2-0.1-8.5,0-12.7,0c-8.2,0-13.7-3.8-16.2-11.5
	c-2.4-7.1-2.3-14.3,0.6-21.3c2.7-6.6,8-10,15.1-10c1.8,0,3.7,0,5.5,0c0.9,0,1.2-0.2,1.2-1.2c-0.1-4.2,0-8.4,0-12.7
	c0-0.8,0.2-1.1,1-1.1c1.9,0.1,3.8,0.1,5.6,0c0.9,0,1.2,0.2,1.2,1.2c0,8.4,0,16.7,0,25.1C418.9,37,418.9,37.9,418.9,38.8z M411,46.1
	c0-4.9,0-9.8,0-14.7c0-0.7-0.2-1-0.9-0.9c-2.1,0.1-4.2,0-6.4,0c-2.1,0.1-3.9,0.9-5.2,2.6c-0.9,1.2-1.6,2.5-2,3.9
	c-1.4,4.7-1.4,9.6-0.8,14.4c0.3,2.3,0.9,4.5,2,6.6c1.4,2.5,3.6,3.8,6.5,3.8c1.9,0,3.8-0.1,5.6,0c0.9,0,1.1-0.3,1.1-1.1
	C411,55.9,411,51,411,46.1z'
      />
      <path
        fill={secondaryColor2}
        d='M367.9,67.6c-2.8,0-5.6,0-8.3,0c-0.7,0-0.8-0.5-1-0.9c-2.6-5.6-5.2-11.3-7.7-16.9c-0.4-0.8-0.8-1.2-1.7-1.1
	c-1.2,0.1-2.3,0.1-3.5,0c-1.1-0.1-1.2,0.3-1.2,1.3c0,5.5,0,11,0,16.5c0,1-0.3,1.3-1.3,1.2c-1.8-0.1-3.7-0.1-5.5,0
	c-0.9,0-1.1-0.2-1.1-1.1c0-11.7,0-23.4,0-35c0-6.8,0-13.6,0-20.4c0-0.9,0.2-1.2,1.1-1.1c1.9,0.1,3.8,0.1,5.6,0c1,0,1.2,0.3,1.2,1.2
	c0,10.1,0,20.2,0,30.4c0,1.2,0.3,1.5,1.4,1.4c1.5-0.2,3.2,0.5,4.3-0.3c1-0.8,1.4-2.4,2-3.7c2.1-4.4,4.2-8.8,6.2-13.2
	c0.3-0.7,0.7-1,1.5-1c2.1,0.1,4.2,0.1,6.2,0c1,0,1,0.2,0.7,1c-2.9,6.1-5.8,12.3-8.8,18.4c-0.4,0.9-0.4,1.5,0,2.3
	c3.2,6.8,6.3,13.6,9.4,20.3C367.7,67.1,367.7,67.2,367.9,67.6z'
      />
      <path
        fill={color1}
        d='M234.7,24.8c-1.4,2.9-2.7,5.6-3.9,8.3c-1.8,3.7-3.5,7.5-5.3,11.2c-0.4,0.7-0.4,1.3,0,2.1
	c3.1,6.7,6.2,13.4,9.3,20c0.2,0.4,0.4,0.7,0.4,1.2c-2.7,0-5.4,0-8.1,0c-0.7,0-0.8-0.5-1-0.9c-2.6-5.7-5.2-11.3-7.7-17
	c-0.4-0.8-0.8-1.1-1.7-1.1c-1.2,0.1-2.4,0.1-3.6,0c-0.9-0.1-1.1,0.3-1.1,1.1c0,5.5,0,11.1,0,16.6c0,1-0.2,1.3-1.2,1.3
	c-1.8-0.1-3.6-0.1-5.4,0c-1,0-1.3-0.2-1.3-1.2c0-7.9,0-15.7,0-23.6c0-10.6,0-21.1,0-31.7c0-1,0.2-1.3,1.2-1.3c1.8,0.1,3.6,0.1,5.4,0
	c1,0,1.3,0.2,1.3,1.3c0,10,0,19.9,0,29.9c0,0.3,0,0.6,0,1c0,0.6,0.2,0.9,0.8,0.9c1.4,0,2.9,0,4.3,0c0.8,0,0.9-0.5,1.2-1
	c2.6-5.4,5.1-10.7,7.6-16.1c0.4-0.8,0.8-1.1,1.7-1.1C229.8,24.8,232.1,24.8,234.7,24.8z'
      />
      <path
        fill={color1}
        d='M197.6,46.5c0.1,4.6-0.7,9.2-2.7,13.6c-5.2,11-17.8,11.2-24.1,5.7c-3.1-2.7-4.9-6.1-5.9-10
	c-1.9-7.5-1.8-15,1.1-22.3c2-5,5.5-8.6,10.9-10c6.9-1.7,13.8,1.2,17.3,7.4C196.9,35.7,197.7,40.9,197.6,46.5z M189.3,46.3
	c0-2.1,0-4.2-0.3-6.3c-0.4-2.7-1-5.3-2.4-7.7c-3-5.1-8.8-5.1-11.8,0c-0.7,1.1-1.1,2.3-1.5,3.5c-1.6,6.2-1.6,12.4-0.5,18.6
	c0.4,2.3,1.1,4.6,2.5,6.5c2.8,4,7.8,4,10.7,0.1c0.8-1.1,1.3-2.3,1.8-3.5C189,53.8,189.3,50.1,189.3,46.3z'
      />
      <path
        fill={color1}
        d='M158.7,47.6c0,3.4-0.7,8-2.7,12.3c-5.1,11-17.2,11.3-23.5,6.5c-3.6-2.8-5.6-6.6-6.7-10.9
	c-1.8-7.3-1.7-14.5,1-21.6c2.2-5.6,6-9.5,12.2-10.5c6.8-1.1,13,1.9,16.3,7.9C157.6,35.3,158.7,40.3,158.7,47.6z M150.3,45.3
	c0-1.4-0.1-3.8-0.5-6.1c-0.5-2.9-1.2-5.8-3.1-8.2c-2.7-3.4-7.3-3.4-10,0c-1,1.3-1.8,2.8-2.2,4.3c-1.9,6.5-1.9,13.1-0.5,19.7
	c0.5,2.5,1.4,4.9,3.2,6.8c2.7,2.8,6.9,2.6,9.4-0.3c1-1.1,1.6-2.4,2.1-3.8C150,54,150.3,50.1,150.3,45.3z'
      />
      <path
        fill={color1}
        d='M255.8,46.2c0-6.8,0-13.5,0-20.3c0-0.9,0.2-1.1,1.1-1.1c4.8,0,9.7,0,14.5,0c2,0,4,0.3,5.9,0.9
	c5.3,1.7,7.7,5.7,8.7,10.8c0.3,1.8,0.4,3.6,0.4,5.5c0,8.1,0,16.3,0,24.4c0,1-0.2,1.3-1.2,1.3c-1.8-0.1-3.6-0.1-5.4,0
	c-1.1,0.1-1.3-0.4-1.3-1.3c0-8.2,0-16.4,0-24.6c0-2-0.1-4-0.7-5.9c-1-3.4-3.3-5.2-6.9-5.2c-2.1,0-4.2,0-6.2,0c-1,0-1.2,0.3-1.2,1.2
	c0,11.5,0,23,0,34.4c0,1.2-0.2,1.6-1.5,1.5c-1.7-0.1-3.5-0.1-5.3,0c-0.9,0-1.1-0.2-1.1-1.1C255.8,59.7,255.8,53,255.8,46.2z'
      />
      <path
        fill={secondaryColor2}
        d='M451.4,34.2c-1.6-1.4-3.2-2.6-4.9-3.6c-2.7-1.5-5.6-2-8.7-1.7c-2,0.2-3.5,1.1-4.2,3.1c-0.7,2.1-0.2,4,1.2,5.7
	c0.9,1,2,1.6,3.3,2.2c3,1.3,5.9,2.5,8.9,3.8c2.5,1.1,4.7,2.6,6.3,4.9c0.4,0.6,0.6,1.2,0.6,2c0,3.7,0,7.5,0,11.2
	c0,0.7-0.2,1.2-0.5,1.8c-1.9,2.8-4.7,4.3-7.9,5c-7.4,1.6-14.1,0.3-20.1-4.5c-0.9-0.7-1.2-1.3-0.6-2.4c0.5-0.9,0.9-1.9,1.3-2.8
	c0.3-0.7,0.5-0.8,1.1-0.3c2.6,2.1,5.4,3.9,8.7,4.5c2.3,0.4,4.5,0.5,6.8-0.1c4.1-1.1,5.5-6.1,2.6-9.1c-1-1.1-2.3-1.8-3.7-2.4
	c-3-1.3-6-2.5-8.9-3.8c-3.8-1.8-6.7-4.5-7.6-8.8c-0.5-2.5-0.5-5.1,0.2-7.6c1.4-4.8,4.9-7.1,9.5-7.9c6.7-1.2,12.7,0.5,17.8,5
	c0.6,0.6,0.8,1,0.4,1.8C452.6,31.5,452.1,32.8,451.4,34.2z'
      />
      <path
        fill={color1}
        d='M120,58.2c0.7,1.6,1.3,3,2,4.4c0.3,0.7-0.2,1-0.5,1.3c-7.4,8.3-20.8,6.7-26.1-3c-1.7-3.1-2.6-6.5-3-10
	C91.8,45.4,92,39.9,94,34.5c1.9-5.2,5-9.2,10.5-10.8c6.4-1.8,11.9-0.1,16.5,4.6c0.6,0.6,0.7,1.1,0.3,1.8c-0.5,1.1-1,2.2-1.5,3.3
	c-0.3,0.7-0.5,0.7-1,0.2c-1.1-1.2-2.3-2.2-3.6-3.1c-1.4-0.9-2.9-1.5-4.6-1.6c-3.4-0.3-5.9,1.1-7.5,4c-1.4,2.5-2,5.2-2.3,8
	c-0.5,5.3-0.5,10.6,1.1,15.7c1,3.1,2.7,5.7,6.2,6.5c3,0.7,5.7,0,8.2-1.8C117.6,60.5,118.8,59.3,120,58.2z'
      />
      <path
        fill={color1}
        d='M247.7,46.5c0,6.6,0,13.2,0,19.8c0,1-0.3,1.3-1.3,1.2c-1.7-0.1-3.4-0.1-5.2,0c-1.1,0.1-1.4-0.2-1.4-1.3
	c0-10.2,0-20.4,0-30.6c0-3,0-6.1,0-9.1c0-0.8,0.2-1.1,1.1-1.1c1.8,0.1,3.7,0.1,5.5,0c0.9,0,1.2,0.2,1.2,1.2
	C247.7,33.3,247.7,39.9,247.7,46.5z'
      />
      <path
        fill={secondaryColor2}
        d='M372.6,46.6c0-6.6,0-13.2,0-19.8c0-1,0.3-1.3,1.3-1.2c1.9,0.1,3.8,0.1,5.6,0c0.7,0,0.9,0.2,0.9,1
	c0,13.4,0,26.8,0,40.3c0,0.7-0.2,0.9-0.9,0.9c-2,0-4-0.1-6,0c-0.8,0-1-0.2-1-1C372.7,59.9,372.6,53.3,372.6,46.6z'
      />
      <path
        fill={secondaryColor2}
        d='M58.2,84.2c1.1-0.4,0.8-1.1,0.5-1.8c-1.4-3.2-4-4.4-7.2-3.5c-0.6,0.2-0.9,0.4-0.5,1.1c0.9,1.6,1.8,3.1,3.4,4.1
	c-2,0.5-2.6,0.4-3.6-1.3c-1.7-2.8-3-5.8-4.2-8.8c-0.2-0.4-0.3-0.8-0.5-1.2c-0.7,0.5-1.2,1.2-1.7,1.8c-0.3,0.4-0.7,0.8-1.3,0.4
	c-0.5-0.4-0.3-0.8,0-1.3c1.6-2.1,3.4-3.8,6.2-4c0.6,0,1.4-0.1,1.4,0.8c0,0.9-0.7,0.7-1.3,0.8c-0.2,0-0.5,0.1-0.9,0.2
	c2.1,1.5,4.3,2.3,6.7,2.6c4.3,0.5,8.1-0.9,11.7-3.2c0.4-0.2,0.7-0.5,1.1-0.7c0.4-0.3,0.9-0.3,1.2,0.1c0.3,0.4,0.2,0.8-0.2,1.1
	c-0.5,0.3-0.9,0.7-1.4,0.9c-1.4,0.7-2.1,1.9-2.6,3.3c-0.8,2.3-1.7,4.6-2.8,6.8C61,83.9,60.2,84.9,58.2,84.2z'
      />
      <path
        fill={secondaryColor2}
        d='M372.4,14.5c0-1.6,0.3-3.2,1.2-4.5c1.5-2.2,4.2-2.3,5.8-0.1c1.8,2.5,1.8,6.8,0,9.2c-1.5,2.1-4.2,2.1-5.6,0
	C372.7,17.7,372.3,16.1,372.4,14.5z'
      />
      <path
        fill={color1}
        d='M239.7,14.3c0-1.5,0.3-3,1.3-4.4c1.5-2.1,4.1-2.2,5.6-0.1c1.9,2.5,1.9,7.1-0.1,9.5c-1.4,1.8-3.9,1.8-5.4,0
	C240.2,18.1,239.7,16.4,239.7,14.3z'
      />
      <path
        fill={secondaryColor2}
        d='M43.4,63.1c0,1-0.1,2-0.4,2.9c-0.2,0.6-0.1,0.8,0.6,0.7c0.2,0,0.4,0,0.6,0c0.5,0,0.9,0.2,0.9,0.7
	c0,0.5-0.3,0.8-0.8,0.8c-1.9,0.1-3.7,0.5-5.3,1.6c-0.4,0.3-0.8,0.2-1.1-0.2c-0.3-0.4-0.2-0.9,0.2-1.1c1-0.4,0.7-0.9,0.4-1.7
	c-1.1-2.5-1.1-5.1,0-7.6c0.4-0.9,1-1.8,2-1.8c1.2,0,1.7,0.9,2.1,1.8C43.2,60.5,43.4,61.8,43.4,63.1z'
      />
      <path
        fill={secondaryColor2}
        d='M71.4,61.8c0.8,0.1,1.4,0.1,2.1,0.2c0.5,0.1,0.8,0.4,0.7,0.9c-0.1,0.4-0.4,0.7-0.9,0.6
	c-1.8-0.3-3.6-0.1-5.2,0.9c-0.3,0.2-0.7,0.1-1-0.2c-0.3-0.4-0.3-0.8,0.1-1c0.6-0.3,0.5-0.7,0.3-1.2c-0.8-2.3,0.4-6.3,2.3-7.9
	c1.4-1.1,2.6-0.7,3.1,0.9c0.5,2,0,3.8-0.9,5.6C71.8,61,71.6,61.3,71.4,61.8z'
      />
      <path
        fill={secondaryColor2}
        d='M40.6,46.6c1.9,0,4.5,2.4,4.7,4.2c0,0.5-0.1,0.9-0.6,1c-0.5,0.2-0.9,0-1.2-0.4c-0.4-0.6-0.7-1.2-1.1-1.7
	c-1-1.3-1.9-1.4-3.1-0.4c-1.1,0.9-1.9,2.1-2.5,3.3c-0.3,0.6-0.7,1.1-1.5,0.8c-0.8-0.4-0.6-1.1-0.3-1.7c0.8-1.4,1.7-2.7,2.9-3.8
	C38.6,47.1,39.6,46.7,40.6,46.6z'
      />
      <path
        fill={secondaryColor2}
        d='M63.5,63.1c-0.1,1.7-1,3.1-2.2,4.5c-0.3,0.3-0.7,0.4-1.1,0.1c-0.4-0.3-0.4-0.8-0.1-1.2c0.6-0.7,1.1-1.4,1.5-2.3
	c0.3-0.7,0.6-1.5,0-2.2c-0.6-0.7-1.4-0.5-2.1-0.4c-1,0.2-1.9,0.6-2.8,1.1c-0.5,0.3-1.1,0.5-1.4-0.1c-0.4-0.7,0.2-1,0.7-1.3
	c1.3-0.7,2.6-1.2,4.1-1.4C62,59.8,63.5,61.1,63.5,63.1z'
      />
      <path
        fill={secondaryColor2}
        d='M72.1,44c0.7,0,1.3,0.4,1.8,1c0.7,0.9,1.1,2,1.4,3.1c0.1,0.5,0,1-0.6,1.1c-0.6,0.2-0.9-0.2-1.1-0.7
	c-0.2-0.8-0.4-1.5-0.9-2.2c-0.4-0.7-0.9-0.8-1.6-0.3c-0.8,0.6-1.3,1.4-1.9,2.2c-0.3,0.5-0.7,0.8-1.3,0.5c-0.6-0.4-0.5-0.9-0.2-1.4
	c0.6-1,1.3-1.9,2.3-2.6C70.7,44.3,71.3,44.1,72.1,44z'
      />
    </svg>
  );
};

export default CookingKids;
