import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => {
  return {
    flexStyled: ({
      alignCenter,
      between,
      center,
      column,
      fend,
      flex,
      fstart,
      height,
      width,
      marginBottom,
      marginTop,
      marginLeft,
      marginRight,
    }) => {
      const justifyContent = () => {
        if (center) {
          return 'center';
        }
        if (between) {
          return 'space-between';
        }
        if (fend) {
          return 'flex-end';
        }
        if (fstart) {
          return 'flex-start';
        }
        return '';
      };
      return {
        display: 'flex',
        position: 'relative',
        justifyContent: justifyContent(),
        flex,
        alignItems: alignCenter ? 'center' : 'flex-start',
        flexDirection: column ? 'column' : 'row',
        height,
        width,
        marginBottom,
        marginTop,
        marginLeft,
        marginRight,
      };
    },
  };
});

export default useStyles;
