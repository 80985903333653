import { makeStyles } from '@material-ui/styles';

import theme from '../../../theme';
import pallete from '../../../theme/pallete';

const useStyles = makeStyles(() => ({
  relativeContainer: {
    position: 'relative',
    maxWidth: '150px',
  },
  drodownContainer: {
    position: 'absolute',
    top: '50%',
    marginTop: (props) => (props.marginTop ? props.marginTop : '1.5rem'),
    right: '30%',
    width: 'auto',
    minWidth: (props) => (props.wide ? '120px' : 'auto'),
    height: 'auto',
    color: 'white',
    zIndex: 1300,
    displey: 'flex',
    flexDirection: 'column',
    // border: '5px solid black',
    background: 'white',
    border: '1px solid rgba(39, 51, 57, 0.2)',
    padding: 3,
  },
  toolTipArrow: {
    position: 'absolute',
    bottom: '100%',
    right: '30%',
    opacity: 1,
    marginTop: '-0.3rem',
    borderLeft: '9px solid white',
    transform: 'rotate(-90deg)',
    borderTop: '9px solid transparent',
    borderRight: '9px solid transparent',
    borderBottom: '9px solid transparent',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexBasis: '100%',
    overflow: 'hidden',
  },
  listItemCompact: {
    padding: 3,
  },
  listItem: {
    padding: '3px 5px',
    '& *': {
      color: pallete.secondary1,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: pallete.secondary1,
      '& *': {
        color: pallete.primary,
        backgroundColor: pallete.secondary1,
      },
    },
  },
  textItem: {
    '&>p': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: (props) =>
        props.textSize ? props.textSize : theme.fontSizes.sm,
    },
  },
  textItemCompact: {
    padding: 3,
    margin: 3,
  },
}));

export default useStyles;
