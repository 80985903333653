import React from 'react';

import pallete from '../../pallete';

const Export = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <g>
          <path
            {...st0}
            d='M24.4,28.1c0,0.7-0.5,1.2-1.2,1.2h-3.8H6.3c-0.3,0-0.6-0.1-0.8-0.4c-0.2-0.2-0.3-0.5-0.3-0.8V3.9
			c0-0.7,0.5-1.2,1.2-1.2h12.2l5.9,5.9v6.5'
          />
          <path
            {...st0}
            d='M24.4,8.6h-4.7c-0.7,0-1.2-0.5-1.2-1.2V2.7L24.4,8.6z'
          />
        </g>
        <g>
          <line {...st1} x1='18.5' y1='21.8' x2='26.7' y2='21.8' />
          <polyline {...st1} points='23.3,18.2 26.8,21.7 23.2,25.3 		' />
        </g>
      </g>
    </svg>
  );
};

export default Export;
