import React from 'react';

import pallete from '../../pallete';

const SpaceManagment = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <g>
          <ellipse {...st0} cx='12.5' cy='4' rx='11.4' ry='2.7' />
          <path
            {...st1}
            d='M1.1,17.5c0,1.5,5.1,2.7,11.4,2.7c2.5,0,4.7-0.2,6.6-0.5'
          />
          <path {...st1} d='M23.9,17.5c0,0.4-0.3,0.7-0.8,1' />
          <path
            {...st1}
            d='M23.9,10.8c0,1.5-5.1,2.7-11.4,2.7S1.1,12.2,1.1,10.8'
          />
          <path
            {...st0}
            d='M19,26.2C19,26.2,19,26.2,19,26.2c-1.8,0.3-4.1,0.5-6.5,0.5c-6.3,0-11.4-1.2-11.4-2.7'
          />
          <line {...st0} x1='1.1' y1='4' x2='1.1' y2='24' />
          <polyline {...st0} points='23.9,18.8 23.9,17.5 23.9,10.8 23.9,4 		' />
        </g>
        <g>
          <circle {...st1} cx='23.8' cy='24' r='2.1' />
          <path
            {...st1}
            d='M16.7,24.6v-1.2c0-0.7,0.6-1.3,1.3-1.3h0c1.3,0,1.8-0.9,1.2-2l0,0c-0.4-0.6-0.1-1.5,0.5-1.8l1.2-0.7
			c0.6-0.3,1.3-0.1,1.6,0.4l0.1,0.1c0.6,1.1,1.7,1.1,2.3,0l0.1-0.1c0.3-0.6,1.1-0.8,1.6-0.4l1.2,0.7c0.6,0.4,0.9,1.2,0.5,1.8l0,0
			c-0.6,1.1-0.1,2,1.2,2h0c0.7,0,1.3,0.6,1.3,1.3v1.2c0,0.7-0.6,1.3-1.3,1.3h0c-1.3,0-1.8,0.9-1.2,2l0,0c0.4,0.6,0.1,1.5-0.5,1.8
			l-1.2,0.7c-0.6,0.3-1.3,0.1-1.6-0.4L25,30c-0.6-1.1-1.7-1.1-2.3,0l-0.1,0.1c-0.3,0.6-1.1,0.8-1.6,0.4l-1.2-0.7
			c-0.6-0.4-0.9-1.2-0.5-1.8l0,0c0.6-1.1,0.1-2-1.2-2h0C17.3,26,16.7,25.3,16.7,24.6z'
          />
        </g>
      </g>
    </svg>
  );
};

export default SpaceManagment;
