import LOCALES from '../locales';

export default {
  [LOCALES.ENGLISH]: {
    default: '',
    unauth_logo_title: 'the “all about nutrition” software',
    notifications: 'Notifications',
    univ_view_details: 'View Details',
    univ_expand: 'Expand',
    univ_sub_plan: 'Subscription plan',
    univ_sub_get_sub: 'Get Subscription',
    univ_contact_admin: 'contact admin',
    univ_expiry: 'Expiry',
    univ_yes: 'Yes',
    univ_no: 'No',
    univ_continue: 'Continue',
    univ_cancel: 'Cancel',
    univ_export: 'Export',
    univ_merge: 'Merge',
    univ_copy: 'Copy',
    univ_save: 'Save',
    univ_email: 'Email',
    univ_phone_number: 'Phone number',
    univ_delete: 'Delete',
    univ_password: 'Password',
    univ_back: 'Back',
    univ_send: 'Send',
    univ_tags: 'Tags',
    univ_add_own: 'Add own',
    univ_create_tags: 'Create Tag',
    univ_create_tag_empty_error: 'tag cannot be empty',
    univ_create_tag_exists_error: 'such tag already exists',
    univ_share: 'Send to community',
    univ_report: 'Send Report',
    univ_edit: 'Edit',
    univ_multi_edit: 'Multi edit',
    roof_search_empty: "type in what you're looking for",
    noti_name: 'Notifications',
    invi_refuse: 'Refuse',
    invi_confirm: 'Confirm',
    mais_more_results: 'more results',
    'mais_show all': 'Show All',
    mais_all_found: 'more found results',
    regi_name: 'Registration',
    regi_step: 'Step [x] of [y]',
    regi_step1_title: 'Personal Information',
    regi_step1_noti1: 'Use your personal data. Its not about your company.',
    regi_step1_first_name: 'First Name',
    regi_step1_last_name: 'Last Name',
    regi_step1_skip_with: 'or skip with',
    regi_step1_have_an_account: 'Already have an account?',
    regi_step1_cancel_registration: 'Leave registration?<br>Login to continue',
    regi_step2_title: 'Define Space',
    regi_step2_item1_main: 'I would like to manage my own database.',
    regi_step2_item1_sub1: 'I am  the owner of a restaurant or a bakery.',
    regi_step2_item1_sub2: 'I want to use nutrimero as my recipe book.',
    regi_step2_item2_main: 'I want access to an existing database.',
    regi_step2_item2_sub1: 'I am an employee or was invited by a colleague.',
    regi_step2_item2_sub2: "In the moment I don't want to use an own recipe database.",
    regi_step2_item3_main: 'I need no database or access in the moment.',
    regi_step2_item3_sub1: 'Only creating the account for future use.',
    regi_step2_create_database: 'Create Database (own Space)',
    regi_step2_create_database_button: 'Create Database',
    regi_step2_tooltip: 'Email address or ID of account to access the Database. Also you can search our user list.',
    regi_step2_email_or_ID: 'Email or user ID',
    regi_step3_title: 'Account Validation',
    regi_step3_how_to_get_otp:
      'We would now have to send you an OTP <br> (one-time-password) to confirm your identity.<br> Please choose the way you want to get it.',
    regi_step3_validate_otp_1:
      "You received the OTP by email. Please also check your junk mail folder if it hasn't arrived yet.",
    regi_step3_validate_otp_2: 'Please enter your password in the following field.',
    regi_step3_otp_to_mail: 'Send OTP to email address',
    regi_step3_otp_to_mobile: 'Send OTP via SMS to mobile phone',
    regi_step3_mobile_number: 'Mobile phone number - only for OTP',
    regi_step3_you_recieved_otp_by_mail:
      "You received the OTP by email. Please also check your junk mail folder if it hasn't arrived yet.",
    regi_step3_you_recieved_otp_by_phone: 'You received the OTP by phone (SMS).',
    regi_step3_enter_password: 'Please enter your password in the following field.',
    regi_step3_otp_number: 'OTP Number',
    regi_step3_accept_terms: 'I accept the terms of usage',
    regi_step3_register_for_free: 'Register for free',
    regi_step3_send_otp_again: 'Send the OTP again',
    regi_step3_send_otp_by_phone: 'Send the OTP by phone',
    regi_step3_send_otp_by_email: 'Send the OTP by email',
    chose_space: 'Choose Working Space',
    chose_space_search_placeholder: 'search spaces I belong to',
    сonfirmation_code_sent_again: 'Confirmation code sent again',
    spac_create_title: 'Create your own Database (Space)',
    spac_create_fill_fields: 'In order to create your own database just fill the fields. ',
    spac_create_general_information: 'General Information',
    spac_database_name: 'Database (Space) Name',
    spac_database_language: 'Select Language',
    spac_database_country: 'Country',
    spac_which_main_unit: 'What main unit for recipes would you like to use?',
    spac_main_unit_choose_prefered: 'Depending on your requirements please choose which unit you prefer. ',
    spac_main_unit_not_changeable: "You can't change these settings later!",
    spac_main_unit_more_information: "You don't know what to choose? Get more information",
    spac_main_unit_kg: 'Kilograms (kg)',
    spac_main_unit_g: 'Grams (g)',
    spac_main_unit_lb: 'Pounds (lb)',
    spac_main_unit_oz: 'Ounces (oz.)',
    reac_title: 'Request access to database',
    reac_noti1: 'Email address or ID of account to access the space. Also you can search our user list.',
    reac_noti2: 'When the space  owner accepts your request, you will receive an email notification.',
    reac_tooltip1:
      'The ID or email you have gotten from the owner of the space (database). Also, you can search via typing.',
    logi_title: 'Login',
    logi_mail_address: 'Your Mail Address',
    logi_button_sign_in: 'Sign In',
    logi_keep_signed_in: 'Keep me signed in',
    logi_or: 'or skip with',
    logi_forgot_password: 'Forgot your password?',
    logi_no_account: "Don't have an account?",
    logi_create_space: 'Create own space (database)',
    logi_request_access: 'Request access',
    logi_which_space: "You're logged in.<br>On which space you want to work today?",
    logi_my_space: 'my own space (database)',
    logi_my_space_title: 'My own space (create own database)',
    logi_other_space: 'other space (database)',
    logi_logout: 'Logout',
    repa_title: 'Password Restore',
    repa_noti1: 'Enter your email address and you will receive a message about password recovery.',
    repa_noti2: 'Your account is created. Please login next time to continue',
    repa_noti3: 'User details edited. Please continue with registration',
    repa_senq_request: 'Send Request',
    repa_enter_new_password: 'Enter your new password.',
    repa_new_password: 'New Password',
    repa_repeat_new_password: 'Repeat New Password',
    repa_save_new_password: 'Save New Password',
    unit_teaspoon: 'Teaspoon',
    navi_root_1_home: 'Home',
    navi_root_1_modify_dashboard: 'Modify Dashboard',
    navi_root_2_jobs: 'Jobs',
    navi_root_2_1_incomplete_data: 'incomplete Data',
    navi_root_2_2_tasks: 'Tasks',
    navi_root_3_messages: 'Messages',
    navi_root_4_tools: 'Tools',
    navi_root_4_1_nutrimero: 'nutrimero',
    navi_root_4_2_declaratio: 'declaratio',
    navi_root_4_3_foodscale: 'foodscale',
    navi_root_5_my_profile: 'my Profile',
    navi_root_6_my_space: 'my Space',
    navi_root_6_1_create_space: '(Create own space)',
    navi_root_6_database_settings: 'Database settings',
    navi_root_6_billing_address: 'Billing Address',
    navi_root_6_subscriptions: 'Subscriptions',
    navi_root_6_invited_users: 'Invited Users',
    navi_root_6_notifications: 'Notifications',
    navi_root_6_tags: 'Tags',
    navi_root_7_external_spaces: 'external Spaces',
    navi_root_7_1_spaces_i_belong_to: 'spaces I belong to',
    navi_root_7_2_notifications: 'Notifications',
    navi_root_8_print_export: 'Print / Exports',
    navi_root_8_1_print_data: 'print Data',
    navi_root_8_2_export_data: 'export Data',
    navi_root_9_settings: 'Settings',
    navi_nutr_1_home: 'Home',
    navi_nutr_2_jobs: 'Jobs',
    navi_nutr_2_1_incomplete_data: 'incomplete Data',
    navi_nutr_2_2_tasks: 'Tasks',
    navi_nutr_2_2_task: 'Task',
    navi_nutr_3_ingredients: 'Ingredients',
    navi_nutr_3_1_ingredients_details: 'Ingredients Details',
    navi_nutr_3_1_ingredients_overview: 'Ingredients Overview',
    navi_nutr_3_2_ingredient_groups: 'Ingredient Groups',
    navi_nutr_3_3_bought_item_overview: 'Bought Items Overview',
    navi_nutr_4_recipes: 'Recipes',
    navi_nutr_4_1_recipes_overview: 'Recipes Overview',
    navi_nutr_4_2_recipe_groups: 'Recipe Groups',
    navi_nutr_4_3_recipe_versions: 'Recipe Version History',
    navi_nutr_5_products: 'Products',
    navi_nutr_5_products_overview: 'Products Overview',
    navi_nutr_5_product_groups: 'Product Groups',
    navi_nutr_6_wizards: 'Wizards',
    navi_nutr_6_1_import_recipes: 'Import Recipes',
    navi_nutr_6_2_import_ingredients: 'Import Ingredients',
    navi_nutr_6_3_replace_ingredients: 'Replace Ingredients',
    navi_nutr_7_print_export: 'Print / Exports',
    navi_nutr_7_1_print_data: 'print Data',
    navi_nutr_7_2_export_data: 'export Data',
    navi_nutr_7_recipe_exchange: 'recipe exchange',
    navi_nutr_8_settings: 'Settings',
    navi_decl_1_home: 'Home',
    navi_decl_2_jobs: 'Jobs',
    navi_decl_2_1_incomplete_data: 'Incomplete Data',
    navi_decl_2_2_tasks: 'Tasks',
    navi_decl_3_declarations: 'Declarations',
    navi_decl_3_1_declarations_overview: 'Declarations Overview',
    navi_decl_3_2_declaration_groups: 'Declaration groups',
    navi_decl_4_templates: 'Templates',
    navi_decl_4_1_templates_overview: 'Templates Overview',
    navi_decl_4_2_template_settings: 'Template Settings',
    navi_decl_4_3_create_templates: 'Create Templates',
    navi_decl_4_4_template_name: 'Template name',
    navi_decl_4_5_template_type: 'Template Type',
    navi_decl_5_print_export: 'Print / Export',
    navi_decl_5_1_print_data: 'Print Data',
    navi_decl_5_2_export_data: 'Export Data',
    navi_decl_6_settings: 'Settings',
    navi_fid_1_home: 'Home',
    navi_fid_2_jobs: 'Jobs',
    navi_fid_2_1_incomplete_data: 'Incomplete Data',
    navi_fid_2_2_tasks: 'Tasks',
    navi_fid_3_technical_data: 'Technical data',
    navi_fid_4_translations: 'Translations',
    navi_fid_4_1_overview: 'Overview',
    navi_fid_4_import_translations: 'import translations',
    navi_fid_5_nutritions: 'Nutritions',
    navi_fid_5_1_nutritions_overview: 'Nutrition overview',
    navi_fid_5_2_add_single_entries: 'add single Entries',
    navi_fid_5_3_import_nutritions: 'import Nutritions',
    navi_fid_5_4_Versions: 'Versions',
    navi_fid_6_branded_products: 'Branded Products',
    navi_fid_6_1_branded_product_overview: 'Branded Products overview',
    navi_fid_6_2_import_branded_products: 'import Branded Products',
    navi_fid_6_3_companies: 'Companies',
    navi_fid_7_manage_user_suggestions: 'Manage user suggestions',
    navi_fid_7_1_new_names: 'new names',
    navi_fid_7_2_new_nutritional_values: 'new nutritional values',
    navi_fid_7_3_new_branded_product: 'new branded product',
    navi_fid_7_4_other_requests: 'other requests',
    navi_fid_8_History: 'History',
    navi_fid_8_1_log_of_changes: 'Log of changes',
    navi_admin_1_home: 'Home',
    navi_admin_2_jobs: 'Jobs',
    navi_admin_2_1_tasks: 'Tasks',
    navi_admin_3_user_management: 'User Management',
    navi_admin_3_1_users_and_roles: 'Users and Roles',
    navi_admin_3_2_create_delete_users: 'Create / Delete Users',
    navi_admin_4_spaces_management: 'Spaces Management',
    navi_admin_4_1_subscriptions: 'Subscriptions',
    navi_admin_4_2_delete_spaces: 'Delete Spaces',
    navi_admin_5_fid_management: 'FID Management',
    navi_admin_5_1_set_user_roles: 'Set User Roles',
    navi_admin__5_2_nutrition_versions: 'Nutrition Versions',
    navi_admin_6_translations_management: 'Translations Management',
    navi_admin_6_1_languages: 'Languages',
    navi_admin_6_2_set_user_roles: 'Set User Roles',
    common_symbols: 'Symbols',
    inco_status_cono: 'Completed - not required',
    inco_status_inno: 'Incompleted - not required',
    inco_status_core: 'Completed - required',
    inco_status_inre: 'Incompleted - required',
    inco_nxi_page:
      'In this overview you will find information on which ingredients or recipes require further details. For this purpose, we always check which information is required for the products made with them. If, for example, there is no product that requires nutritional information, no nutritional values are requested for the raw materials it contains.',
    inco_table_name: 'Name',
    inco_table_unit: 'Unit',
    inco_table_weight: 'Weight',
    inco_table_allergens: 'Allergens',
    inco_table_header_allergens_name: 'Allergen name',
    inco_table_header_allergens_contains: 'contains allergen',
    inco_table_header_allergens_traces: 'traces',
    no_info_about_traces: 'no info about traces',
    contains_no_allergens: 'Contains no allergens',
    common_nutris: 'Nutritional Values',
    common_ingredient_list: 'Ingredient List',
    inco_ingredients: 'Incomplete Ingredients',
    inco_recipes: 'Incomplete Recipes',
    common_table_head_name: 'Name',
    common_table_head_unit: 'Unit',
    common_table_head_weight: 'Weight',
    common_table_head_allergens: 'Allergens',
    common_table_head_nutritional: 'Nutritional',
    common_table_head_ingredientList: 'Ingredient List',
    common_table_head_additives: 'Additives',
    common_table_head_foodsymbols: 'foodsymbols',
    common_table_head_price: 'price',
    common_table_head_foodscale: 'foodscale',
    'ingr_ov title': 'Ingredient Overview',
    ingr_ov_nxi_page:
      'This overview shows all the ingredients that you have stored for your recipes. From here you can call them up or create new ones.',
    ingr_ov_table_name: 'ingredient name',
    ingr_ov_table_group: 'ingredient group',
    ingr_detail_title: 'Ingredient:',
    ingr_detail_nxi_page:
      'This page contains all the information about this ingredient. Fill in all the fields you need for your calculations. The system will also show you these accordingly.',
    common_source: 'Source',
    ingr_detail_inf_title: 'Ingredient Information',
    ingr_detail_inf_nxi:
      'The base information about this ingredient. Use the name how this ingredient is called in "your kitchen".',
    ingr_detail_inf_name: 'Ingredient Name',
    ingr_detail_inf_fid_reference: 'Reference to FID',
    ingr_detail_inf_group: 'Ingredient Group',
    ingr_detail_inf_create_group: 'Create new Group',
    common_uaw_unit: 'Unit',
    ingr_detail_uaw_title: 'Unit and Weight',
    common_uaw_weight_per_unit: 'Weight per Unit in kg',
    common_specific_weight_per_unit: 'Specific weight',
    ingr_detail_uaw_specific_weight: 'Specific weight (kg per liter)',
    ingr_detail_uaw_specific_weight_small: 'Specific weight',
    ingr_detail_pro_title: 'Product',
    ingr_detail_pro_nxi:
      'If an ingredient is sold as such, it can be created as a product for sale. So if you buy e.g. applejuice in bottles and sell it, without modifying, in your shop.',
    ingr_detail_pro_button_add: 'Add Product',
    ingr_detail_pui_title: 'Purchase item',
    ingr_detail_pui_nxi:
      'This function is used, if your ingredient can be bought as different products, e.g. frozen donut from producer A and a nearly similar from producer B.',
    ingr_detail_pui_manage_via_bought_product: 'Manage ingredient data via bought product',
    ingr_detail_pui_name_item: 'Name purchased Item',
    ingr_detail_pui_duplicate_bought_item: 'duplicate bought item',
    ingr_detail_pui_new_bought_item: 'new bought item',
    ingr_detail_dat_title: 'Data Sources',
    ingr_detail_dat_nxi:
      'Where to get informations for Allergens, Nutritional Values, ingredient lists.<br>There are much sources, most informations you can find in our "food ingredient database".',
    ingr_detail_dat_name_of_list: 'Name of list / database',
    ingr_detail_dat_list_item: 'List Item',
    ingr_detail_pac_title: 'Package (kind of and content)',
    ingr_detail_pac_nxi:
      'Especially for purchasing and calculation, it makes sense to define the purchasing package. Select the unit in which you buy the product and how many [main weighing units] are contained.',
    ingr_detail_pac_unit: 'Unit',
    'ingr_detail_pac_weight per unit': 'Weight per Unit in [main weighing unit]',
    common_allergens_title: 'Allergens',
    ingr_detail_all_nxi:
      'Select the allergens contained here and if you also need to label traces, the same. Note that the allergens are displayed for all countries for which you want to declare (see template settings in declaratio).',
    upgr_more_functions: 'Need more functionality?',
    upgr_noti1:
      'Nutrimero offers many additional modules, which will make your life' +
      ' easier. Find out which tools match your tasks and choose one of them.',
    upgr_button_module_management: 'Module Management',
    expe_title: 'Expert Mode',
    advanced_mode: 'Advanced Mode',
    unit_gram: 'gram',
    unit_kilogram: 'kilogram',
    unit_hectogram: 'hectogram',
    unit_tonne: 'tonne',
    unit_milligram: 'milligram',
    unit_microgram: 'microgram',
    unit_ounce: 'ounce',
    unit_pound: 'pound',
    unit_liter: 'liter',
    unit_cubic_decimeter: 'cubic decimeter',
    unit_cubic_centimeter: 'cubic centimeter',
    unit_deciliter: 'deciliter',
    unit_centiliter: 'centiliter',
    unit_milliliter: 'milliliter',
    unit_fluid_ounce: 'fluid ounce',
    unit_cubic_inch: 'cubic inch',
    unit_pint: 'pint',
    unit_cup: 'Cup',
    unit_tablespoon: 'Tablespoon',
    unit_quart: 'quart',
    unit_bag: 'bag',
    unit_sheet: 'sheet',
    unit_can: 'can',
    unit_bucket: 'bucket',
    unit_bottle: 'bottle',
    unit_box: 'box',
    unit_scoop: 'scoop',
    unit_measuring_spoon: 'measuring spoon',
    unit_meter: 'meter',
    unit_parcel: 'parcel',
    unit_portion: 'portion',
    unit_sack: 'sack',
    unit_slice: 'slice',
    unit_piece: 'piece',
    unit_cake: 'cake',
    tech_browser_too_small_1: 'The display area of your browser is too small.',
    tech_browser_too_small_2:
      "To work with nutrimero you need an effective display area in your browser of at least 1024x720 pixels. <br>Alternatively, you can reduce the zoom of your browser's user interface, e.g. with the key combination [Ctrl] + [-].",
    enter_your_email: 'Enter your E-Mail',
    enter_your_password: 'Enter your password',
    your_account_create: 'Your account is created. Login next time using your email and password',
    how_would_you_like_to_manage_your_database: 'How would you like to manage your database?',
    back_to_step: 'Back to Step [x]',
    create_account_for_free: 'create account for free',
    register: 'Register',
    next: 'Next',
    database: 'Database',
    own_space_create: `With your own space you can manage your recipes and ingredients.
    It´s free to create and you can, if required, add several add ons or plans.`,
    create_own_space_button: 'Create own Space',
    own_space_users_on_my_space: 'Users on my space',
    own_space_db_name: 'Database Name',
    own_space_db_label: 'Own Database Name',
    own_space_recalculate_data: 'Recalculate data',
    own_space_dbset_automatically: 'Automatically',
    own_space_dbset_manually: 'Manually',
    own_space_dbset_howOften: 'How often?',
    own_space_dbset_howOften_hour: 'Every hour',
    own_space_dbset_howOften_week: 'Every week',
    own_space_dbset_howOften_4hour: 'Every four hours',
    own_space_dbset_howOften_month: 'Every month',
    own_space_dbset_howOften_day: 'Every day',
    own_space_dbset_recalculate: 'How do you want to recalculate your data?',
    own_space_dbset_allergens: 'Declare traces of allergens',
    own_space_dbset_allergens_info: 'Do you want to declare traces of allergens?',
    own_space_dbset_number_format: 'Number Format / Amount of Digits',
    own_space_dbset_number_format_info: 'Used decimal  separator',
    own_space_dbset_number_format_comma: 'Comma',
    own_space_dbset_number_format_point: 'Point',
    own_space_dbset_number_format_count: 'Count of decimal digits',
    own_space_dbset_number_format_preview: 'Preview Number Format',
    own_space_dbset_units: 'Kind of unit',
    own_space_dbset_units_info: 'Was set while creating database. Not possible to change!',
    own_space_dbset_units_kgs: 'Kilograms (kg)',
    own_space_dbset_units_lb: 'Pounds (lb)',
    own_space_dbset_units_g: 'Grams (g)',
    own_space_dbset_units_oz: 'Ounces (oz.)',
    own_space_dbset_used_units: 'Used units',
    own_space_dbset_weight_units: 'Weight units',
    own_space_dbset_volume_units: 'Volume units',
    own_space_dbset_piece_units: 'Piece units',
    own_space_dbset_piece_add_own: '+ add own',
    own_space_subs_title: 'Your active and all available plans',
    own_space_tags_title: 'Tags List',
    own_space_tags_modal_title_edit: 'Edit Tag',
    own_space_tags_modal_descr: 'Tag Name',
    own_space_tags_modal_title_create: 'Create tag',
    own_space_tags_modal_title_merge: 'Merge tags',
    own_space_tags_modal_title_delete: 'Delete',
    own_space_tags_modal_title_delete_confirm: 'Are you sure you want to remove the marked tags',

    own_space_billing_name_field: 'Name on bill (company or private)',
    own_space_billing_2nd_line: '2nd line on invoice',
    own_space_billing_street_field: 'Street',
    own_space_billing_zip_field: 'ZIP',
    own_space_billing_city_field: 'City',
    own_space_billing_region_state_field: 'Region/State',
    own_space_billing_country_field: 'Country',
    own_space_billing_phone_field: 'Phone',
    own_space_billing_vat_id_field: 'VAT ID',
    own_space_inv_users_name: 'Name',
    own_space_inv_users_inv_stat: 'Invitation Status',
    own_space_inv_users_only_view: 'Can only view',
    own_space_inv_users_can_edit_everything: 'Can edit everything',
    own_space_inv_users_can_edit_ingrid: 'Can edit ingredients',
    own_space_inv_users_can_edit_recipe: 'Can edit Recipes',
    own_space_inv_users_can_edit_prod: 'Can edit Products',
    own_space_inv_users_can_edit_decla: 'Can edit Declarations',
    own_space_inv_users_delete_user: 'Delete User',
    own_space_inv_user_button: 'Invite to Space',
    inv_user_modal_button: 'Invite User',
    inv_user_modal_desc: 'Do you want to invite someone? Please send them an invitation.',
    profile_page_title: 'Profile',
    profile_name_and_photo_title: 'Name and Photo',
    profile_photo: 'Photo',
    profile_photo_upload: 'Upload',
    profile_photo_delete: 'Delete',
    profile_photo_tooltip: 'Photos help your teammates recognize you',
    profile_first_name: 'First Name',
    profile_last_name: 'Last Name',
    profile_location: 'Location (to get found easier by others)',
    profile_password_title: 'Password',
    profile_password_desc: 'Change the email for your account',
    profile_password_link: 'Change Password',
    profile_email_title: 'Email',
    profile_email_desc: 'Change the email for your account',
    profile_email_link: 'Change Email',
    profile_security_title: 'Security',
    profile_security_desc: 'Log out of all sessions except this current browser',
    profile_security_link: 'Log out other sessions',
    profile_changepass_form_old_pass: 'Old Password',
    profile_changepass_form_new_pass: 'New Password',
    profile_changepass_form_repeat: 'Repeat',
    profile_changeemail_form_email: 'Email',
    notifications_settings_title: 'Notifications Settings',
    notifications_settings_rec_email: 'I want to receieve email notifications:',
    notifications_settings_email_for: 'Send me email notifications for:',
    notifications_settings_email_for_activity: 'Activity updates',
    notifications_settings_email_for_daily: 'Daily summaries',
    notifications_settings_push_notif: 'Push Notifications',
    notifications_settings_push_notif_msg: 'Receive Push Notifications',
    profile_db_ibelong_page_title: 'List of databases, where you have access to',
    profile_db_ibelong_table_owner: 'Owner',
    profile_db_ibelong_table_db_name: 'Database Name',
    profile_db_ibelong_table_status: 'Status',
    profile_db_ibelong_table_status_accepted: 'The invitation was accepted',
    profile_db_ibelong_table_status_leavespace: 'Leave the space',
    profile_db_ibelong_request_button: 'Request Access to Space',

    profile_db_ibelong_form_info:
      'Email address or ID of account to access the Database. Also you can search the Database.',
    profile_db_ibelong_form_info_tooltip:
      'The ID or email you have gotten from the owner of the Database. Also, you can search via typing.',

    special_combo_box_add_ingridient_message: 'Ingredient not yet created in own database. Click the “plus” to add it.',
    special_combo_box_requst_to_add_ingridient_message:
      'Entry not yet created in own database and not available in our global database (FID). Click on "send" to submit as request.',
    pecial_combo_box_requst_modal_success_text:
      'Your suggestion has been successfully submitted and is now being reviewed by us. You will be notified by e-mail about the approval, but you can start working with the term from now on.',
    special_combo_box_add_ingridient_modal_success_text:
      'Ingredient was saved to your own space, so you can use it. For adding information to this dataset, you will find it in ingredient overview.',
    special_combo_box_requst_modal_title: 'Report missing ingredient / recipe',
    special_combo_box_requst_modal_desc_1:
      'In order to continuously complete the "food ingredient database", terms entered in nutrimero that are not yet existing will be entered after verification.',
    special_combo_box_requst_modal_desc_2:
      'Please add any additional information so that our team can act more easily.',
    special_combo_box_requst_modal_input_title_label: 'Ingredient / Recipe Name',
    special_combo_box_requst_modal_input_desc_label: 'Additional Information',
    create_group_title: 'Create Group',
    create_group_field_label: 'Group Name',
    create_ingridient_title: 'Create an Ingredient',
    create_ingridient_unit_desc: 'Unit and weight can be changed with modifying the recipe later. ',
    product_details_title: 'Product Information',
    product_details_name: 'Product Name',
    product_details_number: 'Product Number',
    product_details_group: 'Product group',
    product_details_create_group: 'Create New Group',
    product_details_type: 'Type of Reference',
    product_details_type_ingredient: 'Ingredient',
    product_details_type_recipe: 'Recipe',
    product_details_type_message: 'Choose Type of Reference',
    product_details_ingr_ref: 'Ingredient Reference',
    product_details_descr: 'Description',
    product_details_descr_short: 'Short Description',
    recipe_details_info_title: 'Recipe Information',
    recipe_details_name: 'Recipe Name',
    recipe_details_tags: 'Tags',
    recipe_details_fid: 'FID',
    recipe_details_group: 'Group',
    recipe_details_ingr_list_and_steps: 'Ingredient List and Steps',
    recipe_details_weight_title: 'Weight',
    recipe_details_weight_number_serv: 'Number of servings',
    recipe_details_weight_number_change_weight: 'Change of weight, %',
    recipe_details_weight_number_weight_per_u: 'Weight per unit',
    recipe_details_product_title: 'Product',
    recipe_details_product_name: 'Name',
    recipe_details_product_number: 'Number',
    ingredient_info_tip:
      'The basic information are used to manage the ingredients in recipes. As name use this, which is used in your production.\nRemind that it is not about the bought product. If you have ketchup in your kitchen, the name should be “ketchup”. not the detailes brand name.\nThe reference to the FID helps to offer better informations about allergens, nutritions or allergens. It is useful to take the best matching entry from this database. Just search by typing.',
    ingredient_unit_weight_tip:
      'As unit you should use this, with which you handle the ingredient. So recommended is a weighing unit (kg, g, lb, oz.) for the most stuff, only things which are used as “piece” (like bread roles, chocolate decoration, etc.) you can set with their piece unit.\nIf by unit the effective weight is not clear, you have to fill in the weight per piece. Also you can add the specific weight, which enabled you to create recipes by cups or other volume units. With clicking the button beside the specific weight field, you can search our database.',
    ingredient_purchase_item_tip:
      'Using the management of ingredients via purchase items is useful when, for example, the declaration or the purchase price of the raw material may change. As soon as a raw material can be purchased in different types (e.g. vegetable broth A and vegetable broth B), a tick should be set here. If it is about an apple, the administration via purchase items makes less sense.',
    ingredient_product_for_sale: 'If a raw product is sold as such, it can be created as a product for sale.',
    ingredient_create_new_group: 'Create New Group',
    ingredient_data_source_label: 'Where to get informations for Allergens, Nutritional Values, ingredient lists.',
    package_kind_content: 'Package kind of and content',
    common_allergens_direct: 'Direct',
    common_allergens_traces: 'Traces',
    common_no_information: 'No information',
    common_edit: 'Edit',
    common_values_per_100g: 'Values per 100g',
    common_nutritio_values_modal_title: 'Nutritional Values',
    common_nutritio: 'Nutrition',
    common_value: 'Value',
    common_incomplete: 'Incomplete',
    common_declaration_name: 'Declaration Name',
    common_kind_of_ingredient_list: 'Kind of Ingredient List',
    common_detailed: 'Detailed',
    common_as_text_kind: 'As Text (string)',
    common_only_additives: 'Only additives',
    common_only_declaration_name: 'only declaration name',
    ingredient_reconstituted: 'Can the Ingredients be reconstituted?',
    resulting_ingredient: 'Resulting Ingredient (from FID)',
    amount_of_product_1000_g: 'Amount of product for 1000g',
    amount_of_water_1000_g: 'Amount of water for 1000g',
    common_food_symbols: 'foodSymbols',
    common_food_symbols_confirmation: 'Confirmation that all the symbols are chosen correctly',

    //overview table columns name
    name: 'name',
    group: 'group',
    referenceType: 'type',
    referenceName: 'reference',
    relatedIngredient: 'related ingredient',
    create_ingridient_button_name: 'Create Ingredient',
    create_bought_item_button_name: 'Create Bought Item',
    create_product_button_name: 'Create Product',
    create_recipe_button_name: 'Create Recipe',
    element_is_not_exist_table_overview: 'Elements do not exist',
    ingredients_table_add_subrow: 'Add SubRow',
    ingredients_table_delete_row: 'Delete Row',
    ingredient_table_title: 'Ingredient Table',
    ingredient_table_add_ingredient: 'Add Ingredient',
    ingredient_table_delete_ingredient: 'Delete Ingredient',
    ingredient_declaration_name_label: 'Ingredient Declaration Name',
    common_amount: 'Amount',
    common_e_number: 'E-Number',
    common_additive_class: 'Additive Class',
    common_organic: 'Organic',
    common_declare: 'Declare',
    common_view: 'View',
    db_settings_add_new_unit: 'Add new unit',
    db_settings_edit_unit: 'Edit unit',
    db_settings_remove_unit: 'Remove unit',
    db_settings_select_unit_add: 'Please select which kind of unit you want to add:',
    db_settings_select_unit_edit: 'Which kind of unit it should be:',
    db_settings_confirm_remove_unit: 'Are you sure you want to remove this unit?',
    db_settings_name_of_unit: 'Name of the unit',
    db_settings_wpu: 'Weight per unit',
    db_settings_vpu: 'Volume per unit',

    bid_information: 'Bought Item Information',
    bid_name: 'Bought Item Name',
    bid_data_sources_title: 'Data Sources',
    bid_where_get_info: 'Where to get informations for Allergens, Nutritional Values, ingredient lists.',
    bid_name_of_list: 'Name of list / database',
    bid_list_item: 'List Item',

    remove_db_modal_title: 'Deleting the selected database',
    remove_db_modal_content: 'Are you sure you want to delete the selected database?',
    remove_db_modal_btn_text: 'Proceed',

    confirm_list_item_modal_title: 'Confirmation of the list item change',
    confirm_list_item_modal_content:
      'If you change the list item. The selected sources associated with this list item will switch to manual mode.',
    confirm_list_item_modal_btn_text: 'Proceed',

    confirm_leave_page_modal_title: 'You have unsaved changes',
    confirm_leave_page_modal_content: 'You have unsaved changes, are you sure you want to leave?',
    confirm_leave_page_modal_btn_text: 'Proceed',

    bitem_detail_inf_nxi:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    bitem_unit_weight_tip:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
};
