import React from 'react';

import pallete from '../../theme/pallete';

const NutrimeroLogo = ({
  color = pallete.secondary1,
  height = 25,
  width = 99,
  selected,
}) => {
  let color1 = color;
  if (selected) {
    color1 = pallete.backgroundWhite;
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 99 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.083 17.164H14.4988L15.7808 8.79174C15.9267 7.83081 16.1378 7.02119 16.4125 6.36289C16.6856 5.70615 17.0333 5.1742 17.4539 4.77017C17.8745 4.36615 18.382 4.06975 18.9764 3.88412C19.5709 3.69848 20.2615 3.60645 21.0499 3.60645C22.3847 3.60645 23.4137 3.94808 24.1354 4.63134C24.857 5.3146 25.2171 6.35509 25.2171 7.75593C25.2171 8.19428 25.1768 8.66695 25.0976 9.17237L23.8746 17.1656H21.2905L22.5321 9.07098C22.5585 8.86818 22.5818 8.6919 22.602 8.54059C22.6221 8.38927 22.6314 8.24576 22.6314 8.11004C22.6314 7.4533 22.4871 6.98843 22.2015 6.71856C21.9144 6.44869 21.4907 6.31453 20.9289 6.31453C20.5005 6.31453 20.1404 6.35197 19.8471 6.42841C19.5538 6.50485 19.3101 6.6468 19.1161 6.85895C18.9221 7.06955 18.7623 7.3519 18.635 7.70601C18.5077 8.06012 18.4053 8.51563 18.3246 9.07253L17.083 17.164Z'
        fill={color1}
      />
      <path
        d='M33.9361 4.01562H36.5202L35.2383 12.1446C35.0908 13.1055 34.8813 13.9151 34.6066 14.5734C34.3319 15.2317 33.9858 15.7621 33.5652 16.1677C33.1446 16.5717 32.6402 16.8681 32.052 17.0522C31.4637 17.2378 30.7824 17.3298 30.0079 17.3298C28.6856 17.3298 27.6675 16.9882 26.9536 16.3065C26.2396 15.6233 25.8811 14.5906 25.8811 13.2084C25.8811 12.9713 25.8935 12.728 25.9215 12.4753C25.9478 12.2226 25.9742 11.987 26.0022 11.767L27.2236 4.01718H29.7876L28.5459 11.8669C28.5195 12.0525 28.4994 12.2382 28.4854 12.4238C28.4714 12.6094 28.4652 12.7529 28.4652 12.8543C28.4652 13.5126 28.6049 13.9713 28.8858 14.2333C29.1667 14.4954 29.5873 14.6264 30.1476 14.6264C30.562 14.6264 30.9128 14.5843 31.1999 14.5001C31.487 14.4159 31.7276 14.2723 31.9216 14.0695C32.1156 13.8667 32.2723 13.586 32.3918 13.2225C32.5114 12.859 32.6122 12.4004 32.6929 11.8435L33.9361 4.01562Z'
        fill={color1}
      />
      <path
        d='M39.0081 13.2733C39.0081 13.6773 39.1074 13.9768 39.3076 14.1702C39.5078 14.3637 39.8415 14.4604 40.3087 14.4604H42.5126L42.0671 17.1669H39.6879C38.5394 17.1669 37.7075 16.8596 37.1938 16.2434C36.6801 15.6272 36.4224 14.7552 36.4224 13.6258C36.4224 13.3887 36.4348 13.1407 36.4628 12.8786C36.4892 12.6181 36.5233 12.3435 36.5637 12.0565L38.2864 0.801431L40.9109 0.422363L40.3708 4.01338H43.6968L42.8959 6.7199H39.9502L39.0888 12.2843C39.0624 12.4699 39.0422 12.6508 39.0283 12.8287C39.0143 13.0065 39.0081 13.1547 39.0081 13.2733Z'
        fill={color1}
      />
      <path
        d='M49.8117 3.96729C50.1982 3.96729 50.5567 3.97665 50.8842 3.99225C51.2116 4.0094 51.0766 4.00004 51.2504 4.0172L50.8143 6.72372C50.6141 6.70657 50.4263 6.69409 50.254 6.68629C50.0802 6.67849 49.9141 6.67381 49.7527 6.67381C49.3259 6.67381 48.9643 6.71125 48.671 6.78768C48.3776 6.86412 48.1371 7.01076 47.9493 7.23071C47.7615 7.45066 47.6094 7.7455 47.4883 8.11677C47.3673 8.48804 47.2602 8.9685 47.1671 9.55817L45.9844 17.1707H43.4205L44.6233 9.33041C44.7971 8.21817 45.0268 7.31183 45.3139 6.61141C45.6011 5.91255 45.9549 5.36812 46.3755 4.9797C46.7961 4.59127 47.2897 4.32608 47.8577 4.18256C48.4258 4.03904 49.0776 3.96729 49.8117 3.96729Z'
        fill={color1}
      />
      <path
        d='M52.5859 4.01562H55.17L53.1461 17.1676H50.562L52.5859 4.01562Z'
        fill={color1}
      />
      <path
        d='M63.167 17.164H60.5829L61.8245 9.04445C61.8773 8.65759 61.9052 8.30192 61.9052 7.98212C61.9052 7.34098 61.7748 6.90264 61.5141 6.66708C61.2534 6.43153 60.8762 6.31297 60.3827 6.31297C60.063 6.31297 59.7882 6.35977 59.5616 6.45181C59.3351 6.5454 59.1411 6.7014 58.9812 6.91979C58.8213 7.13975 58.6879 7.4221 58.5808 7.76685C58.4737 8.11316 58.3806 8.54683 58.2999 9.06941L57.0582 17.164H54.4741L55.7561 8.79174C56.037 6.98843 56.5507 5.67339 57.2988 4.84661C58.0469 4.01983 59.0883 3.60645 60.4246 3.60645C61.8928 3.60645 62.9357 4.12123 63.5503 5.14924C64.2317 4.12123 65.3134 3.60645 66.7956 3.60645C67.9984 3.60645 68.9359 3.9356 69.611 4.59234C70.2861 5.25064 70.6229 6.27865 70.6229 7.67793C70.6229 8.11628 70.5826 8.61391 70.5034 9.17081L69.2804 17.164H66.6963L67.9379 9.06941C67.9783 8.79954 68.0046 8.59363 68.0171 8.45011C68.0295 8.3066 68.0372 8.15996 68.0372 8.00708C68.0372 7.36594 67.9069 6.92447 67.6461 6.67956C67.3854 6.43465 67.002 6.31297 66.4945 6.31297C66.1748 6.31297 65.9001 6.35977 65.6735 6.45181C65.4469 6.5454 65.2529 6.7014 65.0931 6.91979C64.9332 7.13975 64.7997 7.4221 64.6926 7.76685C64.5855 8.11316 64.4924 8.54683 64.4117 9.06941L63.167 17.164Z'
        fill='#323366'
      />
      <path
        d='M73.999 11.8041C73.985 11.9227 73.9757 12.0319 73.968 12.1333C73.9602 12.2347 73.9587 12.3267 73.9587 12.4109C73.9587 13.2034 74.1185 13.7821 74.4398 14.144C74.7611 14.5075 75.3074 14.6885 76.0834 14.6885C76.4574 14.6885 76.7942 14.6885 77.0953 14.6885C77.3948 14.6885 77.682 14.6838 77.9567 14.676C78.2298 14.6682 78.4983 14.6588 78.7575 14.651C79.0183 14.6432 79.3023 14.6214 79.6096 14.5871L79.161 17.0783C78.7342 17.1298 77.9505 17.175 77.4352 17.1922C76.9215 17.2078 76.3426 17.2172 75.7016 17.2172C74.9007 17.2172 74.2287 17.0986 73.6886 16.863C73.1485 16.6275 72.7139 16.303 72.3865 15.8896C72.059 15.4762 71.8262 14.9833 71.685 14.4108C71.5437 13.8383 71.4754 13.2128 71.4754 12.5389C71.4754 12.1676 71.4925 11.7932 71.5251 11.4141C71.5577 11.0351 71.6089 10.6342 71.6756 10.213C71.8898 8.848 72.1863 7.73419 72.5665 6.87466C72.9467 6.01512 73.3844 5.34434 73.8795 4.86388C74.3731 4.38341 74.9147 4.05426 75.5014 3.87798C76.0896 3.70015 76.6902 3.61279 77.3048 3.61279C78.6535 3.61279 79.6391 3.92946 80.2599 4.56125C80.8807 5.19303 81.1911 6.04944 81.1911 7.12893C81.1911 7.51736 81.1507 7.93855 81.0716 8.39406C80.8574 9.5921 80.3902 10.4594 79.6686 10.9992C78.9484 11.5389 77.8449 11.8088 76.3627 11.8088H73.999V11.8041ZM77.1248 6.08844C76.3364 6.08844 75.7357 6.32868 75.3213 6.80914C74.9069 7.28961 74.5795 8.15382 74.3405 9.40179H76.3643C76.7647 9.40179 77.1015 9.37995 77.3762 9.33939C77.6494 9.29727 77.8698 9.22083 78.0374 9.11164C78.205 9.00244 78.3354 8.8636 78.4285 8.69513C78.5216 8.52665 78.6023 8.31606 78.6691 8.06335C78.7094 7.91203 78.7358 7.77631 78.7482 7.65931C78.7622 7.54076 78.7684 7.44092 78.7684 7.35513C78.7684 6.9667 78.6489 6.65938 78.4083 6.43163C78.1662 6.20232 77.7378 6.08844 77.1248 6.08844Z'
        fill='#323366'
      />
      <path
        d='M87.4511 3.96387C87.8376 3.96387 88.1945 3.97323 88.5236 3.98883C88.851 4.00599 89.1009 4.02314 89.2748 4.03874L88.4537 6.72031C88.2535 6.70315 88.0673 6.69067 87.8934 6.68287C87.7196 6.67507 87.5536 6.67039 87.3937 6.67039C86.9653 6.67039 86.6053 6.70783 86.3119 6.78427C86.0186 6.8607 85.778 7.00734 85.5918 7.22729C85.404 7.44725 85.2504 7.74208 85.1308 8.11335C85.0113 8.48462 84.9043 8.96509 84.8111 9.55475L83.6285 17.1673H81.0645L82.2658 9.32699C82.4396 8.21474 82.6693 7.30841 82.958 6.60799C83.2451 5.90913 83.599 5.36471 84.0196 4.97628C84.4402 4.58785 84.9337 4.32266 85.5018 4.17914C86.0652 4.03563 86.717 3.96387 87.4511 3.96387Z'
        fill='#323366'
      />
      <path
        d='M94.7594 3.62692C96.1484 3.65343 97.21 4.04186 97.9441 4.78908C98.6782 5.53786 99.0305 6.78738 98.9979 8.54077C98.9902 8.9292 98.9638 9.32855 98.9157 9.74038C98.8676 10.1522 98.8055 10.603 98.731 11.0897C98.5354 12.2503 98.284 13.244 97.9752 14.0724C97.6663 14.9007 97.2798 15.5777 96.8158 16.0988C96.3517 16.6213 95.8039 16.9988 95.1722 17.2313C94.5405 17.4637 93.8033 17.5714 92.9621 17.5542C91.5731 17.5277 90.5115 17.1392 89.7774 16.392C89.0433 15.6448 88.6909 14.3937 88.7235 12.6403C88.7313 12.2535 88.7577 11.8526 88.8058 11.4407C88.8524 11.0289 88.9144 10.5781 88.9905 10.0914C89.186 8.93076 89.4375 7.94175 89.7463 7.12121C90.0552 6.30068 90.4416 5.6299 90.9057 5.10731C91.3682 4.58473 91.9176 4.2041 92.5493 3.9623C93.181 3.72363 93.9166 3.61132 94.7594 3.62692ZM93.2508 14.9303C93.6916 14.9397 94.0625 14.8835 94.3651 14.7619C94.6678 14.6418 94.9254 14.4358 95.1381 14.1441C95.3507 13.854 95.5338 13.4609 95.6906 12.9664C95.8458 12.4719 95.9917 11.8541 96.1251 11.1163C96.3036 10.0914 96.3999 9.26615 96.4107 8.64217C96.4278 7.73116 96.2741 7.10874 95.9529 6.77335C95.6316 6.43796 95.1303 6.26324 94.449 6.25076C94.0082 6.2414 93.6372 6.29756 93.333 6.41923C93.0304 6.53935 92.7728 6.74526 92.5601 7.03697C92.3475 7.32713 92.1675 7.7218 92.0185 8.21474C91.8695 8.70925 91.7283 9.32699 91.5948 10.0664C91.4147 11.1085 91.3201 11.9337 91.3092 12.5405C91.2921 13.4515 91.4411 14.0739 91.7562 14.4078C92.0728 14.7432 92.5695 14.9179 93.2508 14.9303Z'
        fill='#323366'
      />
      <path
        d='M14.5868 23.336C14.6007 23.0973 14.6628 22.8711 14.7746 22.6543C14.8863 22.4374 15.0307 22.2487 15.206 22.0849C15.3814 21.9226 15.5816 21.7916 15.8051 21.6949C16.0286 21.5982 16.2567 21.5498 16.488 21.5498C16.6339 21.5498 16.7627 21.567 16.876 21.6044C16.9893 21.6403 17.0855 21.6855 17.1616 21.7386C17.2392 21.7916 17.2982 21.8478 17.3401 21.907C17.382 21.9663 17.4099 22.0162 17.4223 22.0599L17.5263 21.6294H18.1207L17.576 24.6775H16.9521L17.025 24.2906C16.994 24.3296 16.9458 24.3764 16.8822 24.4295C16.8186 24.4825 16.741 24.5324 16.6494 24.5792C16.5578 24.626 16.4492 24.665 16.3266 24.6994C16.204 24.7337 16.0674 24.7508 15.9169 24.7508C15.7042 24.7508 15.5133 24.7134 15.3426 24.6385C15.1719 24.5636 15.0291 24.4607 14.9142 24.3328C14.7978 24.2033 14.7109 24.0535 14.6535 23.8804C14.5992 23.7072 14.5759 23.5263 14.5868 23.336ZM15.2231 23.2564C15.2091 23.5091 15.2759 23.7197 15.4264 23.8866C15.577 24.0551 15.7849 24.1378 16.0503 24.1378C16.2102 24.1378 16.3607 24.1081 16.502 24.0504C16.6432 23.9911 16.7674 23.9131 16.8745 23.8133C16.9815 23.7135 17.0685 23.5965 17.1336 23.4623C17.1988 23.3282 17.2361 23.1862 17.2454 23.0364C17.2594 22.7837 17.1926 22.5747 17.0421 22.4093C16.8915 22.244 16.6789 22.1613 16.4026 22.1613C16.2474 22.1613 16.1015 22.1894 15.9619 22.2455C15.8237 22.3017 15.6996 22.3797 15.5925 22.4795C15.4854 22.5794 15.3985 22.6964 15.3333 22.8305C15.2681 22.9631 15.2309 23.1051 15.2231 23.2564Z'
        fill='#323366'
      />
      <path
        d='M19.0561 24.6746H18.4399L19.455 19.0073H20.0711L19.0561 24.6746Z'
        fill='#323366'
      />
      <path
        d='M20.5386 24.6746H19.9224L20.9374 19.0073H21.5536L20.5386 24.6746Z'
        fill='#323366'
      />
      <path
        d='M23.0897 23.336C23.1037 23.0973 23.1658 22.8711 23.2775 22.6543C23.3892 22.4374 23.5336 22.2487 23.709 22.0849C23.8843 21.9226 24.0845 21.7916 24.308 21.6949C24.5315 21.5982 24.7597 21.5498 24.9909 21.5498C25.1368 21.5498 25.2656 21.567 25.3789 21.6044C25.4922 21.6403 25.5885 21.6855 25.6645 21.7386C25.7421 21.7916 25.8011 21.8478 25.843 21.907C25.8849 21.9663 25.9128 22.0162 25.9252 22.0599L26.0292 21.6294H26.6237L26.0789 24.6775H25.455L25.5279 24.2906C25.4969 24.3296 25.4488 24.3764 25.3851 24.4295C25.3215 24.4825 25.2439 24.5324 25.1523 24.5792C25.0608 24.626 24.9521 24.665 24.8295 24.6994C24.7069 24.7337 24.5703 24.7508 24.4198 24.7508C24.2072 24.7508 24.0163 24.7134 23.8455 24.6385C23.6748 24.5636 23.532 24.4607 23.4172 24.3328C23.3008 24.2033 23.2139 24.0535 23.1564 23.8804C23.1021 23.7072 23.0788 23.5263 23.0897 23.336ZM23.726 23.2564C23.7121 23.5091 23.7788 23.7197 23.9293 23.8866C24.0799 24.0551 24.2879 24.1378 24.5533 24.1378C24.7131 24.1378 24.8637 24.1081 25.0049 24.0504C25.1461 23.9911 25.2703 23.9131 25.3774 23.8133C25.4845 23.7135 25.5714 23.5965 25.6366 23.4623C25.7017 23.3282 25.739 23.1862 25.7483 23.0364C25.7623 22.7837 25.6955 22.5747 25.545 22.4093C25.3944 22.244 25.1818 22.1613 24.9056 22.1613C24.7504 22.1613 24.6045 22.1894 24.4648 22.2455C24.3267 22.3017 24.2025 22.3797 24.0954 22.4795C23.9883 22.5794 23.9014 22.6964 23.8362 22.8305C23.771 22.9631 23.7338 23.1051 23.726 23.2564Z'
        fill='#323366'
      />
      <path
        d='M30.2415 22.9496C30.226 23.207 30.167 23.4457 30.063 23.6641C29.959 23.8825 29.8209 24.0728 29.6502 24.2335C29.4779 24.3942 29.2808 24.5205 29.0557 24.6125C28.8307 24.7046 28.5917 24.7514 28.3403 24.7514C28.0935 24.7514 27.8855 24.7015 27.7179 24.6016C27.5487 24.5018 27.4401 24.3942 27.3873 24.2772C27.3811 24.3021 27.378 24.3271 27.3764 24.3536C27.3749 24.3801 27.3702 24.4066 27.364 24.43L27.3206 24.6781H26.6967L27.7117 19.0107H28.3356L27.7893 22.0745C27.8358 22.0168 27.8979 21.9575 27.974 21.8967C28.05 21.8358 28.1385 21.7797 28.2378 21.7282C28.3371 21.6767 28.4458 21.6346 28.5637 21.6003C28.6817 21.566 28.809 21.5488 28.944 21.5488C29.3646 21.5488 29.6921 21.6736 29.928 21.9247C30.1608 22.1743 30.2663 22.516 30.2415 22.9496ZM29.5555 23.0214C29.5695 22.7734 29.5089 22.5674 29.3723 22.4052C29.2373 22.243 29.0356 22.1603 28.7702 22.1603C28.615 22.1603 28.4675 22.1884 28.3263 22.2445C28.185 22.3007 28.0609 22.3787 27.9538 22.4785C27.8467 22.5784 27.7598 22.6954 27.6946 22.8295C27.6294 22.9637 27.5922 23.1056 27.5829 23.2554C27.5689 23.5081 27.6356 23.7187 27.7862 23.8856C27.9367 24.0541 28.1462 24.1368 28.4179 24.1368C28.5777 24.1368 28.7236 24.1071 28.8586 24.0494C28.9921 23.9901 29.1101 23.9106 29.2094 23.8092C29.3087 23.7062 29.3894 23.5892 29.4515 23.4551C29.5105 23.3225 29.5462 23.1774 29.5555 23.0214Z'
        fill='#323366'
      />
      <path
        d='M30.6645 23.1472C30.7064 22.9288 30.7856 22.7213 30.9035 22.5279C31.0215 22.3329 31.1658 22.1644 31.3381 22.0209C31.5104 21.8774 31.7028 21.7635 31.9123 21.6777C32.1234 21.5919 32.3422 21.5498 32.5688 21.5498C32.7768 21.5498 32.9661 21.5872 33.1353 21.6621C33.3045 21.737 33.4473 21.84 33.5637 21.9679C33.6801 22.0973 33.767 22.2487 33.8244 22.4234C33.8818 22.5981 33.9051 22.7837 33.8943 22.9772C33.8803 23.2205 33.8198 23.4483 33.7142 23.662C33.6071 23.8757 33.4674 24.0629 33.2936 24.2236C33.1198 24.3842 32.9196 24.5122 32.6945 24.6058C32.4679 24.7009 32.2336 24.7477 31.9868 24.7477C31.7649 24.7477 31.5616 24.7087 31.38 24.6307C31.1984 24.5527 31.0478 24.4435 30.9268 24.3016C30.8073 24.1612 30.7219 23.9911 30.6723 23.7946C30.6257 23.6027 30.621 23.3859 30.6645 23.1472ZM31.2806 23.1534C31.2527 23.2892 31.2543 23.4171 31.2838 23.5356C31.3132 23.6557 31.3645 23.7587 31.4358 23.8492C31.5088 23.9397 31.6004 24.0099 31.7106 24.0613C31.8208 24.1128 31.9449 24.1378 32.0799 24.1378C32.2243 24.1378 32.3671 24.1097 32.5052 24.0535C32.6433 23.9974 32.7675 23.9194 32.8777 23.8195C32.9879 23.7197 33.0779 23.6043 33.1477 23.4732C33.2176 23.3422 33.2579 23.2034 33.2657 23.0583C33.2734 22.9319 33.2579 22.8134 33.2207 22.7042C33.1834 22.595 33.1276 22.4998 33.0515 22.4203C32.977 22.3407 32.8854 22.2767 32.7799 22.2315C32.6744 22.1847 32.558 22.1613 32.4323 22.1613C32.2972 22.1613 32.1653 22.1863 32.0365 22.2377C31.9077 22.2892 31.7913 22.3594 31.6873 22.4483C31.5833 22.5388 31.4948 22.6449 31.4219 22.7666C31.3505 22.8898 31.3024 23.0177 31.2806 23.1534Z'
        fill='#323366'
      />
      <path
        d='M37.0588 24.7425H36.5357L36.6133 23.897C36.4581 24.145 36.2672 24.3494 36.0375 24.51C35.8078 24.6707 35.5393 24.7503 35.2289 24.7503C34.9185 24.7503 34.6888 24.666 34.5367 24.4991C34.3846 24.3322 34.3272 24.1122 34.3676 23.8393L34.7618 21.6304H35.3779L35.0349 23.5631C35.0163 23.7145 35.0473 23.8424 35.1296 23.95C35.2103 24.0576 35.3515 24.1185 35.5549 24.1325C35.6992 24.1325 35.8373 24.1076 35.9661 24.0561C36.095 24.0046 36.2114 23.936 36.3153 23.8486C36.4193 23.7613 36.5062 23.6567 36.5761 23.5351C36.6459 23.4134 36.6956 23.2792 36.722 23.1342L36.9889 21.6319H37.6128L37.0588 24.7425Z'
        fill='#323366'
      />
      <path
        d='M38.7289 20.2837H39.345L39.1013 21.6331H39.9146L39.8168 22.1868H39.0036L38.5566 24.6734H37.9404L38.3874 22.1868H38.0848L38.1825 21.6331H38.4852L38.7289 20.2837Z'
        fill='#323366'
      />
      <path
        d='M41.9301 21.628H42.4748C42.4562 21.7793 42.4407 21.9166 42.4298 22.0398C42.4174 22.1631 42.4034 22.2863 42.3864 22.408C42.5462 22.1459 42.7433 21.9369 42.9777 21.7809C43.212 21.6249 43.479 21.5469 43.7785 21.5469C44.0827 21.5469 44.3124 21.6311 44.4645 21.8027C44.6166 21.9727 44.6725 22.1911 44.6337 22.4595L44.2379 24.6761H43.614L43.9648 22.7434C43.9834 22.5874 43.9539 22.4548 43.8748 22.3456C43.7956 22.2364 43.6528 22.1771 43.4464 22.1677C43.3021 22.1677 43.1639 22.1927 43.0351 22.2442C42.9063 22.2957 42.7899 22.3643 42.6859 22.4517C42.5819 22.539 42.4935 22.6435 42.4205 22.7652C42.3476 22.8869 42.2979 23.021 42.27 23.1661L42.0015 24.6761H41.3853L41.9301 21.628Z'
        fill='#323366'
      />
      <path
        d='M47.6323 24.7425H47.1093L47.1869 23.897C47.0317 24.145 46.8408 24.3494 46.6111 24.51C46.3814 24.6707 46.1129 24.7503 45.8025 24.7503C45.4921 24.7503 45.2624 24.666 45.1103 24.4991C44.9582 24.3322 44.9008 24.1122 44.9411 23.8393L45.3353 21.6304H45.9515L45.6085 23.5631C45.5898 23.7145 45.6209 23.8424 45.7031 23.95C45.7839 24.0576 45.9251 24.1185 46.1284 24.1325C46.2727 24.1325 46.4109 24.1076 46.5397 24.0561C46.6685 24.0046 46.7849 23.936 46.8889 23.8486C46.9929 23.7613 47.0798 23.6567 47.1496 23.5351C47.2195 23.4134 47.2691 23.2792 47.2955 23.1342L47.5625 21.6319H48.1864L47.6323 24.7425Z'
        fill='#323366'
      />
      <path
        d='M52.6061 22.3503C52.471 22.3503 52.336 22.369 52.2041 22.4049C52.0706 22.4407 51.9495 22.4953 51.8393 22.5686C51.7292 22.642 51.6345 22.7324 51.5584 22.8416C51.4824 22.9508 51.4296 23.0756 51.4032 23.2176L51.1378 24.6761H50.5217L51.0664 21.628H51.6112C51.5926 21.7793 51.5771 21.9166 51.5662 22.0398C51.5538 22.1631 51.5398 22.2863 51.5227 22.408C51.6081 22.2769 51.7105 22.1568 51.8331 22.0507C51.9557 21.9447 52.0799 21.8542 52.2072 21.7809C52.3344 21.7076 52.4586 21.6514 52.5797 21.6093C52.7007 21.5672 52.8047 21.5469 52.8916 21.5469L52.6061 22.3503Z'
        fill='#323366'
      />
      <path
        d='M53.3953 24.6734H52.7792L53.3239 21.6253H53.9401L53.3953 24.6734ZM53.4543 20.4959C53.4621 20.3695 53.5164 20.2634 53.6173 20.1792C53.7182 20.0949 53.8237 20.0513 53.9292 20.0513C54.0161 20.0513 54.0922 20.0872 54.1589 20.1574C54.2257 20.2275 54.2552 20.3165 54.249 20.4241C54.2412 20.5505 54.1869 20.655 54.086 20.7377C53.9836 20.8203 53.8827 20.8609 53.7818 20.8609C53.6902 20.8609 53.6111 20.8266 53.5443 20.7595C53.4776 20.6893 53.4481 20.6019 53.4543 20.4959Z'
        fill='#323366'
      />
      <path
        d='M56.8097 24.6734H56.1936L56.7383 21.6253H57.3545L56.8097 24.6734ZM56.8687 20.4959C56.8765 20.3695 56.9308 20.2634 57.0317 20.1792C57.1326 20.0949 57.2381 20.0513 57.3436 20.0513C57.4305 20.0513 57.5066 20.0872 57.5733 20.1574C57.6401 20.2275 57.6696 20.3165 57.6633 20.4241C57.6556 20.5505 57.6013 20.655 57.5004 20.7377C57.398 20.8203 57.2971 20.8609 57.1962 20.8609C57.1046 20.8609 57.0255 20.8266 56.9587 20.7595C56.892 20.6893 56.8625 20.6019 56.8687 20.4959Z'
        fill='#323366'
      />
      <path
        d='M57.4814 23.1472C57.5233 22.9288 57.6024 22.7213 57.7204 22.5279C57.8384 22.3329 57.9827 22.1644 58.155 22.0209C58.3272 21.8774 58.5197 21.7635 58.7292 21.6777C58.9403 21.5919 59.1591 21.5498 59.3857 21.5498C59.5937 21.5498 59.7831 21.5872 59.9522 21.6621C60.1214 21.737 60.2642 21.84 60.3806 21.9679C60.497 22.0973 60.5839 22.2487 60.6413 22.4234C60.6988 22.5981 60.722 22.7837 60.7112 22.9772C60.6972 23.2205 60.6367 23.4483 60.5311 23.662C60.424 23.8757 60.2844 24.0629 60.1105 24.2236C59.9367 24.3842 59.7365 24.5122 59.5114 24.6058C59.2864 24.7009 59.0505 24.7477 58.8037 24.7477C58.5818 24.7477 58.3785 24.7087 58.1969 24.6307C58.0153 24.5527 57.8647 24.4435 57.7437 24.3016C57.6242 24.1612 57.5388 23.9911 57.4891 23.7946C57.4426 23.6027 57.4395 23.3859 57.4814 23.1472ZM58.0975 23.1534C58.0696 23.2892 58.0712 23.4171 58.1006 23.5356C58.1301 23.6557 58.1814 23.7587 58.2527 23.8492C58.3241 23.9397 58.4157 24.0099 58.5275 24.0613C58.6392 24.1128 58.7618 24.1378 58.8968 24.1378C59.0412 24.1378 59.184 24.1097 59.3221 24.0535C59.4602 23.9974 59.5844 23.9194 59.6946 23.8195C59.8048 23.7197 59.8948 23.6043 59.9646 23.4732C60.0345 23.3422 60.0748 23.2034 60.0826 23.0583C60.0904 22.9319 60.0748 22.8134 60.0376 22.7042C60.0003 22.595 59.9445 22.4998 59.8684 22.4203C59.7939 22.3407 59.7023 22.2767 59.5968 22.2315C59.4913 22.1847 59.3749 22.1613 59.2492 22.1613C59.1141 22.1613 58.9807 22.1863 58.8534 22.2377C58.7246 22.2892 58.6082 22.3594 58.5042 22.4483C58.4002 22.5388 58.3117 22.6449 58.2388 22.7666C58.1674 22.8898 58.1208 23.0177 58.0975 23.1534Z'
        fill='#323366'
      />
      <path
        d='M61.366 21.628H61.9108C61.8922 21.7793 61.8766 21.9166 61.8658 22.0398C61.8534 22.1631 61.8394 22.2863 61.8223 22.408C61.9822 22.1459 62.1793 21.9369 62.4136 21.7809C62.648 21.6249 62.9149 21.5469 63.2145 21.5469C63.5187 21.5469 63.7468 21.6311 63.9005 21.8027C64.0541 21.9743 64.1084 22.1911 64.0696 22.4595L63.6739 24.6761H63.05L63.4007 22.7434C63.4193 22.5874 63.3898 22.4548 63.3107 22.3456C63.2331 22.2364 63.0888 22.1771 62.8823 22.1677C62.738 22.1677 62.5999 22.1927 62.4711 22.2442C62.3422 22.2957 62.2258 22.3643 62.1218 22.4517C62.0179 22.539 61.9294 22.6435 61.8565 22.7652C61.7835 22.8869 61.7338 23.021 61.7075 23.1661L61.439 24.6761H60.8228L61.366 21.628Z'
        fill='#323366'
      />
      <path
        d='M49.3168 20.2837H49.9329L49.6892 21.6331H50.5025L50.4047 22.1868H49.5915L49.1445 24.6734H48.5283L48.9753 22.1868H48.6727L48.7704 21.6331H49.0731L49.3168 20.2837Z'
        fill='#323366'
      />
      <path
        d='M55.0403 20.2837H55.6564L55.4128 21.6331H56.226L56.1283 22.1868H55.315L54.868 24.6734H54.2518L54.6988 22.1868H54.3962L54.494 21.6331H54.7966L55.0403 20.2837Z'
        fill='#323366'
      />
      <path
        d='M13.5102 7.34722H12.222C11.9318 7.34722 11.7129 7.10855 11.7362 6.81684L11.9426 5.52208C12.0202 5.23037 12.222 4.9917 12.5138 4.9917H13.7756C14.0658 4.9917 14.2846 5.23037 14.2613 5.52208L14.0829 6.81684C14.0037 7.1335 13.8004 7.34722 13.5102 7.34722Z'
        fill='#323366'
      />
      <path
        d='M3.33975 7.34722H2.05157C1.76134 7.34722 1.5425 7.10855 1.56579 6.81684L1.7722 5.52208C1.84981 5.23037 2.05157 4.9917 2.34335 4.9917H3.60514C3.89537 4.9917 4.11421 5.23037 4.09092 5.52208L3.91244 6.81684C3.83329 7.1335 3.62997 7.34722 3.33975 7.34722Z'
        fill='#323366'
      />
      <path
        d='M6.73019 7.34722H5.44201C5.15178 7.34722 4.93295 7.10855 4.95623 6.81684L5.16265 5.52208C5.24025 5.23037 5.44201 4.9917 5.73379 4.9917H6.99559C7.28581 4.9917 7.50465 5.23037 7.48137 5.52208L7.30289 6.81684C7.22373 7.1335 7.02042 7.34722 6.73019 7.34722Z'
        fill='#323366'
      />
      <path
        d='M10.1197 7.34722H8.83154C8.54131 7.34722 8.32247 7.10855 8.34575 6.81684L8.55217 5.52208C8.62977 5.23037 8.83154 4.9917 9.12332 4.9917H10.3851C10.6753 4.9917 10.8942 5.23037 10.8709 5.52208L10.6924 6.81684C10.6133 7.1335 10.4099 7.34722 10.1197 7.34722Z'
        fill='#323366'
      />
      <path
        d='M11.9439 17.1734H10.6557C10.3655 17.1734 10.1466 16.9347 10.1699 16.643L10.3763 15.3483C10.4539 15.0565 10.6557 14.8179 10.9475 14.8179H12.2093C12.4995 14.8179 12.7183 15.0565 12.6951 15.3483L12.5166 16.643C12.4374 16.9597 12.2341 17.1734 11.9439 17.1734Z'
        fill='#323366'
      />
      <path
        d='M1.77566 17.1734H0.487481C0.197253 17.1734 -0.021582 16.9347 0.00169835 16.643L0.208118 15.3483C0.285719 15.0565 0.487482 14.8179 0.779263 14.8179H2.04106C2.33129 14.8179 2.55012 15.0565 2.52684 15.3483L2.34836 16.643C2.26921 16.9597 2.06589 17.1734 1.77566 17.1734Z'
        fill='#323366'
      />
      <path
        d='M5.16616 17.1734H3.87798C3.58776 17.1734 3.36892 16.9347 3.3922 16.643L3.59862 15.3483C3.67622 15.0565 3.87798 14.8179 4.16976 14.8179H5.43156C5.72179 14.8179 5.94062 15.0565 5.91734 15.3483L5.73886 16.643C5.65971 16.9597 5.45639 17.1734 5.16616 17.1734Z'
        fill='#323366'
      />
      <path
        d='M8.55538 17.1734H7.26721C6.97698 17.1734 6.75814 16.9347 6.78142 16.643L6.98784 15.3483C7.06544 15.0565 7.2672 14.8179 7.55899 14.8179H8.82078C9.11101 14.8179 9.32985 15.0565 9.30657 15.3483L9.12808 16.643C9.04893 16.9597 8.84561 17.1734 8.55538 17.1734Z'
        fill='#323366'
      />
      <path
        d='M12.4663 13.9024H11.1781C10.8879 13.9024 10.669 13.6637 10.6923 13.372L10.8987 12.0773C10.9763 11.7855 11.1781 11.5469 11.4699 11.5469H12.7317C13.0219 11.5469 13.2407 11.7855 13.2175 12.0773L13.039 13.372C12.9598 13.6887 12.7565 13.9024 12.4663 13.9024Z'
        fill='#323366'
      />
      <path
        d='M2.29812 13.9024H1.00994C0.719714 13.9024 0.500879 13.6637 0.524159 13.372L0.730579 12.0773C0.80818 11.7855 1.00994 11.5469 1.30172 11.5469H2.56352C2.85375 11.5469 3.07258 11.7855 3.0493 12.0773L2.87082 13.372C2.79167 13.6887 2.58835 13.9024 2.29812 13.9024Z'
        fill='#323366'
      />
      <path
        d='M5.68862 13.9024H4.40044C4.11022 13.9024 3.89138 13.6637 3.91466 13.372L4.12108 12.0773C4.19868 11.7855 4.40045 11.5469 4.69223 11.5469H5.95402C6.24425 11.5469 6.46308 11.7855 6.4398 12.0773L6.26132 13.372C6.18217 13.6887 5.97885 13.9024 5.68862 13.9024Z'
        fill='#323366'
      />
      <path
        d='M9.07906 13.9024H7.79089C7.50066 13.9024 7.28182 13.6637 7.3051 13.372L7.51152 12.0773C7.58912 11.7855 7.79089 11.5469 8.08267 11.5469H9.34446C9.63469 11.5469 9.85352 11.7855 9.83024 12.0773L9.65176 13.372C9.5726 13.6887 9.36929 13.9024 9.07906 13.9024Z'
        fill='#323366'
      />
      <path
        d='M12.9872 10.6202H11.699C11.4088 10.6202 11.19 10.3815 11.2132 10.0898L11.4197 8.79503C11.4973 8.50332 11.699 8.26465 11.9908 8.26465H13.2526C13.5428 8.26465 13.7617 8.50332 13.7384 8.79503L13.5599 10.0898C13.4808 10.4065 13.2774 10.6202 12.9872 10.6202Z'
        fill='#323366'
      />
      <path
        d='M2.81826 10.6202H1.53008C1.23986 10.6202 1.02102 10.3815 1.0443 10.0898L1.25072 8.79503C1.32832 8.50332 1.53008 8.26465 1.82186 8.26465H3.08366C3.37389 8.26465 3.59272 8.50332 3.56944 8.79503L3.39096 10.0898C3.31181 10.4065 3.10849 10.6202 2.81826 10.6202Z'
        fill='#323366'
      />
      <path
        d='M6.20675 10.6202H4.91857C4.62834 10.6202 4.40951 10.3815 4.43279 10.0898L4.63921 8.79503C4.71681 8.50332 4.91857 8.26465 5.21035 8.26465H6.47215C6.76238 8.26465 6.98121 8.50332 6.95793 8.79503L6.77945 10.0898C6.7003 10.4065 6.49698 10.6202 6.20675 10.6202Z'
        fill='#323366'
      />
      <path
        d='M9.59701 10.6202H8.30883C8.0186 10.6202 7.79977 10.3815 7.82305 10.0898L8.02947 8.79503C8.10707 8.50332 8.30883 8.26465 8.60061 8.26465H9.86241C10.1526 8.26465 10.3715 8.50332 10.3482 8.79503L10.1697 10.0898C10.0906 10.4065 9.88724 10.6202 9.59701 10.6202Z'
        fill={color1}
      />
      <path
        d='M54.8171 2.58953H53.4017C53.0835 2.58953 52.843 2.32746 52.8678 2.0061L53.0944 0.583425C53.1797 0.263633 53.4017 0 53.7214 0H55.1074C55.4255 0 55.6661 0.262074 55.6413 0.583425L55.4457 2.0061C55.3588 2.35553 55.1353 2.58953 54.8171 2.58953Z'
        fill={color1}
      />
    </svg>
  );
};

export default NutrimeroLogo;
