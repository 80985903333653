import React from 'react';

import pallete from '../../pallete';

const Message = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <path {...st0} d='M30,10.6L17.5,17c-1,0.5-2.1,0.5-3.1,0L2,10.6' />
        <path
          {...st1}
          d='M28.1,26H3.9c-1.1,0-2-0.9-2-2V8c0-1.1,0.9-2,2-2h24.1c1.1,0,2,0.9,2,2v16C30,25.1,29.1,26,28.1,26z'
        />
      </g>
    </svg>
  );
};

export default Message;
