import React from 'react';

import pallete from '../../pallete';

const Templates = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <path
          {...st0}
          d='M27.6,30.8H4.4c-1.2,0-2.2-1-2.2-2.2V3.4c0-1.2,1-2.2,2.2-2.2h23.2c1.2,0,2.2,1,2.2,2.2v25.3
      C29.8,29.8,28.8,30.8,27.6,30.8z'
        />
        <line {...st0} x1='2.2' y1='5.8' x2='29.8' y2='5.8' />
        <path
          {...st1}
          d='M23.8,13.5H8.2c-1.1,0-1.9-0.9-1.9-1.9v-0.2c0-1.1,0.9-1.9,1.9-1.9h15.6c1.1,0,1.9,0.9,1.9,1.9v0.2
      C25.8,12.6,24.9,13.5,23.8,13.5z'
        />
        <rect x='6.2' y='17' {...st1} width='9.1' height='10.1' />
        <rect x='19.6' y='17' {...st1} width='6.1' height='3' />
        <rect x='19.6' y='24.3' {...st1} width='6.1' height='2.9' />
      </g>
    </svg>
  );
};

export default Templates;
