import React from 'react';

import pallete from '../../pallete';

const Ingredients = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
  className,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
      className={className}
    >
      <g>
        <path
          {...st0}
          d='M21.8,15.1C21.8,15.2,21.8,15.2,21.8,15.1c0.7,0.1,1.5,0.2,2.3,0.2c3.3,0,6.5-1.1,6.5-2.4
		c0-1.2-2.9-2.2-5.9-2.4c0.1-0.1,0.1-0.1,0.2-0.2c2.3-2.3,3.4-5,2.5-5.9c-0.9-0.9-3.6,0.2-5.9,2.5c-0.1,0.1-0.1,0.1-0.2,0.2
		c-0.1-3-1.1-5.9-2.4-5.9c-1.3,0-2.4,3.2-2.4,6.5c0,0.8,0.1,1.6,0.2,2.3'
        />
        <path
          {...st1}
          d='M20.5,19.9c-10.1,8.7-17,12-18.7,10.3c-1.6-1.7,1.6-8.6,10.3-18.7c1.8-2.1,4.5-2,6.7-0.3
		c0.7,0.5,1.4,1.2,1.9,1.9C22.5,15.4,22.6,18,20.5,19.9z'
        />
        <g>
          <line {...st0} x1='14.3' y1='12.9' x2='15.9' y2='14.4' />
          <line {...st0} x1='6.2' y1='24.3' x2='7.7' y2='25.8' />
          <line {...st0} x1='15.2' y1='17.4' x2='16.7' y2='18.9' />
          <line {...st0} x1='9.7' y1='22.5' x2='11.2' y2='24' />
          <line {...st0} x1='10.4' y1='18.3' x2='12' y2='19.9' />
          <line {...st0} x1='13.7' y1='21.6' x2='14' y2='21.9' />
        </g>
      </g>
    </svg>
  );
};

export default Ingredients;
