import { AppBar, Badge, Box, IconButton, InputBase, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { BASE_URL, COMPANY_ROUTES, ROUTES } from '../../../app/config/constants';
import { AvatarMenuOPtions, CompanyOptions, LanguageMenuOptions } from '../../../app/config/menuOptions';
import CompaniesDropdown from '../../../components/UI/CompaniesDropdown/CompaniesDropdown';
import CustomDropdown from '../../../components/UI/CustomDropdown/CustomDropdown';
import Flex from '../../../components/UI/Flex/Flex';
import Typo from '../../../components/UI/Typo/Typo';
import { useUpdateUser } from '../../../graphql';
import { useDispatch, useStore } from '../../../hooks';
import { useReduxDispatch } from '../../../redux/helpers';
import { setCurrentLocale } from '../../../redux/reducers/language';
import fontWeights from '../../../theme/fontWeights';
import AvatarShape from '../../../theme/icons/AvatarShape';
import ChevronDown from '../../../theme/icons/ChevronDown';
import NutrimeroLogo from '../../../theme/icons/NutrimeroLogo';
import NutrimeroLogoUnAuth from '../../../theme/icons/NutrimeroLogoUnAuth';
import SearchGlass from '../../../theme/icons/SearchGlass';
import pallete from '../../../theme/pallete';
import useStyles from './styles';

const TopNavBar = (props) => {
  const { isAuth, currentLanguage, companySelected, user } = useStore();
  const currentSpace = localStorage.getItem('space');
  const [notifications] = useState([1]);
  const location = useLocation();
  // Authorization process
  const registrationPages =
    location.pathname === ROUTES.login ||
    location.pathname === ROUTES.registrationStep2DefineSpace ||
    location.pathname === ROUTES.registrationStep1Back ||
    location.pathname === ROUTES.registrationStep3aGetOneTimePassword ||
    location.pathname === ROUTES.registrationStep3aValidationPassword ||
    location.pathname === ROUTES.spaceChoice ||
    location.pathname === ROUTES.createDB;

  const { push } = useHistory();
  const bigScreen = useMediaQuery('@media(min-width:1280px)') && !registrationPages;
  const [updateUser] = useUpdateUser();
  const classes = useStyles({
    larger: bigScreen,
    isHome: location.pathname.includes(COMPANY_ROUTES.Roof),
  });

  const dispatch = useDispatch();
  const reduxDispatch = useReduxDispatch();
  const { formatMessage } = useIntl();
  const [language, setLanguage] = useState((user && user.languageId) || localStorage.getItem('language'));
  const [isMounted, setIsMounted] = useState(false);

  const [activeIcon, setActiveIcon] = useState(
    CompanyOptions.find((company) => company.value === companySelected) || CompanyOptions[0],
  );

  const CompIcon = activeIcon.icon;
  const handleLanguageChange = async (value) => {
    reduxDispatch(setCurrentLocale(value));
    setLanguage(value);
    if (user) {
      await updateUser({
        variables: {
          input: {
            languageId: value,
          },
        },
      });
    }
  };
  const handleCompanyChange = (company) => {
    setActiveIcon(company);
    dispatch({ type: 'SET_COMPANY', payload: company.value });
  };
  const handleProfileMenuChange = (v) => {
    dispatch({ type: 'SET_COMPANY', payload: 'Roof' });
    push(`/root${v}`);
  };

  useEffect(() => {
    if (language !== localStorage.getItem('language')) {
      dispatch({
        type: 'SET_LANGUAGE',
        payload: {
          label: LanguageMenuOptions.find((e) => e.value === language)?.title || user?.languageId || 'EN',
          value: language,
        },
      });
      setIsMounted(true);
    }
  }, [dispatch, isMounted, language]);

  return (
    <AppBar elevation={0} position='absolute' className={classes.appBar} {...props}>
      <Toolbar className={classes.toolbar} disableGutters>
        <Box
          className={classes.homeLink}
          onClick={async () => {
            await dispatch({ type: 'SET_COMPANY', payload: 'Roof' });
            push(`${COMPANY_ROUTES.Roof}${ROUTES.home}`);
          }}
        >
          {isAuth && user?.databasePreference && !registrationPages ? (
            <NutrimeroLogo
              color={location.pathname.includes(COMPANY_ROUTES.Roof) ? pallete.secondary1 : pallete.backgroundWhite}
            />
          ) : (
            <NutrimeroLogoUnAuth />
          )}
        </Box>

        {(!isAuth || registrationPages) && (
          <Typo color='backgroundWhite' fontSize='ml' fontWeight={fontWeights.BOLD} className={classes.slogan}>
            {formatMessage({ id: 'unauth_logo_title' })}
          </Typo>
        )}

        {isAuth && user?.databasePreference && !registrationPages && currentSpace && (
          <>
            <Flex>
              <CompaniesDropdown content={CompanyOptions} setValue={handleCompanyChange}>
                <Flex>
                  <CompIcon selected />
                  <IconButton className={classes.chevy}>
                    <ChevronDown color={pallete.primary} />
                  </IconButton>
                </Flex>
              </CompaniesDropdown>
            </Flex>

            <Box flexGrow={1} />
            <Box className={classes.searchInputContainer}>
              <InputBase
                className={classes.searchInput}
                style={{ width: bigScreen ? 400 : 290 }}
                placeholder={formatMessage({ id: 'roof_search_empty' })}
              />
              <SearchGlass />
            </Box>
            <IconButton color='inherit'>
              <Badge badgeContent={notifications.length} className={classes.badge} variant='dot'>
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <Typography className={classes.textLink}>{formatMessage({ id: 'notifications' })}</Typography>

            <CustomDropdown content={LanguageMenuOptions} compact setValue={handleLanguageChange}>
              <Flex row alignCenter>
                <Typography className={classNames(classes.textLink)} style={{ marginRight: -5 }}>
                  {currentLanguage.label}
                </Typography>
                <IconButton>
                  <ChevronDown />
                </IconButton>
              </Flex>
            </CustomDropdown>
            <Box marginLeft={!bigScreen ? '-5px' : '15px'}>
              <CustomDropdown
                wide
                content={AvatarMenuOPtions}
                setValue={handleProfileMenuChange}
                className={classes.userProfile}
              >
                <Flex row alignCenter>
                  <AvatarShape href={`${BASE_URL}/${user?.avatar}`} />
                  <Box display='flex' flexDirection='column' marginLeft='5px' marginRight='5px'>
                    <Typo fontSize='sm' color='backgroundWhite' className={classes.userName}>
                      {`${user?.firstName} ${user?.lastName}`}
                    </Typo>
                    <Typo fontSize='xs' color='backgroundWhite' className={classes.userEmail}>
                      {user?.email}
                    </Typo>
                  </Box>
                  <IconButton classes={{ root: classes.userProfileArrow }}>
                    <ChevronDown />
                  </IconButton>
                </Flex>
              </CustomDropdown>
            </Box>
          </>
        )}
        {isAuth && (!user?.databasePreference || !currentSpace) && (
          <Flex flex={1} row fend alignCenter>
            <CustomDropdown content={LanguageMenuOptions} compact setValue={handleLanguageChange}>
              <Flex row alignCenter>
                <Typography className={classNames(classes.textLink)} style={{ marginRight: -5 }}>
                  {currentLanguage.label}
                </Typography>
                <IconButton>
                  <ChevronDown />
                </IconButton>
              </Flex>
            </CustomDropdown>
            {user.status === 'active' && (
              <Box marginLeft={!bigScreen ? '-5px' : '15px'}>
                <CustomDropdown
                  wide
                  content={AvatarMenuOPtions.slice(-1)}
                  setValue={handleProfileMenuChange}
                  className={classes.userProfile}
                >
                  <Flex row alignCenter>
                    <AvatarShape href={`${BASE_URL}/${user?.avatar}`} />
                    <Box display='flex' flexDirection='column' marginLeft='5px' marginRight='5px'>
                      <Typo fontSize='sm' color='backgroundWhite' className={classes.userName}>
                        {`${user?.firstName} ${user?.lastName}`}
                      </Typo>
                      <Typo fontSize='xs' color='backgroundWhite' className={classes.userEmail}>
                        {user?.email}
                      </Typo>
                    </Box>
                    <IconButton classes={{ root: classes.userProfileArrow }}>
                      <ChevronDown />
                    </IconButton>
                  </Flex>
                </CustomDropdown>
              </Box>
            )}
          </Flex>
        )}
        {!isAuth && (
          <Box justifySelf='flex-end' marginLeft='auto' width='auto'>
            <CustomDropdown content={LanguageMenuOptions} compact setValue={handleLanguageChange}>
              <Flex row alignCenter>
                <Typography className={classNames(classes.textLink)} style={{ marginRight: -5 }}>
                  {currentLanguage.label}
                </Typography>
                <IconButton>
                  <ChevronDown />
                </IconButton>
              </Flex>
            </CustomDropdown>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;
