import { useMutation } from '@apollo/client';

import { FOOD_SCALE_URL } from '../../app/config/constants';
import * as MUTATIONS from '../mutations.gql';

export const useCreateUser = () => {
  return useMutation(MUTATIONS.CreateUserGQL);
};
export const useUpdateUser = () => {
  return useMutation(MUTATIONS.UpdateUserGQL);
};
export const useSetUserDBPref = () => {
  return useMutation(MUTATIONS.SetDBPreference);
};
export const useUpdateEmail = () => {
  return useMutation(MUTATIONS.UpdateEmailGQL);
};
export const useSetEmail = () => {
  return useMutation(MUTATIONS.setEmailGQL);
};
export const useUpdateNotificationSettings = () => {
  return useMutation(MUTATIONS.UpdateNotificationSettingsGQL);
};
export const useChangePassword = () => {
  return useMutation(MUTATIONS.ChangePasswordGQL);
};
export const useCreateDataBase = () => {
  return useMutation(MUTATIONS.CreateDabaseGQL);
};

export const useUploadImage = () => {
  return useMutation(MUTATIONS.AvatarUpload);
};

export const useUploadFileToDB = () => {
  return useMutation(MUTATIONS.uploadFileToDataBaseGQL);
};

export const useDeleteAvatar = () => {
  return useMutation(MUTATIONS.DeleteAvatarGQL);
};
export const useUserRequestAccess = () => {
  return useMutation(MUTATIONS.userRequestDBAccessGQL);
};
export const useOwnerRequestAccess = () => {
  return useMutation(MUTATIONS.ownerRequestDBAccessGQL);
};

export const useUserAcceptRequestAccess = () => {
  return useMutation(MUTATIONS.userAcceptRequestDBAccessGQL);
};

export const useOwnerAcceptRequestAccess = () => {
  return useMutation(MUTATIONS.ownerAcceptRequestDBAccessGQL);
};

export const useUserDeleteRequestAccess = () => {
  return useMutation(MUTATIONS.userDeleteRequestDBAccessGQL);
};

export const useOwnerDeleteRequestAccess = () => {
  return useMutation(MUTATIONS.ownerDeleteRequestDBAccessGQL);
};

export const useGetOtpCode = () => {
  return useMutation(MUTATIONS.GetOptCode);
};

export const useValidateOtpCode = () => {
  return useMutation(MUTATIONS.ValidateOptCode);
};

export const useCreateNewPassword = () => {
  return useMutation(MUTATIONS.setNewPasswordGQL);
};

export const useRestorePassword = () => {
  return useMutation(MUTATIONS.RestorePasswordGQL);
};

export const useSetSettingsBoughtItems = () => {
  return useMutation(MUTATIONS.setSettingsBoughtItemsGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useSetSettingsProducts = () => {
  return useMutation(MUTATIONS.setSettingsProductsGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useSetSettingsIngridients = () => {
  return useMutation(MUTATIONS.setSettingsIngridientsGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useSetSettingsRecipes = () => {
  return useMutation(MUTATIONS.setSettingsResipesGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useCreateTag = (options) => {
  return useMutation(MUTATIONS.createTagGQL, options);
};

export const useEditTag = () => {
  return useMutation(MUTATIONS.editTagGQL);
};

export const useMergeTag = () => {
  return useMutation(MUTATIONS.mergeTagGQL);
};

export const useDeleteTags = () => {
  return useMutation(MUTATIONS.deleteTagsGQL);
};

export const useCreateBoughtItem = () => {
  return useMutation(MUTATIONS.createBoughtItemGQL);
};

export const useUpdateBoughtItem = () => {
  return useMutation(MUTATIONS.updateBoughtItemGQL);
};

export const useGetCrossAuthToken = () => {
  return useMutation(MUTATIONS.getCrossAuthTokenGQL, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      //ToDo Oleg make Loader
      const token = response?.getCrossAuthToken?.token;
      if (token) {
        window.location = `${FOOD_SCALE_URL}/login-to-space?t=${token}`;
      }
    },
  });
};

export const useSetDatabaseSettings = () => {
  return useMutation(MUTATIONS.setDatabaseSettings);
};

export const useSaveIngredientGroupsSettings = () => {
  return useMutation(MUTATIONS.saveIngredientGroupsSettings);
};

export const useCreateIngredientGroup = (options) => {
  return useMutation(MUTATIONS.createIngredientGroup, options);
};

export const useUpdateIngredientGroup = (options) => {
  return useMutation(MUTATIONS.updateIngredientGroup, options);
};

export const useDeleteIngredientGroup = (options) => {
  return useMutation(MUTATIONS.deleteIngredientGroup, options);
};

export const useDeleteBoughtItems = (options) => {
  return useMutation(MUTATIONS.deleteBoughtItems, options);
};

export const useMergeIngredientGroups = (options) => {
  return useMutation(MUTATIONS.mergeIngredientGroups, options);
};

export const useCreateFID = (options) => {
  return useMutation(MUTATIONS.createFID, options);
};
