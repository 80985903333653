import React from 'react';

import pallete from '../../pallete';

const Jobs = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <polyline {...st0} points='30.8,14.8 16,19.5 1.2,14.8 	' />
        <path
          {...st1}
          d='M27.4,28.3H4.6c-1.9,0-3.4-1.5-3.4-3.4V10.6c0-1.9,1.5-3.4,3.4-3.4h22.9c1.9,0,3.4,1.5,3.4,3.4v14.4
		C30.8,26.8,29.3,28.3,27.4,28.3z'
        />
        <path
          {...st1}
          d='M20.5,7.2h-9V5.4c0-1,0.8-1.7,1.7-1.7h5.6c1,0,1.7,0.8,1.7,1.7V7.2z'
        />
      </g>
    </svg>
  );
};

export default Jobs;
