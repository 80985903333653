import React from 'react';

import pallete from '../../pallete';

const Products = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <g>
          <path {...st0} d='M3.6,11.1V9.2c0-1,0.7-1.8,1.6-1.8h2.2' />
          <path {...st0} d='M3.6,20.9v1.9c0,1,0.7,1.8,1.6,1.8h2.2' />
          <path {...st0} d='M24.6,7.4h2.2c0.9,0,1.6,0.8,1.6,1.8v1.9' />
          <path {...st0} d='M28.4,20.9v1.9c0,1-0.7,1.8-1.6,1.8h-2.2' />
        </g>
        <g>
          <line {...st1} x1='7.4' y1='11.1' x2='7.4' y2='20.9' />
          <line {...st1} x1='10.9' y1='11.1' x2='10.9' y2='18.1' />
          <line {...st1} x1='14.3' y1='11.1' x2='14.3' y2='18.1' />
          <line {...st1} x1='10.9' y1='20.6' x2='10.9' y2='20.9' />
          <line {...st1} x1='14.3' y1='20.6' x2='14.3' y2='20.9' />
          <line {...st1} x1='21.1' y1='20.6' x2='21.1' y2='20.9' />
          <line {...st1} x1='17.7' y1='11.1' x2='17.7' y2='20.9' />
          <line {...st1} x1='21.1' y1='11.1' x2='21.1' y2='18.1' />
          <line {...st1} x1='24.6' y1='11.1' x2='24.6' y2='20.9' />
        </g>
      </g>
    </svg>
  );
};

export default Products;
