import { Box, Typography, useMediaQuery } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import DrawerNavTree from '../../../app/config/drawerNavTree';
import { useStore } from '../../../hooks';
import ChevronRightIcon from '../../../theme/icons/ChevronRight';
import QuestionMarkInCircle from '../../../theme/icons/QuestionMarkInCircle';
import pallete from '../../../theme/pallete';
import DrawerMenuItem from '../MenuItem/DrawerMenuItem';
import useStyles from './styles';

export default function MiniDrawer() {
  const bigScreen = useMediaQuery('@media(min-width:1280px)');
  const smallScreen = useMediaQuery('@media(min-width:1024px)');
  const classes = useStyles({ small: smallScreen, big: bigScreen });
  const [open, setOpen] = React.useState(false);
  const { companySelected } = useStore();
  const [expand, setExpand] = useState('');

  useEffect(() => {
    if (bigScreen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    return () => setExpand('');
  }, [bigScreen]);
  const handleClick = () => {
    if (!bigScreen) {
      setOpen(!open);
    }
  };

  return (
    <Drawer
      variant='permanent'
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {!bigScreen && (
        <>
          <div className={classes.toolbar}>
            <IconButton onClick={handleClick} className={classes.iconButton}>
              {!open ? (
                <Box className={classes.iconContainer}>
                  <ChevronRightIcon />
                  <ChevronRightIcon />
                </Box>
              ) : (
                <Box className={classes.iconContainerOpen}>
                  <ChevronRightIcon color={pallete.primary} />
                  <ChevronRightIcon color={pallete.primary} />
                </Box>
              )}
            </IconButton>
          </div>
          <Divider />
        </>
      )}

      <List style={{ padding: 0 }}>
        {DrawerNavTree[companySelected].map((listItem, index) => {
          return (
            <React.Fragment key={index}>
              {!open && <Divider classes={{ root: classes.drawerDivider }} />}
              <DrawerMenuItem
                key={index}
                listItem={listItem}
                openDrawer={setOpen}
                isDrawerOpen={open}
                expand={expand}
                setExpand={setExpand}
              />
            </React.Fragment>
          );
        })}
      </List>
      <Box
        className={classNames(
          classes.helpButton,
          !open && classes.helpButtonVertical,
        )}
      >
        <QuestionMarkInCircle />
        <Typography>Help</Typography>
      </Box>
    </Drawer>
  );
}
