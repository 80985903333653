import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useGetDatabaseSettings } from '../../graphql';
import { useReduxDispatch } from '../../redux/helpers';
import { setSpaceSettings } from '../../redux/reducers/space';
import { spaceIdSelector } from '../../redux/selectors';

const SpaceSettings = () => {
  const reduxDispatch = useReduxDispatch();
  const spaceId = useSelector(spaceIdSelector);

  const [getDbSettings] = useGetDatabaseSettings({
    variables: { databaseId: Number(spaceId) },
    onCompleted: (data) => {
      if (data?.getDatabaseSettings) reduxDispatch(setSpaceSettings(data?.getDatabaseSettings));
    },
  });

  // Fetching space settings on every space id change and saving data to redux store
  useEffect(() => {
    if (spaceId) {
      getDbSettings();
    }
  }, [getDbSettings, spaceId]);

  return null;
};

export default SpaceSettings;
