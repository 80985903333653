import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useGetTranslationsByLanguage } from '../../graphql';
import { useReduxDispatch } from '../../redux/helpers';
import { setTranslations } from '../../redux/reducers/language';
import { currentLocaleSelector } from '../../redux/selectors';

const TranslationsProvider = ({ children }) => {
  const reduxDispatch = useReduxDispatch();
  const currentLocale = useSelector(currentLocaleSelector);

  // Here we save our translations file to redux store
  const handleLanguageUpdate = useCallback(
    (languagesData) => {
      if (languagesData?.translations) {
        reduxDispatch(setTranslations(languagesData.translations));
      }
    },
    [reduxDispatch],
  );

  useGetTranslationsByLanguage(
    {
      languageCode: currentLocale,
    },
    {
      onCompleted: handleLanguageUpdate,
    },
  );

  return children;
};

export default TranslationsProvider;
