export const SHORT = {
  'en-GB': 'EN',
  'de-DE': 'DE',
};

export const NOTIFICATIONS = {
  'all-notification': 'All Notifications',
};

const LOCALES = {
  GERMAN: 'de-DE',
  ENGLISH: 'en-GB',
};

export default LOCALES;
