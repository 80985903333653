import React from 'react';

import pallete from '../../pallete';

const History = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <path
        {...st0}
        d='M4.6,5.6c1.5-1.5,3.6-2.4,5.8-2.4h11.2c4.5,0,8.2,3.7,8.2,8.2v11.2c0,4.5-3.7,8.2-8.2,8.2H10.4
	c-4.5,0-8.2-3.7-8.2-8.2v-8.1'
      />
      <polyline {...st1} points='16,10 16,16.9 21.7,22.6 ' />
      <polyline {...st0} points='8.6,6.4 4.1,5.8 4.8,1.3 ' />
    </svg>
  );
};

export default History;
