import { makeStyles } from '@material-ui/core/styles';

import pallete from '../../../theme/pallete';

export default makeStyles(() => ({
  activeMenuItem: {
    height: 52,
    background: pallete.backgroundWhite,
    color: pallete.secondary1,
    padding: '0 8px 0 10px',
    '& svg': {
      color: pallete.secondary1,
    },
    '&:hover': {
      background: pallete.backgroundWhite,
      filter: 'brightness(95%)',
      color: pallete.primary,
      '& svg': {
        color: pallete.primary,
      },
    },
  },
  activeSubMenuItem: {
    height: 52,
    background: pallete.secondary1,
    color: pallete.backgroundWhite,
    '& svg': {
      color: pallete.backgroundWhite,
    },
    '&:hover': {
      background: pallete.secondary1,
      filter: 'brightness(95%)',
      color: pallete.primary,
      '& svg': {
        color: pallete.primary,
      },
    },
  },
  activeMenuItemExpand: {
    height: 52,
    background: pallete.primary,
    color: pallete.backgroundWhite,
    '& svg': {
      color: pallete.backgroundWhite,
    },
    '&:hover': {
      background: pallete.primary,
      color: pallete.secondary1,
      filter: 'brightness(95%)',
      '& svg': {
        color: pallete.secondary1,
      },
    },
  },
  activeMenuItemIcon: {
    // color: 'white',
  },
  extraPadding: {
    paddingLeft: '2rem',
  },
  menuItem: {
    height: 52,
    background: pallete.primary,
    color: pallete.backgroundWhite,
    '& svg': {
      color: pallete.backgroundWhite,
    },
    '&:hover': {
      // background: pallete.backgroundWhite,
      color: pallete.secondary1,
      '& svg': {
        color: pallete.secondary1,
      },
    },
    padding: '0 8px 0 10px',
  },
  subMenuItem: {
    height: 52,
    background: pallete.backgroundWhite,
    color: pallete.secondary1,
    borderBottom: `1px solid ${pallete.grey3}`,
    '& svg': {
      color: pallete.secondary1,
    },
    '&:hover': {
      // background: pallete.backgroundWhite,
      color: pallete.primary,
      '& svg': {
        color: pallete.primary,
      },
    },
  },
  menuItemExpand: {
    height: 52,
    background: pallete.backgroundWhite,
    color: pallete.primary,
    borderBottom: `1px solid ${pallete.grey3}`,
    '& svg': {
      color: pallete.primary,
    },
    '&:hover': {
      color: pallete.secondary1,
      '& svg': {
        color: pallete.secondary1,
      },
    },
  },
}));
