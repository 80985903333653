import React from 'react';

import pallete from '../pallete';

const SearchGlass = ({
  color = pallete.textPrimary,
  height = 13,
  width = 15,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.7769 7.84981H10.1571L9.93738 7.66756C10.7063 6.89806 11.1692 5.89906 11.1692 4.8123C11.1692 2.38905 8.886 0.424805 6.06923 0.424805C3.25246 0.424805 0.969233 2.38905 0.969233 4.8123C0.969233 7.23556 3.25246 9.19981 6.06923 9.19981C7.33246 9.19981 8.49369 8.80156 9.38815 8.14006L9.6 8.32906V8.86231L13.5231 12.2306L14.6922 11.2248L10.7769 7.84981ZM6.06923 7.84981C4.11554 7.84981 2.53846 6.49306 2.53846 4.8123C2.53846 3.13156 4.11554 1.7748 6.06923 1.7748C8.02292 1.7748 9.6 3.13156 9.6 4.8123C9.6 6.49306 8.02292 7.84981 6.06923 7.84981Z'
        fill={color}
      />
    </svg>
  );
};

export default SearchGlass;
