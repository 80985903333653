import React from 'react';

import pallete from '../../pallete';

const Declaration = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <path
          stroke={color}
          fill='none'
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M29.8,19.6L19.6,29.8c-0.6,0.6-1.6,0.6-2.2,0L2.2,14.6c-0.3-0.3-0.5-0.8-0.5-1.2l0.7-9.5
		c0.1-0.8,0.7-1.4,1.4-1.4l9.5-0.7c0.5,0,0.9,0.1,1.2,0.5l15.2,15.2C30.4,18,30.4,19,29.8,19.6z'
        />
        <circle
          stroke={color2}
          fill='none'
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          cx='7.8'
          cy='7.8'
          r='0.8'
        />
        <line
          stroke={color2}
          fill='none'
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          x1='9.7'
          y1='16.3'
          x2='16.3'
          y2='9.7'
        />
        <line
          stroke={color2}
          fill='none'
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          x1='13.4'
          y1='20'
          x2='20'
          y2='13.4'
        />
        <line
          stroke={color2}
          fill='none'
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          x1='17.1'
          y1='23.8'
          x2='23.8'
          y2='17.1'
        />
      </g>
    </svg>
  );
};

export default Declaration;
