import '../theme/fonts.css';
import 'react-toastify/dist/ReactToastify.css';

import { MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import RenderRoutes from '../components/navigation/RenderRoutes/RenderRoutes';
import SpinnerLogo from '../components/UI/Spinner/SpinnerLogo';
import TooltipContainer from '../components/UI/TooltipContainer/TooltipContainer';
import { useCheckToken, useGetUserDatabases } from '../graphql';
import { getParameterByName, useDispatch, useStore } from '../hooks';
import { LanguageProvider } from '../i18n';
import messages from '../i18n/data';
import MainLayout from '../layout/MainLayout/MainLayout';
import theme from '../theme';
import { ROUTES } from './config/constants';
import routes from './config/routes';
import TranslationsProvider from './providers/TranslationsProvider';
import SpaceSettingsService from './services/SpaceSettings';

const App = () => {
  const { currentLanguage, hasToken, isAuth, loading } = useStore();
  const [checkToken, { data, client }] = useCheckToken();
  const [getDataBases, { data: dbs, called }] = useGetUserDatabases();

  const dispatch = useDispatch();

  const { search } = useLocation();
  const { push } = useHistory();
  let resetToken;

  useEffect(() => {
    dispatch({ type: 'START_LOADING' });
    if (hasToken) {
      checkToken();
    } else {
      client && client.clearStore();
      dispatch({ type: 'LOGOUT' });
      dispatch({ type: 'FINISH_LOADING' });
    }
  }, [checkToken, dispatch, hasToken]);

  useEffect(() => {
    if (dbs && !called) {
      getDataBases();
    }
  }, [data, dbs, dispatch, getDataBases]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resetToken = getParameterByName('t', search);
    if (resetToken) {
      localStorage.setItem('resetToken', resetToken);
    }
  }, [resetToken, search]);

  useEffect(() => {
    const RT = localStorage.getItem('resetToken');
    if (isAuth && RT) {
      push(`/root${ROUTES.myProfile}`, { rt: RT });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <TranslationsProvider>
          <LanguageProvider locale={currentLanguage.value} messages={messages[currentLanguage.value]}>
            <MainLayout>{loading ? <SpinnerLogo /> : <RenderRoutes routes={routes} />}</MainLayout>
            <TooltipContainer />
          </LanguageProvider>
        </TranslationsProvider>
      </MuiThemeProvider>
      <SpaceSettingsService />
    </React.Fragment>
  );
};

export default App;
