import './index.css';

import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App, { StoreProvider } from './app';
import { client } from './graphql';
import { store } from './redux/store';

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <StoreProvider>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </BrowserRouter>
      </StoreProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);
