import React from 'react';

import pallete from '../../pallete';

const TechnicalData = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <path
          {...st0}
          d='M7.3,27.9l13-14.7c3.2,1.4,6.9,0,8.4-3.2c0.5-1.2,0.7-2.6,0.4-3.9l-3.8,3.8l-3.7-1.2l-1.2-3.7l3.8-3.8
		c-3.4-0.7-6.8,1.4-7.5,4.9c-0.3,1.3-0.1,2.7,0.4,3.9L2.4,23c-1.5,1.3-1.6,3.7-0.2,5.1l0,0C3.6,29.5,6,29.4,7.3,27.9z'
        />
        <circle {...st0} cx='5.3' cy='25' r='0.5' />
      </g>
      <g>
        <path
          {...st1}
          d='M24.2,19.6c0,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.4-0.1-0.8-0.3-1.1-0.5
		c-0.5-0.3-0.8-0.7-1.1-1.2c-0.4-1,0.1-1.8,0.1-1.9C21.9,16,22,16,22.1,16c1.2,0.4,1.9,1,2.2,1.8C24.7,18.7,24.3,19.6,24.2,19.6z'
        />
        <path
          {...st1}
          d='M30.4,23.7c0,1.1-0.4,2.6-1,4c-0.8,1.8-2.1,3.3-3.7,3.3c-0.4,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.7-0.1-1.1,0
		c-0.4,0.1-0.8,0.2-1.1,0.2c-1.5,0-2.8-1.5-3.7-3.3c-0.7-1.4-1-2.9-1-4c0-0.5,0.1-1.7,0.6-2.8c0.6-1.1,1.5-1.7,2.7-1.7
		c1.2,0,2.3,0.4,2.5,0.5c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.5,0,0.7-0.1c0.6-0.2,1.4-0.4,2.4-0.4
		c1.2,0,2.2,0.6,2.7,1.7C30.4,22,30.4,23.2,30.4,23.7z'
        />
      </g>
    </svg>
  );
};

export default TechnicalData;
