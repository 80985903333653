import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import useStyles from './styles';

const Flex = ({ children, onClick, style, className, sx, ...props }) => {
  const classes = useStyles(props);
  return (
    <Box
      className={classNames(classes.flexStyled, className)}
      onClick={onClick}
      style={style}
      sx={sx}
    >
      {children}
    </Box>
  );
};

export default Flex;
