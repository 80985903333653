import SettingsIcon from '@material-ui/icons/Settings';

import {
  BrandedProducts,
  Declaration,
  Export,
  ExternalSpaces,
  Home,
  Ingredients,
  Jobs,
  ManageUserSuggestions,
  Message,
  MySpace,
  Nutritions,
  Products,
  Profile,
  Recepies,
  Settings,
  TechnicalData,
  Templates,
  Tools,
  Translations,
  Wizards,
} from '../../theme/icons/MenuNav';
import History from '../../theme/icons/MenuNav/History';
import { ROUTES } from './constants';

const DrawerNavTree = {
  Roof: [
    {
      label: 'navi_root_1_home',
      icon: Home,
      link: ROUTES.home,
    },
    {
      label: 'navi_root_2_jobs',
      icon: Jobs,
      subMenu: [
        {
          label: 'navi_root_2_1_incomplete_data',
          link: ROUTES.incompleteDataIngredients,
        },
        {
          label: 'navi_root_2_2_tasks',
          link: ROUTES.tasks,
        },
      ],
    },
    {
      label: 'navi_root_3_messages',
      icon: Message,
      link: ROUTES.messages,
    },
    {
      label: 'navi_root_4_tools',
      icon: Tools,
      subMenu: [
        {
          label: 'navi_root_4_1_nutrimero',
          link: ROUTES.tooldNutrimero,
        },
        {
          label: 'navi_root_4_2_declaratio',
          link: ROUTES.toolsDeclaratio,
        },
        {
          label: 'navi_root_4_3_foodscale',
          link: ROUTES.toolsFoodscale,
        },
      ],
    },
    {
      label: 'navi_root_5_my_profile',
      icon: Profile,
      link: ROUTES.myProfile,
    },
    {
      label: 'navi_root_6_my_space',
      icon: MySpace,
      subMenu: [
        {
          label: 'navi_root_6_1_create_space',
          link: ROUTES.mySpaceCreate,
        },
        {
          label: 'navi_root_6_database_settings',
          link: ROUTES.mySpaceDb,
        },
        {
          label: 'navi_root_6_billing_address',
          link: ROUTES.mySpaceBillingA,
        },
        {
          label: 'navi_root_6_subscriptions',
          link: ROUTES.mySpaceSubs,
        },
        {
          label: 'navi_root_6_invited_users',
          link: ROUTES.mySpaceInvUsers,
        },
        {
          label: 'navi_root_6_notifications',
          link: ROUTES.mySpaceNotifications,
        },
        {
          label: 'navi_root_6_tags',
          link: ROUTES.mySpaceTags,
        },
      ],
    },
    {
      label: 'navi_root_7_external_spaces',
      icon: ExternalSpaces,
      subMenu: [
        {
          label: 'navi_root_7_1_spaces_i_belong_to',
          link: ROUTES.extSpacesList,
        },
        {
          label: 'navi_root_7_2_notifications',
          link: ROUTES.extSpacesNotifications,
        },
      ],
    },
    {
      label: 'navi_root_8_print_export',
      icon: Export,
      subMenu: [
        {
          label: 'navi_root_8_1_print_data',
          link: ROUTES.print,
        },
        {
          label: 'navi_root_8_2_export_data',
          link: ROUTES.export,
        },
      ],
    },
    {
      label: 'navi_root_9_settings',
      icon: Settings,
      link: ROUTES.settings,
    },
  ],
  Nutrimero: [
    {
      label: 'navi_nutr_1_home',
      icon: Home,
      link: ROUTES.home,
    },
    {
      label: 'navi_nutr_2_jobs',
      icon: Jobs,
      subMenu: [
        {
          label: 'navi_nutr_2_1_incomplete_data',
          link: ROUTES.incompleteDataIngredients,
        },
        {
          label: 'navi_nutr_2_2_task',
          link: ROUTES.tasks,
        },
      ],
    },
    {
      label: 'navi_nutr_3_ingredients',
      icon: Ingredients,
      subMenu: [
        {
          label: 'navi_nutr_3_1_ingredients_overview',
          link: ROUTES.ingridientsOverview,
        },
        {
          label: 'navi_nutr_3_2_ingredient_groups',
          link: ROUTES.ingridientsGroups,
        },
        {
          label: 'navi_nutr_3_3_bought_item_overview',
          link: ROUTES.boughtItemsOverview,
        },
      ],
    },
    {
      label: 'navi_nutr_4_recipes',
      icon: Recepies,
      subMenu: [
        {
          label: 'navi_nutr_4_1_recipes_overview',
          link: ROUTES.recipesOverview,
        },
        {
          label: 'navi_nutr_4_2_recipe_groups',
          link: ROUTES.recipesGroups,
        },
        {
          label: 'navi_nutr_4_3_recipe_versions',
          link: ROUTES.recipesVersions,
        },
      ],
    },
    {
      label: 'navi_nutr_5_products',
      icon: Products,
      subMenu: [
        {
          label: 'navi_nutr_5_products_overview',
          link: ROUTES.productsOverview,
        },
        { label: 'navi_nutr_5_product_groups', link: ROUTES.productsGroups },
      ],
    },
    {
      label: 'navi_nutr_6_wizards',
      icon: Wizards,
      subMenu: [
        {
          label: 'navi_nutr_6_1_import_recipes',
          link: ROUTES.wizardsImportRecipes,
        },
        {
          label: 'navi_nutr_6_2_import_ingredients',
          link: ROUTES.wizardsImportIngridients,
        },
        {
          label: 'navi_nutr_6_3_replace_ingredients',
          link: ROUTES.wizardsExportIngridients,
        },
      ],
    },
    {
      label: 'navi_nutr_7_print_export',
      icon: Export,
      subMenu: [
        {
          label: 'navi_nutr_7_1_print_data',
          link: ROUTES.print,
        },
        {
          label: 'navi_nutr_7_2_export_data',
          link: ROUTES.export,
        },
        {
          label: 'navi_nutr_7_recipe_exchange',
          link: ROUTES.recipeExchange,
        },
      ],
    },
    {
      label: 'navi_nutr_8_settings',
      icon: Settings,
      link: ROUTES.settings,
    },
  ],
  CookingKids: [
    {
      label: 'navi_nutr_1_home',
      icon: Home,
      link: ROUTES.home,
    },
    {
      label: 'navi_nutr_2_jobs',
      icon: Jobs,
      subMenu: [
        {
          label: 'navi_nutr_2_1_incomplete_data',
          link: ROUTES.incompleteDataIngredients,
        },
        {
          label: 'navi_nutr_2_2_task',
          link: ROUTES.tasks,
        },
      ],
    },
    {
      label: 'navi_nutr_3_ingredients',
      icon: Ingredients,
      subMenu: [
        {
          label: 'navi_nutr_3_1_ingredients_overview',
          link: ROUTES.ingridientsOverview,
        },
        {
          label: 'navi_nutr_3_2_ingredient_groups',
          link: ROUTES.ingridientsGroups,
        },
        {
          label: 'navi_nutr_3_3_bought_item_overview',
          link: ROUTES.boughtItemsOverview,
        },
      ],
    },
    {
      label: 'navi_nutr_4_recipes',
      icon: Recepies,
      subMenu: [
        {
          label: 'navi_nutr_4_1_recipes_overview',
          link: ROUTES.recipesOverview,
        },
        {
          label: 'navi_nutr_4_2_recipe_groups',
          link: ROUTES.recipesGroups,
        },
        {
          label: 'navi_nutr_4_3_recipe_versions',
          link: ROUTES.recipesVersions,
        },
      ],
    },
    {
      label: 'navi_nutr_5_products',
      icon: Products,
      subMenu: [
        {
          label: 'navi_nutr_5_products_overview',
          link: ROUTES.productsOverview,
        },
        { label: 'navi_nutr_5_product_groups', link: ROUTES.productsGroups },
      ],
    },
    {
      label: 'navi_nutr_6_wizards',
      icon: Wizards,
      subMenu: [
        {
          label: 'navi_nutr_6_1_import_recipes',
          link: ROUTES.wizardsImportRecipes,
        },
        {
          label: 'navi_nutr_6_2_import_ingredients',
          link: ROUTES.wizardsImportIngridients,
        },
        {
          label: 'navi_nutr_6_3_replace_ingredients',
          link: ROUTES.wizardsExportIngridients,
        },
      ],
    },
    {
      label: 'navi_nutr_7_print_export',
      icon: Export,
      subMenu: [
        {
          label: 'navi_nutr_7_1_print_data',
          link: ROUTES.print,
        },
        {
          label: 'navi_nutr_7_2_export_data',
          link: ROUTES.export,
        },
        {
          label: 'navi_nutr_7_recipe_exchange',
          link: ROUTES.recipeExchange,
        },
      ],
    },
    {
      label: 'navi_nutr_8_settings',
      icon: Settings,
      link: ROUTES.settings,
    },
  ],
  Declaratio: [
    {
      label: 'navi_decl_1_home',
      icon: Home,
      link: ROUTES.home,
    },
    {
      label: 'navi_decl_2_jobs',
      icon: Jobs,
      subMenu: [
        {
          label: 'navi_decl_2_1_incomplete_data',
          link: ROUTES.incompleteDataIngredients,
        },
        {
          label: 'navi_decl_2_2_tasks',
          link: ROUTES.tasks,
        },
      ],
    },
    {
      label: 'navi_decl_3_declarations',
      icon: Declaration,
      subMenu: [
        {
          label: 'navi_decl_3_1_declarations_overview',
          link: ROUTES.declarationsOverview,
        },
        {
          label: 'navi_decl_3_2_declaration_groups',
          link: ROUTES.declarationsGroup,
        },
      ],
    },
    {
      label: 'navi_decl_4_templates',
      icon: Templates,
      subMenu: [
        {
          label: 'navi_decl_4_1_templates_overview',
          link: ROUTES.templatesOverview,
        },
        {
          label: 'navi_decl_4_2_template_settings',
          link: ROUTES.templatesSettings,
        },
      ],
    },
    {
      label: 'navi_decl_5_print_export',
      icon: Templates,
      subMenu: [
        {
          label: 'navi_decl_5_1_print_data',
          link: ROUTES.print,
        },
        {
          label: 'navi_decl_5_2_export_data',
          link: ROUTES.export,
        },
      ],
    },
    {
      label: 'navi_decl_6_settings',
      icon: Settings,
      link: ROUTES.settings,
    },
  ],
  FoodIngridientsData: [
    {
      label: 'navi_fid_1_home',
      icon: Home,
      link: ROUTES.home,
    },
    {
      label: 'navi_fid_2_jobs',
      icon: Jobs,
      subMenu: [
        {
          label: 'navi_fid_2_1_incomplete_data',
          link: ROUTES.incompleteDataIngredients,
        },
        {
          label: 'navi_fid_2_2_tasks',
          link: ROUTES.tasks,
        },
      ],
    },
    {
      label: 'navi_fid_3_technical_data',
      icon: TechnicalData,
      link: ROUTES.technicalData,
    },
    {
      label: 'navi_fid_4_translations',
      icon: Translations,
      subMenu: [
        {
          label: 'navi_fid_4_1_overview',
          link: ROUTES.translationsOverview,
        },
        {
          label: 'navi_fid_4_import_translations',
          link: ROUTES.translationsImport,
        },
      ],
    },
    {
      label: 'navi_fid_5_nutritions',
      icon: Nutritions,
      subMenu: [
        {
          label: 'navi_fid_5_1_nutritions_overview',
          link: ROUTES.nutritionsOverview,
        },
        {
          label: 'navi_fid_5_2_add_single_entries',
          link: ROUTES.nutritionsSingleEntries,
        },
        {
          label: 'navi_fid_5_3_import_nutritions',
          link: ROUTES.nutritionsImport,
        },
        {
          label: 'navi_fid_5_4_Versions',
          link: ROUTES.nutritionsVersions,
        },
      ],
    },
    {
      label: 'navi_fid_6_branded_products',
      icon: BrandedProducts,
      subMenu: [
        {
          label: 'navi_fid_6_1_branded_product_overview',
          link: ROUTES.productsOverview,
        },
        {
          label: 'navi_fid_6_2_import_branded_products',
          link: ROUTES.productsGroups,
        },
        { label: 'navi_fid_6_3_companies', link: ROUTES.productsCompanies },
      ],
    },
    {
      label: 'navi_fid_7_manage_user_suggestions',
      icon: ManageUserSuggestions,
      subMenu: [
        {
          label: 'navi_fid_7_1_new_names',
          link: ROUTES.manUserNames,
        },
        {
          label: 'navi_fid_7_2_new_nutritional_values',
          link: ROUTES.manUserValues,
        },
        {
          label: 'navi_fid_7_3_new_branded_product',
          link: ROUTES.manUserProduct,
        },
        {
          label: 'navi_fid_7_4_other_requests',
          link: ROUTES.manUserOther,
        },
      ],
    },

    {
      label: 'navi_fid_8_History',
      icon: History,
      link: ROUTES.history,
    },
  ],
  FoodScale: [
    {
      label: 'navi_nutr_1_home',
      icon: Home,
      link: ROUTES.home,
    },
    {
      label: 'navi_nutr_2_jobs',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_2_2_task',
          link: ROUTES.tasks,
        },
      ],
    },
    {
      label: 'navi_nutr_3_ingredients',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_3_1_ingredients_overview',
          link: ROUTES.ingridientsOverview,
        },
        {
          label: 'navi_nutr_3_2_ingredient_groups',
          link: ROUTES.ingridientsGroups,
        },
        {
          label: 'navi_nutr_3_3_bought_item_overview',
          link: ROUTES.boughtItemsOverview,
        },
      ],
    },
    {
      label: 'navi_nutr_4_recipes',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_4_1_recipes_overview',
          link: ROUTES.recipesOverview,
        },
        {
          label: 'navi_nutr_4_2_recipe_groups',
          link: ROUTES.recipesGroups,
        },
        {
          label: 'navi_nutr_4_3_recipe_versions',
          link: ROUTES.recipesVersions,
        },
      ],
    },
    {
      label: 'navi_nutr_5_products',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_5_products_overview',
          link: ROUTES.productsOverview,
        },
        { label: 'navi_nutr_5_product_groups', link: ROUTES.productsGroups },
      ],
    },
    {
      label: 'navi_nutr_6_wizards',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_6_1_import_recipes',
          link: ROUTES.wizardsImportRecipes,
        },
        {
          label: 'navi_nutr_6_2_import_ingredients',
          link: ROUTES.wizardsImportIngridients,
        },
        {
          label: 'navi_nutr_6_3_replace_ingredients',
          link: ROUTES.wizardsExportIngridients,
        },
      ],
    },
    {
      label: 'navi_nutr_7_print_export',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_7_1_print_data',
          link: ROUTES.print,
        },
        {
          label: 'navi_nutr_7_2_export_data',
          link: ROUTES.export,
        },
        {
          label: 'navi_nutr_7_recipe_exchange',
          link: ROUTES.recipeExchange,
        },
      ],
    },
    {
      label: 'navi_nutr_8_settings',
      icon: SettingsIcon,
      link: ROUTES.settings,
    },
  ],
  InternalSettings: [
    {
      label: 'navi_nutr_1_home',
      icon: SettingsIcon,
      link: ROUTES.home,
    },
    {
      label: 'navi_nutr_2_jobs',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_2_2_task',
          link: ROUTES.tasks,
        },
      ],
    },
    {
      label: 'navi_nutr_3_ingredients',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_3_1_ingredients_overview',
          link: ROUTES.ingridientsOverview,
        },
        {
          label: 'navi_nutr_3_2_ingredient_groups',
          link: ROUTES.ingridientsGroups,
        },
        {
          label: 'navi_nutr_3_3_bought_item_overview',
          link: ROUTES.boughtItemsOverview,
        },
      ],
    },
    {
      label: 'navi_nutr_4_recipes',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_4_1_recipes_overview',
          link: ROUTES.recipesOverview,
        },
        {
          label: 'navi_nutr_4_2_recipe_groups',
          link: ROUTES.recipesGroups,
        },
        {
          label: 'navi_nutr_4_3_recipe_versions',
          link: ROUTES.recipesVersions,
        },
      ],
    },
    {
      label: 'navi_nutr_5_products',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_5_products_overview',
          link: ROUTES.productsOverview,
        },
        { label: 'navi_nutr_5_product_groups', link: ROUTES.productsGroups },
      ],
    },
    {
      label: 'navi_nutr_6_wizards',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_6_1_import_recipes',
          link: ROUTES.wizardsImportRecipes,
        },
        {
          label: 'navi_nutr_6_2_import_ingredients',
          link: ROUTES.wizardsImportIngridients,
        },
        {
          label: 'navi_nutr_6_3_replace_ingredients',
          link: ROUTES.wizardsExportIngridients,
        },
      ],
    },
    {
      label: 'navi_nutr_7_print_export',
      icon: SettingsIcon,
      subMenu: [
        {
          label: 'navi_nutr_7_1_print_data',
          link: ROUTES.print,
        },
        {
          label: 'navi_nutr_7_2_export_data',
          link: ROUTES.export,
        },
        {
          label: 'navi_nutr_7_recipe_exchange',
          link: ROUTES.recipeExchange,
        },
      ],
    },
    {
      label: 'navi_nutr_8_settings',
      icon: SettingsIcon,
      link: ROUTES.settings,
    },
  ],
};

export default DrawerNavTree;
