import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { DispatchContext, StoreContext } from '../../app';
import { currentLocaleSelector, translationsSelector } from '../../redux/selectors';

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function useStore() {
  return useContext(StoreContext);
}

export function useDispatch() {
  return useContext(DispatchContext);
}

/**
 * Receive the locale id and return the string translated into user's selected language
 */
export function useLocalize() {
  const intl = useIntl();

  return (name) => intl.formatMessage({ id: name });
}

export function useTranslations() {
  const currentLocale = useSelector(currentLocaleSelector);
  return useSelector(translationsSelector(currentLocale));
}

export function getCountryFlagUrlByISO(iso) {
  return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${iso}.svg`;
}

export const validateEmail = (email) => {
  return EMAIL_REGEX.test(String(email).toLowerCase());
};

export const getParameterByName = (name, url = window.location.href) => {
  // eslint-disable-next-line no-useless-escape
  // eslint-disable-next-line no-param-reassign
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
