import React from 'react';

import pallete from '../../pallete';

const BrandedProducts = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <path
          stroke={color}
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          fill='none'
          d='M27.3,30.4H4.7c-1.7,0-3.1-1.4-3.1-3.1V4.7c0-1.7,1.4-3.1,3.1-3.1h22.7c1.7,0,3.1,1.4,3.1,3.1v22.7
		C30.4,29.1,29.1,30.4,27.3,30.4z'
        />
        <path
          stroke={color2}
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          fill='none'
          d='M16.7,5.8l2.1,6.4c0.1,0.3,0.4,0.5,0.7,0.5h6.8c0.7,0,1,0.9,0.4,1.4l-5.5,4C21,18.3,20.9,18.6,21,19l2.1,6.4
		c0.2,0.7-0.6,1.3-1.2,0.8l-5.5-4c-0.3-0.2-0.6-0.2-0.9,0l-5.5,4c-0.6,0.4-1.4-0.1-1.2-0.8L11,19c0.1-0.3,0-0.6-0.3-0.8l-5.5-4
		c-0.6-0.4-0.3-1.4,0.4-1.4h6.8c0.3,0,0.6-0.2,0.7-0.5l2.1-6.4C15.5,5.1,16.5,5.1,16.7,5.8z'
        />
      </g>
    </svg>
  );
};

export default BrandedProducts;
