import { configureStore } from '@reduxjs/toolkit';

import languageReducer from './reducers/language.js';
import modalReducer from './reducers/modal';
import overviewTablesReducer from './reducers/overviewTables';
import spaceReducer from './reducers/space';
import toolBarReducer from './reducers/toolBar.js';
import userReducer from './reducers/user';

export const store = configureStore({
  reducer: {
    toolBar: toolBarReducer,
    languages: languageReducer,
    overviewTables: overviewTablesReducer,
    user: userReducer,
    space: spaceReducer,
    modal: modalReducer,
  },
});
