import React from 'react';

import pallete from '../../pallete';

const Settings = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <g>
          <path
            {...st0}
            d='M21,9.4c-0.7-0.3-1.1-0.9-1.1-1.6c0-0.4,0.1-0.9,0.5-1.5c0.6-1.1,0.2-2.4-0.8-3.1l-2-1.2
			c-0.9-0.5-2.1-0.2-2.7,0.7l-0.1,0.2c-1.1,1.8-2.8,1.8-3.9,0l-0.1-0.2C10.2,1.9,9,1.6,8.1,2.1l-2,1.2C5.3,3.7,4.9,4.5,4.9,5.2
			C4.9,5.6,5,6,5.2,6.3c0.3,0.5,0.5,1,0.5,1.5c0,1.1-0.9,1.9-2.4,1.9c-1.2,0-2.2,1-2.2,2.2V14c0,0.6,0.2,1.2,0.6,1.6
			c0.4,0.4,1,0.7,1.6,0.7c1.5,0,2.4,0.8,2.4,1.8c0,0.5-0.1,1-0.5,1.5c-0.6,1.1-0.2,2.4,0.8,3l2,1.2c0.9,0.6,2.1,0.2,2.7-0.7l0.1-0.2
			c0-0.1,0.1-0.1,0.1-0.2'
          />
        </g>
        <circle {...st1} cx='19.2' cy='19.1' r='3.5' />
        <path
          {...st1}
          d='M7.5,20.1V18c0-1.2,1-2.2,2.2-2.2h0c2.1,0,3-1.5,1.9-3.3l0,0c-0.6-1.1-0.2-2.4,0.8-3l2-1.2
		c0.9-0.5,2.1-0.2,2.7,0.7l0.1,0.2c1.1,1.8,2.8,1.8,3.9,0L21.3,9c0.5-0.9,1.7-1.2,2.7-0.7l2,1.2c1.1,0.6,1.4,2,0.8,3l0,0
		c-1.1,1.8-0.2,3.3,1.9,3.3h0c1.2,0,2.2,1,2.2,2.2v2.1c0,1.2-1,2.2-2.2,2.2h0c-2.1,0-3,1.5-1.9,3.3l0,0c0.6,1.1,0.2,2.4-0.8,3
		l-2,1.2c-0.9,0.5-2.1,0.2-2.7-0.7l-0.1-0.2c-1.1-1.8-2.8-1.8-3.9,0l-0.1,0.2c-0.5,0.9-1.7,1.2-2.7,0.7l-2-1.2c-1.1-0.6-1.4-2-0.8-3
		l0,0c1.1-1.8,0.2-3.3-1.9-3.3h0C8.5,22.3,7.5,21.3,7.5,20.1z'
        />
      </g>
    </svg>
  );
};

export default Settings;
