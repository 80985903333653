// Toolbar
export const toolbarSelector = (state) => state.toolBar;

// Translations
export const translationsSelector = (languageCode) => (state) => state.languages.translations[languageCode] || {};
export const currentLocaleSelector = (state) => state.languages.currentLocale;

// Overview Table
export const overviewTableItemsSelector = (state) => state.overviewTables.selectedItems;

// User
export const userStatusSelector = (state) => state.user.data?.status;

// Space
export const spaceIdSelector = (state) => state.space.id;

export const spaceNumberFormatSettingsSelector = (state) => {
  return {
    numberFormat: state.space.numberFormat,
    countOfDecimalDigits: state.space.countOfDecimalDigits,
    mainUnit: state.space.mainUnit,
  };
};

export const modalDataSelector = (modalName) => (state) => state.modal[modalName].data;
export const isModalOpenSelector = (modalName) => (state) => state.modal[modalName].isOpen;
export const reinitializeFIDsSelector = (modalName) => (state) => state.modal[modalName].reinitializeFIDs;
