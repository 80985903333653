import React from 'react';

import pallete from '../pallete';

const ChevronRight = ({
  color = pallete.backgroundWhite,
  height = 10,
  width = 8,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 8 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.93241 0.616211L0.944336 0.616211L4.26442 5.26432L0.944336 9.91243H3.93241L7.25249 5.26432L3.93241 0.616211Z'
        fill={color}
      />
    </svg>
  );
};

export default ChevronRight;
