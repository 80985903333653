import { lazy } from 'react';

import { ROUTES } from './constants';

const TEMPORARYgenericPage = lazy(() => import('../../pages/TEMPORARY/TEMPORARY'));
const LoginPage = lazy(() => import('../../pages/Authorization/Login/Login'));
const RegistrationPage = lazy(() =>
  import('../../pages/Authorization/RegStep1PersonalInformation/RegStep1PersonalInformation'),
);

const RegistrationStep2DefineSpacePage = lazy(() =>
  import('../../pages/Authorization/RegStep2DefineSpace/RegStep2DefineSpace'),
);
const RegistrationStep3aGetOneTimePasswordPage = lazy(() =>
  import('../../pages/Authorization/RegStep3GetOTPCode/RegStep3GetOTPCode'),
);
const RegistrationStep3aValidatePasswordPage = lazy(() =>
  import('../../pages/Authorization/RegStep3ValidationCode/RegStep3ValidationCode'),
);
const RestorePasswordPage = lazy(() => import('../../pages/Authorization/RestorePassword/RestorePassword'));
const SetNewPasswordPage = lazy(() => import('../../pages/Authorization/SetNewPassword/SetNewPassword'));
const CreateDB = lazy(() => import('../../pages/MyOwnSpace/CreateDatabaseForm/CreateDatabaseForm'));
const SpaceChoice = lazy(() => import('../../pages/Authorization/ActiveSpaceChoice/ActiveSpaceChoice'));
// Page with 3 highly customizable columns with drag n drop.
const DashboardPage = lazy(() => import('../../pages/Dashboard/Dashboard'));
// Own Space
const OwnSpaceCreate = lazy(() => import('../../pages/MyOwnSpace/Create/CreateOwnDatabase'));
const OwnSpaceDBSettings = lazy(() => import('../../pages/MyOwnSpace/DataBaseSettings'));
const OwnSpaceBillingAddress = lazy(() => import('../../pages/MyOwnSpace/BillingAddress/BillingAddress'));
const OwnSpaceSubscriptions = lazy(() => import('../../pages/MyOwnSpace/Subscriptions/Subscriptions'));
const OwnSpaceTagList = lazy(() => import('../../pages/MyOwnSpace/TagList/TagList'));
const OwnSpaceInvitedUsers = lazy(() => import('../../pages/MyOwnSpace/InvitedUsers/InvitedUsers'));
// My profile pages
const MyProfilePage = lazy(() => import('../../pages/ProfilePages/Profile/Profile'));
const MyNotifications = lazy(() => import('../../pages/ProfilePages/NotificationsPage/Notifications'));
const MyDBIBelong = lazy(() => import('../../pages/ProfilePages/DataBaseIBelong/DataBaseIBelong'));

// Declaratio
const DeclaratioTemplatesOverview = lazy(() => import('../../pages/Declaratio/TemplatesOverview/TemplatesOverview'));

// Ingredients

const IngredientsGroups = lazy(() => import('../../pages/Ingredients/IngredientsGroups'));
const IngredientsOverview = lazy(() => import('../../pages/Ingredients/IngredientsOverview/IngredientsOverview'));
const IngredientDetails = lazy(() => import('../../pages/Ingredients/IngrdientDetails'));
const IngredientCreate = lazy(() => import('../../pages/Ingredients/IngrdientDetails'));

// BoughtItems
const BoughtItemsOverview = lazy(() => import('../../pages/BoughtItems/BoughtItemsOverview/BoughtItemsOverview'));
const BoughtItemDetails = lazy(() => import('../../pages/BoughtItems/BoughtItemDetails'));
const BoughtItemCreate = lazy(() => import('../../pages/BoughtItems/BoughtItemDetails'));

// Recipes
const RecipesOverview = lazy(() => import('../../pages/Recipes/RecipesOverview/RecipesOverview'));

// Products
const ProductsOverview = lazy(() => import('../../pages/Products/ProductsOverview/ProductsOverview'));

// Jobs
const IncompleteDataPageIngredients = lazy(() =>
  import('../../pages/Jobs/IncompleteData/IncompleteDataPageIngredients'),
);
const IncompleteDataPageRecipes = lazy(() => import('../../pages/Jobs/IncompleteData/IncompleteDataPageRecipes'));

const routes = [
  { path: ROUTES.home, component: DashboardPage, private: true },
  { path: ROUTES.export, component: TEMPORARYgenericPage, private: true },
  {
    path: ROUTES.extSpacesNotifications,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.incompleteDataIngredients,
    component: IncompleteDataPageIngredients,
    private: true,
  },
  {
    path: ROUTES.incompleteDataRecipes,
    component: IncompleteDataPageRecipes,
    private: true,
  },
  { path: ROUTES.messages, component: TEMPORARYgenericPage, private: true },
  {
    path: ROUTES.myProfile,
    component: MyProfilePage,
    private: true,
    exact: false,
  },
  { path: ROUTES.extSpacesList, component: MyDBIBelong, private: true },
  { path: ROUTES.myNotifications, component: MyNotifications, private: true },
  {
    path: ROUTES.mySpaceBillingA,
    component: OwnSpaceBillingAddress,
    private: true,
  },
  { path: ROUTES.mySpaceCreate, component: OwnSpaceCreate, private: true },
  { path: ROUTES.mySpaceDb, component: OwnSpaceDBSettings, private: true },
  {
    path: ROUTES.mySpaceInvUsers,
    component: OwnSpaceInvitedUsers,
    private: true,
  },
  {
    path: ROUTES.mySpaceNotifications,
    component: TEMPORARYgenericPage,
    private: true,
  },
  { path: ROUTES.mySpaceSubs, component: OwnSpaceSubscriptions, private: true },
  { path: ROUTES.mySpaceTags, component: OwnSpaceTagList, private: true },
  { path: ROUTES.print, component: TEMPORARYgenericPage, private: true },
  { path: ROUTES.settings, component: TEMPORARYgenericPage, private: true },
  { path: ROUTES.tasks, component: TEMPORARYgenericPage, private: true },
  {
    path: ROUTES.tooldNutrimero,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.toolsDeclaratio,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.toolsFoodscale,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.recipeExchange,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.ingridientCreate,
    component: IngredientDetails,
    private: true,
  },
  {
    path: ROUTES.ingridientDetails,
    component: IngredientCreate,
    private: true,
  },
  {
    path: ROUTES.ingridientsOverview,
    component: IngredientsOverview,
    private: true,
  },
  {
    path: ROUTES.ingridientsGroups,
    component: IngredientsGroups,
    private: true,
  },
  {
    path: ROUTES.boughtItemsOverview,
    component: BoughtItemsOverview,
    private: true,
  },
  {
    path: ROUTES.boughtItemDetails,
    component: BoughtItemDetails,
    private: true,
  },
  {
    path: ROUTES.boughtItemCreate,
    component: BoughtItemCreate,
    private: true,
  },
  {
    path: ROUTES.recipesOverview,
    component: RecipesOverview,
    private: true,
  },
  {
    path: ROUTES.recipesGroups,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.recipesVersions,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.recipesImport,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.productsOverview,
    component: ProductsOverview,
    private: true,
  },
  {
    path: ROUTES.productsGroups,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.productsCompanies,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.templatesOverview,
    component: DeclaratioTemplatesOverview,
    private: true,
  },
  {
    path: ROUTES.declarationsOverview,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.declarationsGroup,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.templatesSettings,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.technicalData,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.translationsOverview,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.translationsImport,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.nutritionsImport,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.nutritionsOverview,
    component: TEMPORARYgenericPage,
    private: true,
  },
  { path: ROUTES.history, component: TEMPORARYgenericPage, private: true },
  {
    path: ROUTES.nutritionsSingleEntries,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.nutritionsVersions,
    component: TEMPORARYgenericPage,
    private: true,
  },
  { path: ROUTES.manUserNames, component: TEMPORARYgenericPage, private: true },
  { path: ROUTES.manUserOther, component: TEMPORARYgenericPage, private: true },
  {
    path: ROUTES.manUserProduct,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.manUserValues,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.wizardsImportIngridients,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.wizardsExportIngridients,
    component: TEMPORARYgenericPage,
    private: true,
  },
  {
    path: ROUTES.spaceChoice,
    component: SpaceChoice,
    private: true,
    isForGuest: false,
  },
  {
    path: ROUTES.login,
    component: LoginPage,
    private: false,
    isForGuest: true,
  },
  {
    path: ROUTES.registration,
    component: RegistrationPage,
    private: false,
    isForGuest: true,
    exact: true,
  },
  {
    path: ROUTES.registrationStep1,
    component: RegistrationPage,
    private: false,
    isForGuest: true,
    exact: true,
  },
  {
    path: ROUTES.registrationStep1Back,
    component: RegistrationPage,
    private: true,
    isForGuest: true,
  },
  {
    path: ROUTES.registrationStep2DefineSpace,
    component: RegistrationStep2DefineSpacePage,
    private: true,
    isForGuest: false,
  },
  {
    path: ROUTES.registrationStep3aGetOneTimePassword,
    component: RegistrationStep3aGetOneTimePasswordPage,
    private: true,
    isForGuest: false,
  },
  {
    path: ROUTES.registrationStep3aValidationPassword,
    component: RegistrationStep3aValidatePasswordPage,
    private: true,
    isForGuest: false,
  },
  {
    path: ROUTES.restorePassword,
    component: RestorePasswordPage,
    private: false,
    isForGuest: true,
  },
  {
    path: ROUTES.setNewPassword,
    component: SetNewPasswordPage,
    private: false,
    isForGuest: true,
  },
  {
    path: ROUTES.createDB,
    component: CreateDB,
    private: true,
    isForGuest: false,
  },
];
export default routes;
