import React, { useState } from 'react';

import pallete from '../../../../theme/pallete';

const Declaratio = ({
  color = pallete.secondary1,
  height = 25,
  width = 132,
  secondaryColor = pallete.logoSecondary,
  selected,
  disabled,
  nonIA = false,
}) => {
  let color1 = color;
  let secondaryColor2 = secondaryColor;
  const [hovered, setHovered] = useState(false);
  if (selected || hovered) {
    color1 = pallete.backgroundWhite;
  } else if (disabled) {
    color1 = '#B2B2B2';
    secondaryColor2 = '#777777';
  }
  return (
    <svg
      version='1.1'
      id='Ebene_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={width}
      height={height}
      viewBox='0 0 453.8 85.2'
      // style='enable-background:new 0 0 453.8 85.2;'
      xmlSpace='preserve'
      onMouseEnter={() => !nonIA && setHovered(true)}
      onMouseLeave={() => !nonIA && setHovered(false)}
    >
      <g>
        <path
          fill={secondaryColor2}
          d='M37.8-0.5c1.3,0.5,2.6,0.3,4,0.5c4.6,0.5,9.2,1,13.6,2.4c0.9,0.3,1.7,0.6,2.6,1C59.1,4,60.5,4.5,60.5,6
  S59.2,8,58,8.6c-1.5,0.8-3.2,1.3-4.8,1.7c-8.7,2-17.6,2.4-26.5,2.2c-7-0.2-13.9-0.7-20.6-2.6C3.9,9.2,1.9,8.5,0.4,6.7
  c0-0.4,0-0.8,0-1.2c1-1.6,2.7-2.3,4.4-2.9C9.5,1.1,14.4,0.5,19.2,0c1.2-0.1,2.4,0,3.5-0.5C27.7-0.5,32.8-0.5,37.8-0.5z'
        />
        <path
          fill={secondaryColor2}
          d='M-0.6,9.2c1.4,0.6,2.9,1.2,4.4,1.8c5.6,1.9,11.4,2.6,17.2,3c7.9,0.6,15.8,0.5,23.7-0.5c5.5-0.7,10.9-1.5,16-4
  c0.6-0.3,0.8-0.2,0.7,0.4c0,3.6,0,7.2,0,10.8c0,1-0.7,1.7-1.5,2.1c-2.3,1.4-4.7,2.1-7.2,2.7c-8.8,2-17.7,2.4-26.5,2.2
  c-6.3-0.2-12.6-0.7-18.7-2.3c-1.1-0.3-2.3-0.6-3.4-1c-1.8-0.6-3.3-1.3-4.6-2.7C-0.6,17.5-0.6,13.4-0.6,9.2z M53.1,22.1
  c2.1,0,3.7-1.4,3.7-3.3c0-1.9-1.7-3.4-3.8-3.4c-2.1,0-3.6,1.5-3.6,3.4C49.4,20.6,51,22.1,53.1,22.1z'
        />
        <path
          fill={secondaryColor2}
          d='M0.4,39.7c4,2.2,8.3,3.1,12.8,3.8c9.6,1.5,19.3,1.6,28.9,0.7c5.2-0.5,10.3-1.2,15.2-3c1-0.4,2-0.9,3.2-1.4
  c0,0.6,0,1.1,0,1.6c0,3-0.1,6.1,0,9.1c0,1.2-0.5,2-1.4,2.5c-2.3,1.4-4.8,2.1-7.4,2.7c-8.2,1.8-16.6,2.2-25,2
  c-6.8-0.2-13.6-0.8-20.1-2.7c-2.3-0.6-4.4-1.4-6.2-3.1C0.4,47.9,0.4,43.8,0.4,39.7z M48.8,49c-0.1,1.8,1.4,3.3,3.4,3.4
  c2,0.1,3.7-1.4,3.8-3.2s-1.5-3.3-3.5-3.4C50.6,45.7,48.9,47.2,48.8,49z'
        />
        <path
          fill={secondaryColor2}
          d='M0.4,54.8c6.2,3.1,12.8,3.9,19.6,4.5c7,0.6,14,0.6,20.9,0c5.7-0.5,11.4-1.2,16.8-3.3c0.9-0.3,1.7-0.8,2.6-1.2
  c0.3,0.2,0.2,0.6,0.2,0.9c0,3.2-0.1,6.4,0,9.6c0,1.4-0.5,2.3-1.6,2.9c-2.1,1.2-4.3,1.9-6.6,2.4c-9.5,2.2-19.1,2.5-28.7,2
  c-5.7-0.3-11.3-0.9-16.8-2.5c-1.3-0.4-2.7-0.8-3.9-1.4c-1-0.5-1.9-1.1-2.5-1.9C0.4,62.8,0.4,58.8,0.4,54.8z M52.5,67.4
  c1.9,0,3.6-1.6,3.5-3.3c0-1.8-1.7-3.3-3.7-3.3c-2,0.1-3.5,1.6-3.5,3.4C48.9,66,50.5,67.4,52.5,67.4z'
        />
        <path
          fill={secondaryColor2}
          d='M329.5,64.3c-0.6,1-1,1.7-1.5,2.4c-1.1,1.6-2.7,2.2-4.6,1.9c-1.8-0.3-2.8-1.5-3.3-3.1c-0.9-2.4-1-5-0.8-7.5
  c0.5-6,1.6-11.9,2.4-17.9c0.6-4.1,1-8.2,2.3-12.2c0.5-1.6,1.2-3.1,2.1-4.5c2.3-3.2,7.2-3,8.7,0.9c0,0.1,0.1,0.3,0.4,0.3
  c0.3-2.4,0.7-4.8,1-7.2c0.6-4,1.1-8,1.7-11.9c0.1-0.7,0.3-0.8,1-0.8c3-0.1,3-0.1,2.5,2.9c-1.3,9.3-2.6,18.7-3.9,28
  c-1.1,8.2-2.3,16.3-3.5,24.5c-0.3,2.3-0.6,4.6-0.8,6.9c-0.1,0.8-0.3,1.2-1.2,1.2c-2.6-0.1-2.6,0-2.4-2.6
  C329.5,65.1,329.5,64.9,329.5,64.3z M323.1,58.1c0,0.8,0.1,1.8,0.2,2.7c0,0.4,0.2,0.8,0.3,1.2c0.7,1.8,2.5,2.3,4.1,1
  c0.6-0.5,1-1.2,1.4-1.9c0.8-1.7,1.3-3.5,1.6-5.3c1.3-6.9,2.1-13.9,3.1-20.9c0.3-2.1,0.5-4.3-0.1-6.4c-0.3-1.2-1-2.1-2.3-2.3
  c-1.3-0.2-2.2,0.5-2.9,1.5c-0.8,1.2-1.2,2.7-1.6,4.1c-0.9,3.8-1.3,7.7-1.8,11.5C324.4,48.2,323.5,53,323.1,58.1z'
        />
        <path
          fill={secondaryColor2}
          d='M392.8,24.4c0.7-0.1,0.8-0.5,1-0.8c1.3-1.8,3-2.7,5.2-2.3c2,0.4,2.9,1.9,3.4,3.7c0.9,3,0.8,6,0.4,9
  c-1,7.7-2.1,15.3-3.3,22.9c-0.3,2.4-1,4.7-1.9,6.9c-0.6,1.4-1.3,2.6-2.5,3.6c-2.6,2.1-5.9,1.2-7.1-1.8c-0.2-0.5-0.4-1-0.6-1.8
  c-0.2,1.2-0.4,2.2-0.5,3.2c-0.1,0.7-0.4,0.9-1.1,0.9c-2.7,0-2.7,0-2.3-2.6c2.2-15.3,4.3-30.5,6.5-45.8c0.7-4.7,1.3-9.4,1.9-14.1
  c0.1-0.8,0.4-1,1.1-1c2.9,0,2.8,0,2.4,2.8c-0.8,5.2-1.5,10.5-2.3,15.7C393,23.5,392.9,23.9,392.8,24.4z M388.5,57.5
  c0,1.2,0,2.4,0.3,3.6c0.3,1.3,1,2.3,2.4,2.5c1.4,0.1,2.2-0.6,2.9-1.7c0.7-1.1,1.1-2.4,1.3-3.7c1.6-8.5,2.7-17.1,3.8-25.7
  c0.2-1.4,0.1-2.8-0.2-4.2c-0.6-2.2-2.8-2.9-4.5-1.3c-0.5,0.5-0.9,1.1-1.3,1.7c-0.6,1.2-1.1,2.6-1.4,3.9c-1,4.7-1.5,9.5-2.1,14.3
  C389.2,50.4,388.5,53.9,388.5,57.5z'
        />
        <path
          fill={secondaryColor2}
          d='M375.2,65.1c-0.5,0.8-0.9,1.5-1.4,2.1c-2.2,2.5-5.8,2-7.2-1c-0.7-1.4-0.9-2.9-1-4.4c-0.2-3.9,0.3-7.7,1.3-11.5
  c1.5-5.7,5.4-8.7,11-9.8c1-0.2,1.3-0.7,1.4-1.6c0.3-2.7,0.9-5.4,1-8.2c0.1-1,0.1-2.1-0.3-3.1c-0.3-1.1-1-1.7-2.1-1.8
  c-1.1-0.1-1.9,0.4-2.5,1.3c-0.9,1.5-1.1,3.2-1.4,4.9c-0.4,2.1-0.4,2.1-2.5,2.1c-1.5,0-1.6,0-1.3-1.5c0.4-2.6,0.8-5.1,1.9-7.5
  c1.4-3,3.6-4.3,6.8-4c2.6,0.2,4.4,2.1,5,5s0.1,5.7-0.3,8.6c-1.5,10.7-3,21.4-4.4,32.2c-0.1,0.8-0.3,1-1.1,0.9
  c-0.9-0.1-2,0.4-2.5-0.2C374.9,67.2,375.5,66.1,375.2,65.1z M369.2,58.5c0,1.1,0,2.2,0.4,3.3c0.7,2.1,2.7,2.6,4.3,1
  c0.8-0.9,1.3-2,1.7-3.1c1.5-4.5,1.9-9.2,2.6-13.9c0.1-0.5,0.1-0.8-0.6-0.6c-3.4,0.8-6,2.5-7.1,6C369.7,53.5,369.3,55.9,369.2,58.5z
  '
        />
        <path
          fill={secondaryColor2}
          d='M412,64.6c-0.6,0.9-0.9,1.6-1.3,2.2c-2.2,3-6.2,2.5-7.7-0.9c-0.6-1.3-0.8-2.7-0.9-4.2
  c-0.2-3.9,0.3-7.8,1.3-11.6c1.3-4.9,4.5-8.1,9.4-9.2c2.2-0.5,3-1.4,3.1-3.6c0.1-2.5,0.8-5,0.8-7.6c0-0.6-0.1-1.2-0.2-1.8
  c-0.2-1.2-0.9-2-2.1-2.1c-1.3-0.2-2.1,0.5-2.7,1.6c-0.7,1.3-0.9,2.7-1.2,4.1c-0.5,2.9-0.5,2.9-3.5,2.7c-0.4,0-0.7-0.1-0.6-0.6
  c0.5-2.9,0.8-5.8,2.1-8.5c1-2,2.5-3.5,4.9-3.8c3.9-0.5,6.3,1.6,6.9,5.5c0.5,3.2-0.2,6.3-0.6,9.4c-1.4,10.3-2.8,20.5-4.2,30.7
  c-0.1,0.7-0.2,1-1.1,1c-2.7,0-2.7,0-2.5-2.6C412,65.2,412,65.1,412,64.6z M414.9,45c-3.3,0.7-6,1.8-7.3,4.9
  c-1.5,3.5-2.1,7.2-1.6,11.1c0.2,1.2,0.6,2.3,1.9,2.6c1.4,0.3,2.4-0.5,3.1-1.6c0.4-0.6,0.7-1.3,1-2c0.6-1.5,1-3.1,1.3-4.7
  C413.9,51.9,414.4,48.5,414.9,45z'
        />
        <path
          fill={secondaryColor2}
          d='M347.1,64.7c-0.3,0.6-0.7,1.2-1,1.7c-1.1,1.8-2.7,2.5-4.8,2.2c-1.9-0.3-2.8-1.6-3.4-3.2
  c-0.7-2.1-0.9-4.2-0.8-6.4c0.1-3.1,0.6-6.1,1.4-9.1c1.3-4.6,4.2-7.6,8.8-8.9c0.8-0.2,1.6-0.5,2.4-0.7c0.6-0.1,0.9-0.4,0.9-1
  c0.3-2.8,0.7-5.6,1.1-8.4c0.1-1,0-2-0.2-3c-0.2-1.1-0.9-2-2.1-2.2c-1.1-0.2-2.1,0.4-2.7,1.6c-0.8,1.5-1,3.3-1.3,4.9
  c-0.3,1.9-0.3,1.9-2.2,1.9c-1.8,0-1.8,0-1.5-1.8c0.4-2.6,0.8-5.1,2-7.5c1.3-2.6,3.4-3.9,6.4-3.8c3.2,0.2,5.2,2.6,5.4,6.4
  c0.2,3.2-0.4,6.3-0.8,9.4c-1.3,9.7-2.7,19.4-4,29.1c0,0.1,0,0.2,0,0.2c0,1.2-0.4,1.7-1.7,1.6c-2.2-0.1-2.2,0-1.9-2.2
  c0-0.4,0.1-0.7,0.1-1.1C347.2,64.7,347.1,64.7,347.1,64.7z M350,45c-3.2,0.5-5.6,1.8-6.8,4c-2.1,3.8-2.6,8-2,12.2
  c0.2,1.1,0.6,2.1,1.9,2.4c1.3,0.3,2.3-0.4,3-1.5c0.8-1.1,1.2-2.4,1.6-3.7C348.9,54.1,349.3,49.6,350,45z'
        />
        <path
          fill={secondaryColor2}
          d='M446.4,45.9c-1.5,0-3,0-4.6,0c-0.4,0-0.8-0.1-0.9,0.5c-0.5,4.1-1.4,8.2-1.4,12.3c0,1,0.1,2.1,0.4,3.1
  c0.3,1.1,0.9,1.9,2.1,2c1.3,0.2,2.2-0.4,2.8-1.4c1-1.6,1.3-3.5,1.6-5.3c0.4-2.4,0.4-2.3,2.8-2.4c1.1,0,1.2,0.3,1,1.3
  c-0.5,2.9-0.9,5.8-2.2,8.5c-1.5,3.2-4.2,4.8-7.4,4.1c-2.1-0.4-3.4-1.8-4.1-3.8c-1-2.9-1-6-0.6-9c1-8.3,2.1-16.6,3.5-24.8
  c0.4-2.3,1.1-4.6,2.4-6.6c1.1-1.8,2.6-3,4.8-3.2c3.8-0.3,6.2,2,6.7,6.3c0.5,3.7-0.1,7.4-0.6,11.1c-0.3,2.1-0.6,4.2-0.9,6.3
  c-0.1,0.8-0.4,1.1-1.2,1C449.4,45.9,447.9,45.9,446.4,45.9z M445.3,41.2c0.9,0,1.8,0,2.6,0c0.7,0.1,1-0.2,1.1-0.9
  c0.3-2.5,0.7-5,1-7.6c0.2-1.7,0.2-3.4-0.5-5.1c-0.9-2.1-3.1-2.4-4.6-0.6c-0.7,0.9-1.1,1.9-1.3,2.9c-1,3.5-1.2,7.1-1.8,10.7
  c-0.1,0.6,0.2,0.6,0.6,0.6C443.4,41.2,444.3,41.2,445.3,41.2z'
        />
        <path
          fill={secondaryColor2}
          d='M436.3,30.6c-0.1,1.2-0.3,2.5-0.4,3.8c0,0.6-0.3,0.7-0.8,0.8c-2.8,0.2-2.8,0.2-2.6-2.5c0.1-1.2,0.2-2.4,0-3.6
  c0-0.4-0.1-0.7-0.2-1.1c-0.3-1.2-0.9-1.9-2.2-2c-1.2-0.1-2,0.6-2.4,1.6c-1.3,3.5-1.7,7-0.1,10.5c0.6,1.4,1.5,2.6,2.4,3.9
  c4.9,6.8,4.7,14.2,2.2,21.8c-0.9,2.7-2.6,4.8-5.8,4.9c-3.9,0.2-6.3-1.9-6.8-6.1c-0.3-2.4-0.2-4.8,0.2-7.3c0.1-0.6,0.3-0.8,0.9-0.8
  c2.8-0.1,2.8-0.1,2.6,2.6c-0.1,1.5-0.2,2.9,0.2,4.4c0.4,1.6,1.2,2.3,2.5,2.5c1.3,0.1,2.4-0.5,2.9-2c1.7-4.9,2.2-9.8-0.6-14.5
  c-0.8-1.3-1.7-2.6-2.5-3.9c-3.8-5.9-3.4-12.1-1.1-18.3c0.8-2.2,2.3-3.7,4.8-4c3.1-0.3,5.2,1.2,6.2,4.3c0.2,0.7,0.4,1.4,0.5,2.1
  C436.3,28.6,436.3,29.5,436.3,30.6z'
        />
        <path
          fill={secondaryColor2}
          d='M366.8,26.8c-2.1,0-2.1,0-2.4,2c-1.5,10.2-3,20.3-4.4,30.5c-0.1,0.7-0.1,1.4-0.1,2c0,2.1,0.8,2.9,2.9,2.4
  c1.2-0.3,1.3,0.1,1,1c-0.1,0.5-0.1,0.9-0.2,1.4c-0.3,2.1-0.3,2.1-2.5,2.3c-3,0.3-4.6-1-5-3.9c-0.4-2.8,0.3-5.6,0.6-8.3
  c1.4-9.6,2.7-19.1,4.1-28.7c0.1-0.7-0.1-0.9-0.7-0.8H360c-2.5-0.1-2.5-0.1-2.2-2.6c0.3-2.3,0.3-2.3,2.7-2.3c0.9,0,1.2-0.3,1.3-1.2
  c0.4-3.3,1-6.5,1.4-9.8c0.1-0.6,0.3-0.8,0.9-0.8c2.9-0.1,2.9-0.1,2.5,2.8c-0.4,2.7-0.8,5.4-1.2,8c-0.1,0.7,0,1,0.8,1
  c0.9-0.1,1.8,0,2.6,0c0.5,0,0.7,0.2,0.6,0.7c-0.2,1.2-0.4,2.4-0.5,3.7c-0.1,0.4-0.3,0.5-0.6,0.5C367.9,26.8,367.4,26.8,366.8,26.8z
  '
        />
      </g>
      <g>
        <path
          fill={color1}
          d='M0.4,24.9c2.8,1.2,5.7,2.3,8.7,2.9c8.2,1.7,16.5,2.1,24.9,1.9c7-0.2,13.9-0.8,20.6-2.6c1.8-0.5,3.5-1.1,5.1-2
  c0.7-0.4,0.8-0.2,0.8,0.5c0,3.4,0,6.9,0,10.3c0,1.2-0.8,1.8-1.7,2.3c-2.4,1.4-5.1,2.1-7.8,2.7c-8.4,1.7-17,2.1-25.6,1.8
  c-5.9-0.2-11.7-0.8-17.4-2.3c-1.2-0.3-2.4-0.7-3.5-1.1c-1.5-0.5-2.9-1.3-4-2.5C0.4,32.9,0.4,28.9,0.4,24.9z M52.2,37.4
  c2,0.1,3.7-1.4,3.8-3.2c0-1.7-1.5-3.3-3.4-3.3c-2.1-0.1-3.7,1.3-3.8,3.1C48.8,35.8,50.3,37.3,52.2,37.4z'
        />
        <path
          fill={color1}
          d='M190.3,25.8c0.2-1.2,0.4-2.1,0.6-3c0.1-0.6,0.4-0.8,1-0.9c2.8-0.1,2.8-0.1,2.4,2.7c-1.3,8.6-2.4,17.2-3.7,25.8
  c-1.3,8.5-2.2,17.1-3.8,25.5c-0.3,1.5-0.8,3-1.5,4.4c-1.6,3.1-4.4,4.5-7.8,3.9c-2.7-0.5-4.6-2.7-5-6.1c-0.2-1.4-0.1-2.9,0-4.3
  c0-0.6,0.2-0.9,0.9-0.8c0.9,0,2-0.3,2.7,0.1c0.8,0.5,0.1,1.7,0.2,2.5c0.1,0.5,0.1,1,0.2,1.4c0.3,1.2,0.9,2.1,2.2,2.4
  c1.4,0.3,2.6-0.3,3.4-1.5c0.9-1.5,1.3-3.1,1.6-4.7c0.5-3.1,1-6.2,1.2-9.4c-0.2,0.4-0.5,0.8-0.7,1.2c-1,1.8-2.5,2.8-4.6,2.6
  c-2.2-0.2-3.3-1.6-3.9-3.5c-1.1-3.2-0.8-6.5-0.4-9.8c1-7.1,2-14.3,3.1-21.4c0.4-2.4,0.9-4.8,1.9-7c0.5-1.3,1.2-2.4,2.3-3.3
  c2.7-2.4,6.3-1.5,7.6,1.9C190,24.7,190.1,25.1,190.3,25.8z M189.2,32.5c0-1.3,0-2.6-0.3-3.8c-0.4-1.6-1.3-2.5-2.5-2.6
  c-1.5-0.1-2.4,0.7-3,1.9c-0.8,1.4-1.2,3-1.4,4.5c-1,6.7-1.9,13.5-2.9,20.2c-0.3,2.4-0.8,4.8-0.3,7.2c0.2,1.2,0.6,2.3,2,2.5
  c1.4,0.3,2.3-0.4,3-1.4c0.9-1.2,1.3-2.5,1.7-3.9c1-3.8,1.5-7.6,2-11.5C188.2,41.3,189.1,36.9,189.2,32.5z'
        />
        <path
          fill={color1}
          d='M132,64.3c-0.5,0.8-0.8,1.5-1.2,2.1c-1.1,1.7-2.7,2.6-4.8,2.3c-2-0.3-2.9-1.7-3.5-3.4c-0.8-2.4-0.9-5-0.6-7.4
  c1.1-9.2,2.3-18.4,4-27.5c0.4-2.3,1.2-4.5,2.5-6.4c1-1.5,2.3-2.6,4.2-2.7c2.4-0.1,3.7,0.8,5,3.6c0.5-1.4,0.5-2.7,0.7-4
  c0.7-4.9,1.4-9.8,2-14.7c0.1-1.2,0.5-1.7,1.8-1.6c2.3,0.1,2.3,0,1.9,2.3c-1.3,8.9-2.5,17.7-3.8,26.6c-1.2,8.8-2.5,17.6-3.7,26.4
  c-0.3,2.3-0.6,4.7-0.8,7c-0.1,0.8-0.3,1.2-1.3,1.2c-2.6-0.1-2.6,0-2.3-2.6C132,65.1,132,64.8,132,64.3z M136.5,32.1
  c0.1-1.8-0.1-3.3-0.6-4.3c-0.6-1.4-2-2-3.3-1.5c-0.9,0.3-1.5,1-1.9,1.9c-1,1.8-1.4,3.7-1.7,5.7c-1,6.6-1.9,13.2-2.8,19.8
  c-0.3,2.4-0.8,4.9-0.3,7.4c0.2,1.2,0.6,2.3,2,2.6c1.4,0.3,2.4-0.4,3.1-1.5c0.8-1.1,1.2-2.4,1.6-3.7c0.9-3.2,1.3-6.5,1.8-9.8
  C135.1,43.1,136.1,37.6,136.5,32.1z'
        />
        <path
          fill={color1}
          d='M232.8,64.3c-0.6,1.1-1,2-1.6,2.7c-2.5,2.9-6.4,2.3-7.7-1.3c-1-2.5-1.1-5.1-0.8-7.7c1.1-9.2,2.3-18.5,4-27.6
  c0.4-2.2,1.1-4.4,2.4-6.3c1-1.6,2.3-2.7,4.3-2.8c2.5-0.1,4,1.2,4.8,3.5c0.3-0.1,0.3-0.3,0.3-0.5c0.8-5.9,1.7-11.9,2.5-17.9
  c0.2-1.7,0.2-1.7,1.9-1.7c2.2,0,2.2,0,1.9,2.1c-1.9,13.5-3.8,27.1-5.7,40.6c-0.9,6.5-1.9,13-2.6,19.5c-0.1,0.8-0.4,1.1-1.3,1.1
  c-2.6,0-2.6,0-2.3-2.6C232.8,65.1,232.8,64.8,232.8,64.3z M237.3,31.2c-0.1-1.4-0.1-2.5-0.6-3.6c-0.8-1.7-2.9-2.1-4.3-0.8
  c-0.6,0.5-0.9,1.2-1.2,1.9c-0.7,1.6-1.1,3.2-1.4,4.9c-1.1,7.3-2.1,14.6-3.1,21.8c-0.3,2-0.4,4,0.1,5.9c0.3,1,0.7,1.9,1.8,2.1
  c1.2,0.3,2.2-0.3,2.9-1.2c0.6-0.8,1-1.6,1.3-2.5c0.5-1.5,0.9-3,1.2-4.5c1-5.4,1.6-10.8,2.4-16.2C236.8,36.5,237.2,33.7,237.3,31.2z
  '
        />
        <path
          fill={color1}
          d='M86.1,58c0.5-6.2,1.6-12.3,2.4-18.5c0.5-3.5,0.8-7,1.8-10.5c0.5-2,1.2-3.9,2.6-5.5c3.2-3.8,8.9-3,10.6,1.7
  c1.2,3.1,1.1,6.3,0.7,9.6c-1,7.7-2.2,15.4-3.3,23.2c-0.4,2.4-1,4.8-2.1,6.9s-2.8,3.6-5.3,3.7c-4.1,0.3-6.4-1.8-7.1-6.5
  C86.1,60.8,86,59.4,86.1,58z M100.7,31.8c0-1,0-2-0.3-3c-0.4-1.6-1.2-2.6-2.5-2.7c-1.4-0.2-2.5,0.5-3.3,2.1s-1.2,3.4-1.4,5.2
  c-1.1,7.2-2.1,14.3-3.1,21.5c-0.3,2.2-0.6,4.4,0.1,6.6c0.4,1.3,1,2.3,2.5,2.4c1.5,0.1,2.4-0.7,3.1-1.9s1-2.6,1.3-4
  c0.8-5.4,1.6-10.8,2.4-16.2C99.9,38.5,100.6,35.2,100.7,31.8z'
        />
        <path
          fill={color1}
          d='M122.2,31.3c0,3.7-0.7,7.4-1.2,11c-0.8,5.5-1.4,11-2.4,16.4c-0.4,2.1-0.9,4.2-1.9,6.1
  c-1.5,2.9-3.9,4.2-7.2,3.8c-2.2-0.3-3.7-1.6-4.5-3.7c-1.1-2.5-1.2-5.2-0.9-7.9c1-8.9,2.2-17.8,3.8-26.6c0.4-2.4,1.2-4.6,2.6-6.6
  c1.6-2.2,3.8-3,6.4-2.6c2.4,0.4,3.8,2,4.6,4.2c0.4,1.2,0.6,2.4,0.7,3.6C122.3,29.9,122.2,30.6,122.2,31.3z M107.8,57.4
  c0,2.1,0,3.7,0.8,5c1,1.7,3.1,2,4.4,0.5c0.5-0.5,0.8-1.2,1.1-1.8c0.6-1.6,1-3.3,1.2-5c1-7,2.1-14.1,3-21.2c0.3-2.2,0.5-4.4-0.1-6.5
  c-0.4-1.2-1-2.2-2.4-2.4c-1.4-0.2-2.4,0.4-3.2,1.8c-0.6,1.1-1,2.4-1.2,3.6c-0.9,4.8-1.5,9.7-2.2,14.5C108.7,50,108,54,107.8,57.4z'
        />
        <path
          fill={color1}
          d='M275.8,25.7c0.7-1.2,1.2-2.1,2-2.9c2.9-2.9,6.9-1.8,8,2.1c0.9,3.1,0.3,6.2-0.1,9.2c-1.1,7.6-2.2,15.2-3.2,22.8
  c-0.5,3.4-1,6.9-1.5,10.3c0,0.3,0,0.7-0.4,0.7c-1,0-2,0-3,0c-0.5,0-0.4-0.4-0.3-0.7c0.8-5.8,1.6-11.6,2.5-17.4
  c0.8-5.9,1.7-11.7,2.5-17.6c0.2-1.1,0.2-2.3,0.1-3.5c-0.1-1.1-0.4-2.1-1.6-2.6c-1.2-0.4-2.3,0.1-3.1,0.9c-0.9,0.9-1.4,2.1-1.8,3.2
  c-1.2,3.5-1.6,7.1-2.1,10.8c-1.2,8.2-2.3,16.4-3.5,24.6c-0.3,2.2-0.4,2.2-2.6,2.2c-1.4,0-1.4,0-1.2-1.5c1.7-11.9,3.3-23.7,5-35.6
  c0.4-2.7,0.8-5.4,1.1-8.2c0.1-0.6,0.3-0.8,0.9-0.8c2.8-0.1,2.8-0.1,2.5,2.7C275.7,24.9,275.8,25.2,275.8,25.7z'
        />
        <path
          fill={color1}
          d='M164.4,25.6c0.6-0.8,0.9-1.4,1.3-2c0.9-1.2,2.1-2.1,3.6-2.3c3-0.3,5,1.6,5.4,5.2c0.3,3-0.2,5.9-0.6,8.8
  c-1.1,7.6-2.2,15.3-3.2,22.9c-0.4,3-0.8,5.9-1.2,8.9c-0.1,0.7-0.3,0.9-1.1,0.9c-2.8,0-2.8,0-2.5-2.7c1.2-8.8,2.5-17.6,3.7-26.4
  c0.4-3,1-6,1.1-9c0-0.6,0-1.1-0.2-1.7c-0.5-2.3-2.7-3-4.4-1.4c-1,0.9-1.5,2.1-2,3.4c-1.2,3.3-1.6,6.7-2.1,10.2
  c-1.2,8.2-2.3,16.3-3.5,24.5c0,0.1-0.1,0.2-0.1,0.4c-0.5,2.9-0.5,2.9-3.4,2.7c-0.6,0-0.7-0.2-0.6-0.8c1.3-9.1,2.6-18.2,3.9-27.3
  c0.8-5.5,1.5-11,2.2-16.4c0.2-1.2,0.6-1.6,1.8-1.5c2.1,0.1,2.1,0,1.9,2.1C164.4,24.4,164.4,24.8,164.4,25.6z'
        />
        <path
          fill={color1}
          d='M260.3,45.9c-1.6,0-3.1,0-4.7,0c-0.4,0-0.8,0-0.9,0.6c-0.5,4.7-1.7,9.3-1.3,14.1c0,0.4,0.1,0.9,0.3,1.3
  c0.3,1,0.9,1.8,2,2c1.2,0.2,2.2-0.3,2.8-1.4c0.7-1.2,1.1-2.5,1.4-3.8c0.2-1.1,0.4-2.1,0.5-3.2c0.1-0.6,0.3-0.8,0.9-0.8
  c3-0.1,3-0.2,2.5,2.8c-0.4,2.6-0.9,5.2-2.2,7.5c-1.1,2.1-2.7,3.5-5.1,3.7c-3.9,0.3-6.1-1.9-6.7-6.1c-0.5-4,0-7.9,0.6-11.8
  c0.8-6,1.7-12,2.6-18c0.4-2.7,1-5.4,2.4-7.8c1.2-2.1,2.8-3.6,5.5-3.7c2.9-0.1,4.8,1.2,5.8,4c1,2.7,0.9,5.6,0.6,8.4
  c-0.4,3.8-1.1,7.6-1.5,11.4c-0.1,0.7-0.3,0.9-1,0.9C263.2,45.9,261.7,45.9,260.3,45.9z M259,41.2c1,0,1.9,0,2.9,0
  c0.6,0,0.8-0.2,0.9-0.8c0.3-2.2,0.7-4.4,0.9-6.6c0.2-1.9,0.3-3.9-0.3-5.8c-0.4-1.1-1-1.9-2.2-2.1c-1.2-0.1-2,0.5-2.7,1.5
  c-0.5,0.8-0.9,1.6-1.1,2.5c-1,3.5-1.2,7-1.8,10.6c-0.1,0.7,0.2,0.8,0.8,0.8C257.2,41.2,258.1,41.2,259,41.2z'
        />
        <path
          fill={color1}
          d='M215.9,45.9c-1.5,0-3,0-4.6,0c-0.6,0-0.9,0.1-1,0.8c-0.4,4-1.3,7.9-1.4,11.9c0,1.1,0,2.2,0.3,3.2
  c0.3,1.1,0.9,2,2.1,2.2c1.3,0.2,2.2-0.4,2.9-1.5c0.8-1.4,1.1-3,1.5-4.6c0-0.2,0-0.3,0.1-0.5c0.5-2.7,0.6-2.8,3.4-2.5
  c0.6,0.1,0.7,0.3,0.6,0.8c-0.5,3-0.9,6.1-2.2,8.9c-1.2,2.7-3.1,4.4-6.3,4.3c-2.5,0-4.2-1.2-5.2-3.5c-1-2.5-1.1-5.1-0.9-7.8
  c0.9-9,2.2-18,3.8-27c0.4-2.2,1.1-4.3,2.4-6.2c1.5-2.3,3.7-3.3,6.5-2.9c2.6,0.4,3.9,2.2,4.5,4.5c0.3,1,0.4,1.9,0.5,2.9
  c0.3,3.7-0.3,7.3-0.8,10.9c-0.2,1.7-0.5,3.4-0.7,5.1c-0.1,0.8-0.4,1.1-1.3,1.1C218.7,45.9,217.3,45.9,215.9,45.9z M214.8,41.2
  c0.9,0,1.8,0,2.6,0c0.7,0,1-0.1,1.1-0.9c0.2-2.1,0.6-4.1,0.9-6.1c0.2-2,0.4-4-0.2-5.9c-0.3-1.2-0.9-2.2-2.3-2.3
  c-1.4-0.2-2.2,0.6-2.9,1.7c-0.3,0.5-0.6,1.1-0.7,1.6c-1.2,3.7-1.4,7.5-2,11.3c-0.1,0.5,0.1,0.7,0.7,0.7
  C212.8,41.2,213.8,41.2,214.8,41.2z'
        />
        <path
          fill={color1}
          d='M313.2,30.3c0.1,1.3-0.2,2.6-0.3,4c-0.1,0.7-0.3,0.9-1,0.9c-2.7,0.1-2.7,0.1-2.4-2.6c0.1-1.5,0.2-3-0.1-4.5
  c-0.3-1.2-0.9-2-2.2-2.1c-1.2-0.1-2,0.6-2.4,1.6c-1.4,3.7-1.9,7.4,0.1,11.1c1,1.7,2.1,3.4,3.2,5.1c2.7,4.3,3.3,9.1,2.6,14
  c-0.4,2.5-0.8,5-1.9,7.2c-1.1,2.1-2.6,3.5-5,3.8c-3.6,0.3-5.9-1.3-6.9-4.9c-0.7-2.8-0.5-5.7-0.1-8.5c0.1-0.5,0.2-0.6,0.8-0.7
  c2.9-0.2,2.9-0.2,2.7,2.6c-0.1,1.5-0.2,3,0.2,4.5c0.4,1.5,1.2,2.2,2.5,2.4c1.2,0.1,2.3-0.5,2.9-1.9C307.6,57,308,51.9,305,47
  c-0.8-1.4-1.8-2.7-2.6-4.1c-3.5-5.9-3-12-0.6-18c0.8-1.9,2.2-3.3,4.4-3.6c3-0.4,5.1,0.9,6.2,3.7C313,26.7,313.3,28.4,313.2,30.3z'
        />
        <path
          fill={color1}
          d='M77.8,68c-2.1,0-2.1,0-1.8-2c1.2-8.3,2.3-16.5,3.5-24.8c0.6-4.3,1.2-8.5,1.8-12.8c0.2-1.6,0.3-1.6-1.3-1.6
  c-1.8,0-1.8,0-1.5-1.8c0.1-0.5,0.1-0.9,0.2-1.4c0.3-1.7,0.3-1.7,2-1.6c1.1,0,1.6-0.1,1.7-1.4c0.4-3.4,0.8-6.8,1.6-10.1
  c0.3-1.3,0.8-2.6,1.5-3.8c1.3-2.1,3.4-2.9,5.7-2.3c0.6,0.2,0.9,0.4,0.8,1c-0.2,1.2-0.4,2.4-0.5,3.5c-0.1,0.6-0.4,0.5-0.8,0.5
  c-1.7-0.1-2.4,0.2-2.9,1.8c-1,3.3-1.1,6.7-1.7,10.1C86,22,86.4,22,86.7,22c0.8,0,1.6,0,2.4,0c0.7,0,0.8,0.2,0.7,0.8
  c-0.2,1.1-0.3,2.3-0.5,3.4c-0.1,0.4-0.3,0.6-0.7,0.6c-0.1,0-0.2,0-0.2,0c-1,0.1-2.3-0.5-3,0.3C84.9,27.7,85,29,84.9,30
  c-1.2,8.7-2.5,17.5-3.7,26.2c-0.5,3.5-1.1,7.1-1.5,10.6C79.6,68.4,78.5,67.8,77.8,68z'
        />
        <path
          fill={color1}
          d='M286.5,62.7c0.2-3.5,0.9-6.9,1.4-10.4c1.1-7.5,2.1-15,3.2-22.5c0.1-0.8,0.2-1.6,0.3-2.4
  c0.1-0.6-0.1-0.7-0.6-0.7c-0.6,0-1.2,0-1.8,0c-0.8,0.1-1-0.3-0.8-1c0.1-0.3,0.1-0.7,0.2-1.1c0.1-0.9,0-2,0.5-2.6s1.7-0.2,2.6-0.2
  c0.7,0,0.8-0.4,0.8-0.9c0.4-3,0.9-6,1.3-9c0.3-1.9,0.2-1.9,2.2-1.9c1.8,0,1.8,0,1.5,1.8c-0.5,2.9-0.9,5.8-1.3,8.7
  c-0.1,0.8,0,1.2,1,1.2s2.4-0.5,2.9,0.2c0.4,0.6-0.1,1.9-0.3,2.9c-0.3,1.7-0.3,1.7-2,1.7c-2.6,0-2.5,0-2.9,2.5
  c-1.2,8.6-2.5,17.2-3.7,25.8c-0.3,1.9-0.5,3.8-0.7,5.7c-0.3,2.5,0.7,3.4,3.2,3c0.8-0.1,1,0,0.8,0.7c-0.1,0.4-0.1,0.9-0.2,1.3
  c-0.4,2.5-0.4,2.5-2.3,2.8c-2.3,0.3-4-0.4-4.8-2.1C286.6,65.3,286.5,64,286.5,62.7z'
        />
        <path
          fill={color1}
          d='M201.8,27.5c0.8-1.3,1.4-2.6,2.4-3.8c1-1.1,2.2-2,3.7-2.3c0.6-0.1,0.9-0.2,0.8,0.6c-0.3,1.7-0.5,3.5-0.8,5.2
  c-0.1,0.5-0.2,0.7-0.7,0.8c-2.1,0.4-3.5,1.8-4.5,3.6c-1.7,3-2.2,6.4-2.7,9.7c-1.2,8.6-2.4,17.3-3.6,25.9c-0.1,0.5-0.3,0.8-0.8,0.8
  c-0.8,0-1.5-0.1-2.3,0c-0.7,0.1-0.8-0.3-0.7-0.9c1.3-9.3,2.7-18.5,4-27.8c0.8-5.4,1.5-10.9,2.2-16.3c0.1-0.8,0.4-1,1.2-1
  c2.6,0,2.6,0,2.3,2.6C202,25.5,202,26.5,201.8,27.5z'
        />
        <path
          fill={color1}
          d='M148.3,68c-0.1,0-0.2,0-0.4,0c-1.6,0-1.5,0-1.3-1.6c1.2-8.2,2.3-16.4,3.5-24.6c0.9-6.3,1.8-12.7,2.7-19
  c0.1-0.6,0.3-0.8,0.9-0.8c3-0.1,3-0.2,2.6,2.8c-1.7,12.1-3.5,24.3-5.2,36.4c-0.2,1.7-0.5,3.4-0.7,5.1C150.1,68,150.1,68,148.3,68z'
        />
        <path
          fill={color1}
          d='M242.3,68c-1.8,0-1.8,0-1.6-1.7c1.2-8.2,2.3-16.5,3.5-24.7c0.8-5.9,1.7-11.7,2.5-17.6c0.3-2,0.2-2,2.3-2
  c1.8,0,1.8,0,1.5,1.8c-1.7,11.8-3.3,23.6-5,35.4c-0.4,2.5-0.8,5-1.1,7.6c-0.1,1.1-0.5,1.5-1.5,1.3C242.7,67.9,242.5,68,242.3,68z'
        />
        <path
          fill={color1}
          d='M251.3,4.6c1.8,0,1.8,0,1.6,1.8c-0.3,2.2-0.6,4.5-0.9,6.7c-0.1,0.5-0.3,0.7-0.8,0.8c-3.2,0.2-3.2,0.2-2.7-2.9
  c0.3-1.7,0.6-3.5,0.7-5.2c0.1-1.1,0.5-1.4,1.5-1.2C250.9,4.6,251.1,4.6,251.3,4.6z'
        />
        <path
          fill={color1}
          d='M156,13.9c-1.9,0-1.9,0-1.6-1.9c0.3-2.1,0.6-4.2,0.8-6.4c0.1-0.8,0.4-1.2,1.2-1c0.3,0,0.6,0,0.8,0
  c1.9,0,1.9,0,1.6,1.9c-0.3,1.9-0.6,3.8-0.8,5.7C157.8,13.9,157.8,13.9,156,13.9z'
        />
      </g>
    </svg>
  );
};

export default Declaratio;
