import { useLazyQuery, useQuery } from '@apollo/client';

import { useDispatch } from '../../hooks';
import { useReduxDispatch } from '../../redux/helpers';
import { setUserData, updateUserData } from '../../redux/reducers/user';
import { setToken } from '../../utils';
import * as QUERIES from '../queries.gql';

export const useGetLanguages = () => {
  return useLazyQuery(QUERIES.GetLanguagesGQL, {
    fetchPolicy: 'network-only',
  });
};
export const useGetUserNotificationsSettings = () => {
  return useLazyQuery(QUERIES.GetUserNotificationsGQL, {
    fetchPolicy: 'network-only',
  });
};
export const useGetUnits = () => {
  return useLazyQuery(QUERIES.getUnitsGQL, {
    fetchPolicy: 'network-only',
  });
};
export const useGetLogin = () => {
  const dispatch = useDispatch();
  return useLazyQuery(QUERIES.SignInGQL, {
    onCompleted: (response) => {
      if (response?.signIn?.token) {
        setToken(response.signIn.token);
        dispatch({
          type: 'LOGIN',
          payload: { token: response.signIn.token, user: response.signIn.user },
        });
      }
    },
    onError: () => {
      dispatch({ type: 'LOGOUT' });
    },
  });
};
export const useCheckToken = () => {
  const dispatch = useDispatch();
  const reduxDispatch = useReduxDispatch();
  return useLazyQuery(QUERIES.GetCurrentUserGQL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.getCurrentUser) {
        dispatch({ type: 'LOGOUT' });
      } else {
        dispatch({ type: 'SET_USER', payload: data.getCurrentUser });
        reduxDispatch(setUserData(data.getCurrentUser));
        dispatch({ type: 'FINISH_LOADING' });
      }
    },
    onError: () => {
      dispatch({ type: 'LOGOUT' });
    },
  });
};

export const useGetUserDatabases = () => {
  const dispatch = useDispatch();
  const reduxDispatch = useReduxDispatch();
  return useLazyQuery(QUERIES.getUserDataBasesGQL, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      dispatch({
        type: 'UPDATE_USER',
        payload: {
          databases: response?.getUserDatabases,
        },
      });
      reduxDispatch(
        updateUserData({
          databases: response?.getUserDatabases,
        }),
      );
      dispatch({ type: 'FINISH_LOADING' });
    },
  });
};

export const useGetOwners = () => {
  return useLazyQuery(QUERIES.getOwnersGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetUsers = () => {
  return useLazyQuery(QUERIES.getUsersGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetUserDataBaseReference = () => {
  return useLazyQuery(QUERIES.getUserDataBaseReferenceGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetOwnerDataBaseReference = () => {
  return useLazyQuery(QUERIES.getOwnerDataBaseReferenceGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetTableForUpload = () => {
  return useLazyQuery(QUERIES.getTableOptionsForUpload, {
    fetchPolicy: 'network-only',
  });
};

export const useGetBoughtItems = () => {
  return useLazyQuery(QUERIES.getBoughtItemsGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetProducts = () => {
  return useLazyQuery(QUERIES.getProductsGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetIngridients = () => {
  return useLazyQuery(QUERIES.getIngridientsGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetRecipes = () => {
  return useLazyQuery(QUERIES.getResipesGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetTags = () => {
  return useLazyQuery(QUERIES.getTagsGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useFetchTags = (options) => {
  return useQuery(QUERIES.getTagsGQL, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetFIDDatabases = ({ exclude }) => {
  return useQuery(QUERIES.getFIDDatabasesGQL, {
    variables: {
      exclude,
    },
    fetchPolicy: 'network-only',
  });
};

export const useGetFIDListItems = ({ FIDDatabaseId, search, page }) => {
  return useLazyQuery(QUERIES.getFIDListItemsGQL, {
    variables: {
      FIDDatabaseId,
      search,
      page,
    },
    fetchPolicy: 'network-only',
  });
};

export const useGetSelectedUnits = () => {
  return useLazyQuery(QUERIES.getSelectedUnitsGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetFIDFoodSymbols = () => {
  return useLazyQuery(QUERIES.getFIDFoodSymbolsGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetBoughtItem = () => {
  return useLazyQuery(QUERIES.getBoughtItemGQL, {
    fetchPolicy: 'network-only',
  });
};

export const useGetDatabaseSettings = (options) => {
  return useLazyQuery(QUERIES.getDatabaseSettings, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetListItemsById = (options) => {
  return useLazyQuery(QUERIES.getListItemsById, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useFetchFIDListItems = (options) => {
  return useQuery(QUERIES.getListItemsById, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetFIDAllergens = () => {
  return useLazyQuery(QUERIES.getFIDAllergens, {
    fetchPolicy: 'network-only',
  });
};

export const useGetAllFIDAllergens = () => {
  return useQuery(QUERIES.getAllFIDAllergens, {
    fetchPolicy: 'network-only',
  });
};

export const useGetFIDNutriasValues = () => {
  return useLazyQuery(QUERIES.getFIDNutriasValues, {
    fetchPolicy: 'network-only',
  });
};

export const useGetAllFIDNutriasValues = () => {
  return useQuery(QUERIES.getAllFIDNutriasValues, {
    fetchPolicy: 'network-only',
  });
};

export const useGetTranslationsByLanguage = ({ languageCode }, options = {}) => {
  return useQuery(QUERIES.getTranslationsByLanguage, {
    variables: {
      languageCode: languageCode,
    },
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetIngredientGroupsByDatabase = () => {
  return useLazyQuery(QUERIES.getIngredientGroupsByDatabase, {
    fetchPolicy: 'network-only',
  });
};

export const useFetchIngredientGroupsByDatabase = (options) => {
  return useQuery(QUERIES.getIngredientGroupsByDatabase, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetBoughtItemUnitOfPackage = (options) => {
  return useQuery(QUERIES.getBoughtItemUnitOfPackage, {
    fetchPolicy: 'network-only',
    ...options,
  });
};

export const useGetAdditiveClasses = () => {
  return useLazyQuery(QUERIES.getAdditiveClasses, {
    fetchPolicy: 'network-only',
  });
};
