import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  limit: 5,
  pageNumb: 1,
  search: '',
  itemList: [],
  filterItems: [],
  sortBy: 'name',
  sortType: 'ASC',
  totalCount: 0,
  pageNum: 1,
  totalPagesCount: 0,
  activeRows: [],
  reinitData: false,
};

export const toolBarSlice = createSlice({
  name: 'toolBar',
  initialState,
  reducers: {
    setLimit: (state, action) => {
      state.limit = action.payload.limit;
      state.activeRows = [];
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload.count;
    },
    setPageNum: (state, action) => {
      state.pageNum = action.payload.pageNum;
      state.activeRows = [];
    },
    setSearch: (state, action) => {
      state.search = action.payload.search;
    },
    setFilterItems: (state, action) => {
      state.filterItems = action.payload.filterItems;
    },
    setItemList: (state, action) => {
      state.itemList = action.payload.itemList;
    },
    setTotalPagesCount: (state, action) => {
      state.totalPagesCount = action.payload.totalPagesCount;
    },
    setSort: (state, action) => {
      state.sortBy = action.payload.sortBy;
      state.sortType = action.payload.sortType;
    },
    setSearchRequest: (state) => {
      state.sortBy = state.filterItems[0] || '';
      state.sortType = 'ASC';
      state.pageNum = 1;
      state.limit = 20;
    },
    setCheckboxesState: (state, action) => {
      state.activeRows = action.payload.activeRows;
    },
    setReInitData: (state, action) => {
      state.reinitData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLimit,
  setTotalCount,
  setPageNum,
  setSearch,
  setFilterItems,
  setItemList,
  setTotalPagesCount,
  setSort,
  setSearchRequest,
  setCheckboxesState,
  setReInitData,
} = toolBarSlice.actions;

export default toolBarSlice.reducer;
