import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';

import data from './data';
import LOCALES from './locales';

const Provider = ({ children, locale = LOCALES.ENGLISH }) => (
  <IntlProvider
    locale={locale}
    textComponent={Fragment}
    messages={data[locale]}
  >
    {children}
  </IntlProvider>
);

export default Provider;
