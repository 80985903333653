import React, { useState } from 'react';

import pallete from '../../../../theme/pallete';

const InternalSettings = ({
  color = pallete.secondary1,
  height = 25,
  width = 132,
  secondaryColor = pallete.logoSecondary,
  selected,
  disabled,
  nonIA = false,
}) => {
  let color1 = color;
  let secondaryColor2 = secondaryColor;
  const [hovered, setHovered] = useState(false);
  if (selected || hovered) {
    color1 = pallete.backgroundWhite;
  } else if (disabled) {
    color1 = '#B2B2B2';
    secondaryColor2 = '#777777';
  }
  return (
    <svg
      version='1.1'
      id='Ebene_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={width}
      height={height}
      viewBox='0 0 453.8 85.2'
      // style='enable-background:new 0 0 453.8 85.2;'
      xmlSpace='preserve'
      onMouseEnter={() => !nonIA && setHovered(true)}
      onMouseLeave={() => !nonIA && setHovered(false)}
    >
      <g>
        <path
          fill={color1}
          d='M253.7-0.6c1.5,0.9,1.9,2.2,1.5,3.9c-0.4,1.8-0.5,3.7-0.8,5.5c-0.4,2.3-1.4,3.1-3.6,3.1c-1.9,0-3.7,0-5.6,0
		c-1.7,0-2.7-1.1-2.6-2.8c0.3-2.4,0.7-4.7,1.1-7.1c0.2-1.2,1-2,2-2.7C248.4-0.6,251-0.6,253.7-0.6z'
        />
        <path
          fill={color1}
          d='M104,82.3c-1.7,0-3.4,0-5,0c-0.7,0-0.9-0.1-0.8-0.9c1-6.4,1.9-12.8,2.8-19.3c1.1-7.4,2.1-14.9,3.2-22.3
		c0.2-1.8,0-3.6-0.5-5.3c-0.7-2.6-2.6-3.9-5.2-4.3c-2.2-0.3-4.5-0.2-6.7,0.3c-2.7,0.6-4.2,2.4-5.3,4.8c-1.3,2.9-1.7,6-2.2,9
		c-1.3,8.6-2.5,17.2-3.8,25.8C80,73.7,79.4,77.3,79,81c-0.1,1.1-0.4,1.5-1.7,1.5c-3.1-0.1-6.2-0.1-9.3,0c-0.9,0-1.1-0.3-0.9-1.1
		c1.4-9.6,2.8-19.2,4.2-28.8c0.8-5.2,1.3-10.5,2.5-15.7c0.7-3,1.6-6,2.9-8.8c3.1-6.4,8.4-9.9,15.4-10.7c3.6-0.4,7.2-0.5,10.7,0.3
		c7,1.5,11.4,6.2,12.7,13.3c0.9,5.1,0.4,10.2-0.3,15.2c-1.3,8.6-2.5,17.3-3.8,25.9c-0.5,3.2-1,6.5-1.4,9.7c-0.1,0.7-0.4,0.8-0.9,0.7
		C107.4,82.3,105.7,82.3,104,82.3z'
        />
        <path
          fill={color1}
          d='M119.1,63.1c0-4.7,0.9-9.3,1.6-13.9c1.3-8.9,2.7-17.8,4-26.7c0-0.3,0.1-0.5,0.1-0.8c0.2-0.9,0-2.2,0.6-2.7
		c0.6-0.6,1.9-0.2,2.9-0.2c2.6,0,5.1,0,7.7,0c0.9,0,1.1,0.3,0.9,1.1c-1.1,7.1-2.2,14.2-3.2,21.4c-0.9,6.2-2,12.3-2.6,18.6
		c-0.2,1.6-0.1,3.2,0.2,4.8c0.6,3.4,2.6,5.1,6.2,5.4c1.7,0.2,3.4,0,5-0.3c3.6-0.7,5.4-3.1,6.5-6.3c1.2-3.8,1.5-7.8,2.2-11.7
		c1.5-9.9,3-19.8,4.5-29.6c0.2-1-0.1-2.4,0.6-2.9c0.7-0.6,2.1-0.2,3.1-0.2c2.5,0,5,0,7.6,0c0.7,0,1,0,0.9,0.9
		c-2,13.1-3.9,26.2-5.9,39.3c-0.7,4.3-1.7,8.6-3.6,12.6c-3.3,7-8.9,10.6-16.5,11.3c-3.1,0.3-6.2,0.3-9.3-0.4
		c-7.2-1.6-11.5-6.2-12.8-13.4C119.2,67.1,119.1,65.1,119.1,63.1z'
        />
        <path
          fill={color1}
          d='M200.7,18.8c-1.2,4.2-2.4,8.3-3.5,12.4c-0.2,0.7-0.6,0.7-1.2,0.7c-3.8,0-7.5,0-11.3,0c-0.9,0-1.3,0.1-1.4,1.2
		c-1.3,9-2.6,18-3.9,26.9c-0.2,1.6-0.3,3.2-0.1,4.8c0.3,2.5,1.6,4,4,4.3c3.2,0.4,6.4,0.1,9.6,0.2c0.4,0,0.8,0,1.2,0
		c1-0.1,1.1,0.2,1,1.1c-0.6,3.5-1.2,7-1.6,10.5c-0.2,1.1-0.6,1.5-1.7,1.4c-3.6-0.1-7.1,0-10.7-0.1c-7.6-0.2-11.9-4.1-13.3-11.6
		c-0.9-4.8-0.3-9.5,0.4-14.3c1.1-8,2.3-16,3.5-24s2.3-15.9,3.5-23.9c0.2-1.5,0.4-2.9,0.6-4.4c0.1-0.6,0.2-0.9,0.9-1
		c3.4-0.5,6.9-1,10.3-1.6c0.7-0.1,1,0,0.9,0.8c-0.7,4.9-1.4,9.8-2.1,14.8c-0.2,1.6-0.3,1.6,1.4,1.6C191.5,18.8,196,18.8,200.7,18.8z
		'
        />
        <path
          fill={color1}
          d='M232.4,31.8c-2.5-0.1-5-0.2-7.6,0.1c-2.7,0.4-4.6,1.8-5.8,4.3c-1.4,3.1-2,6.4-2.5,9.7
		c-1.4,9.6-2.8,19.2-4.3,28.8c-0.3,2.3-0.7,4.6-1,6.9c-0.1,0.6-0.3,0.8-0.9,0.8c-3.4,0-6.7,0-10.1,0c-0.7,0-0.9-0.2-0.8-0.9
		c0.6-4,1.2-8,1.8-12c1.2-8,2.3-16,3.5-24c0.7-5,1.6-10.1,3.6-14.8c2-4.9,5-8.8,10.2-10.6c2.6-0.9,5.3-1.2,8-1.4
		c2.6-0.1,5.3-0.1,7.9,0.1c0.6,0.1,0.8,0.2,0.7,0.9c-0.6,3.8-1.2,7.6-1.8,11.4C233.3,31.5,233.3,32.1,232.4,31.8z'
        />
        <path
          fill={color1}
          d='M238.1,82.3c-1.7,0-3.4,0-5,0c-0.7,0-1,0-0.9-0.9c2.4-16.3,4.8-32.6,7.1-48.9c0.6-4.3,1.3-8.5,1.9-12.8
		c0.1-0.7,0.3-0.9,1-0.9c3.4,0,6.7,0,10.1,0c0.8,0,0.9,0.2,0.8,1c-1.9,13-3.8,25.9-5.7,38.9c-1.1,7.6-2.2,15.2-3.3,22.8
		c-0.1,0.7-0.4,0.9-1.1,0.9C241.4,82.2,239.7,82.3,238.1,82.3z'
        />
        <path
          fill={color1}
          d='M41.6,50.7c-0.9,0-1.8,0-2.8,0c-1.6,0-2.6-1.1-2.4-2.7c0.3-2,0.6-4,0.9-6c0.3-1.8,1.4-2.6,3.2-2.6
		c1.7,0,3.3,0,5,0c1.5,0,2.4,0.9,2.3,2.4c-0.2,2.2-0.5,4.4-0.9,6.6c-0.3,1.6-1.3,2.3-3,2.3C43.2,50.7,42.4,50.7,41.6,50.7z'
        />
      </g>
      <g>
        <path
          fill={secondaryColor2}
          d='M291.4,24.1c0.3,0,0.4-0.2,0.5-0.4c3-4.3,7.3-6.3,12.4-6.8c3.3-0.3,6.6,0,9.7,1.2c5.8,2.3,8.5,6.8,9.5,12.6
		c0.8,4.9,0.4,9.8-0.4,14.7c-1.3,8.5-2.5,17.1-3.7,25.6c-0.5,3.4-1,6.9-1.5,10.3c-0.1,0.6-0.3,0.9-1,0.9c-3.4,0-6.8,0-10.2,0
		c-0.6,0-0.9-0.1-0.8-0.8c1.2-8.3,2.5-16.7,3.7-25.1c0.8-5.7,1.9-11.4,2.3-17.2c0.1-1.9,0.1-3.7-0.5-5.6c-0.3-1-0.9-1.9-1.7-2.5
		c-3.5-2.6-9.5-1.4-11.6,2.4c-1.5,2.7-2,5.6-2.5,8.6c-1.9,13-3.8,26.1-5.7,39.1c-0.1,0.8-0.4,1-1.1,1c-3.3,0-6.6,0-10,0
		c-0.7,0-0.9-0.1-0.8-0.9c1.2-8.1,2.4-16.2,3.5-24.3c0.8-5.9,2-11.8,2.3-17.8c0.1-1.6,0-3.1-0.3-4.6c-0.5-2.5-2-4.1-4.5-4.5
		c-5.7-0.9-8.3,1-9.9,5.4c-1.2,3.3-1.6,6.9-2.1,10.4c-1.3,9.1-2.6,18.1-3.9,27.2c-0.4,2.6-0.8,5.2-1.1,7.8c-0.1,1-0.4,1.4-1.5,1.4
		c-3.2-0.1-6.3-0.1-9.5,0c-0.8,0-1.1-0.2-1-1.1c1.4-9.6,2.8-19.1,4.2-28.7c0.8-5.3,1.3-10.6,2.5-15.8c0.8-3.9,2-7.6,4.1-11
		c3.4-5.7,8.4-8.5,15-8.8c2.8-0.1,5.5,0.1,8.1,1C287.2,19,289.7,21.1,291.4,24.1z'
        />
        <path
          fill={secondaryColor2}
          d='M453.9,40.8c-0.6,9.8-1.9,20.3-6.2,30.2c-2.3,5.1-5.6,9.4-11.1,11.5c-6.3,2.4-12.7,2.4-19,0.1
		c-6.2-2.3-9.1-7.2-10.2-13.4c-1-5.5-0.7-11,0-16.5c0.9-6.8,2.1-13.6,4.6-20.1c1.5-3.9,3.6-7.6,6.7-10.4c4.9-4.5,10.9-5.5,17.3-5.1
		c2.3,0.1,4.6,0.6,6.8,1.4c6.2,2.4,9.3,7.1,10.4,13.5C453.7,34.5,453.9,37.2,453.9,40.8z M442,40.9c0-1.8-0.1-3.7-0.5-5.5
		c-0.7-2.9-2.5-4.7-5.4-5.4c-0.6-0.1-1.3-0.3-1.9-0.3c-6-0.5-9.2,1.4-11.2,6.4c-0.3,0.8-0.7,1.7-0.9,2.6c-1.6,5.5-2.5,11.1-3.1,16.8
		c-0.3,3-0.5,6,0,9.1c0.4,2.9,1.5,5.4,4.6,6.3c6.5,1.9,11.8,0.4,14.3-6.1c0.2-0.6,0.4-1.1,0.6-1.7C440.6,55.8,441.6,48.4,442,40.9z'
        />
        <path
          fill={secondaryColor2}
          d='M355.7,70.2c2.8,0,5.7-0.1,8.5-0.3c0.7-0.1,0.8,0.2,0.7,0.8c-0.6,3.5-1.2,7-1.8,10.5c-0.1,0.6-0.3,0.8-0.9,0.8
		c-5.7,0.5-11.3,0.7-17,0.5c-9.2-0.4-15.1-5.7-16.7-14.8c-1.2-6.6-0.7-13.1,0.3-19.6c0.9-5.7,2.1-11.3,4.5-16.5
		c1.2-2.6,2.5-5.1,4.3-7.3c3.3-4.1,7.6-6.4,12.9-7.1c3.2-0.4,6.4-0.4,9.5,0.2c6.4,1.2,10.7,5.3,11.8,11.7c1.1,6,0.3,11.8-2,17.4
		c-2.4,5.7-6.9,8.4-12.8,9.4c-5.1,0.9-10.2,0.4-15.3,0.5c-2.5,0-2.5,0-2.5,2.5c0,1.4,0,2.9,0.3,4.3c0.7,4.5,3.4,6.9,7.9,7.1
		C350.2,70.3,353,70.2,355.7,70.2z M348.3,44.8c1.5,0,3.5,0,5.5-0.2c2.4-0.2,4.6-0.9,5.8-3.2c1.2-2.4,1.8-4.9,1.5-7.6
		c-0.2-2-1.2-3.5-3.1-4.3c-5.4-2.2-11.6,0.1-14.1,5.3c-1.4,3-2.1,6.1-2.8,9.3c-0.1,0.6,0.2,0.6,0.6,0.6
		C343.7,44.8,345.8,44.8,348.3,44.8z'
        />
        <path
          fill={secondaryColor2}
          d='M377.7,82.3c-1.7,0-3.4,0-5.2,0c-0.6,0-0.8-0.2-0.7-0.8c1.3-8.7,2.5-17.4,3.8-26.1c0.8-5.4,1.4-10.8,2.5-16.2
		c0.7-3.4,1.7-6.7,3.1-9.9c2.9-6.4,7.9-9.8,14.8-10.5c4.2-0.4,8.3-0.2,12.5,0c0.6,0,0.8,0.2,0.6,0.9c-1.1,3.8-2.3,7.6-3.3,11.5
		c-0.2,0.7-0.5,0.7-1.1,0.7c-2.6-0.2-5.1-0.3-7.7,0.1c-2.7,0.4-4.5,1.8-5.7,4.2c-1.4,2.9-1.9,6-2.4,9.1c-1.8,12.1-3.6,24.1-5.3,36.2
		c-0.1,0.8-0.4,1-1.2,0.9C380.9,82.2,379.3,82.3,377.7,82.3z'
        />
        <path
          fill={secondaryColor2}
          d='M5.8,82.3c-1,0-1.9,0-2.9,0c-1.5-0.1-2.4-1-2.3-2.5c0.2-2.2,0.6-4.4,1-6.6c0.3-1.6,1.4-2.3,3-2.3
		c1.7,0,3.4,0,5,0c1.7,0,2.6,1,2.5,2.6c-0.2,2-0.4,4-0.8,6C11,81.4,10,82.2,8,82.2C7.3,82.3,6.5,82.3,5.8,82.3z'
        />
        <path
          fill={secondaryColor2}
          d='M22.7,70.9c0.9,0,1.8,0,2.6,0c1.5,0,2.5,1.1,2.3,2.7c-0.2,1.9-0.5,3.8-0.7,5.7c-0.4,2.3-1.3,3-3.6,3
		c-1.6,0-3.1,0-4.7,0c-1.8,0-2.7-1-2.5-2.9c0.2-1.9,0.5-3.8,0.8-5.7c0.3-2,1.4-2.9,3.4-2.9C21.2,70.9,21.9,70.9,22.7,70.9z'
        />
        <path
          fill={secondaryColor2}
          d='M52.3,82.3c-1,0-1.9,0-2.9,0c-1.5-0.1-2.4-1-2.3-2.5c0.2-2.2,0.6-4.4,1-6.6c0.3-1.6,1.4-2.3,3-2.3
		c1.7,0,3.4,0,5.1,0c1.5,0,2.4,1,2.3,2.4c-0.2,2.2-0.4,4.3-0.8,6.5c-0.3,1.8-1.3,2.4-3.1,2.5C53.9,82.3,53.1,82.3,52.3,82.3z'
        />
        <path
          fill={secondaryColor2}
          d='M38.1,70.9c0.9,0,1.8,0,2.8,0c1.4,0,2.4,0.9,2.3,2.3c-0.2,2.3-0.5,4.5-0.9,6.7c-0.3,1.6-1.3,2.3-3,2.3
		s-3.4,0-5.1,0c-1.8,0-2.7-1.2-2.5-3c0.3-1.8,0.6-3.6,0.8-5.4c0.3-2.1,1.3-3,3.4-3C36.7,70.9,37.4,70.9,38.1,70.9z'
        />
        <path
          fill={secondaryColor2}
          d='M44,34.9c-1,0-1.9,0-2.9,0c-1.5,0-2.5-1.1-2.3-2.6c0.3-2.1,0.6-4.3,1-6.4c0.3-1.7,1.4-2.4,3-2.4
		c1.7,0,3.4,0,5.1,0c1.6,0,2.5,0.9,2.3,2.5c-0.2,2.1-0.5,4.3-0.8,6.4c-0.3,1.7-1.3,2.4-3.1,2.5C45.6,34.9,44.8,34.9,44,34.9z'
        />
        <path
          fill={secondaryColor2}
          d='M8.1,66.5c-1,0-1.9,0-2.9,0c-1.4-0.1-2.4-1-2.2-2.4c0.2-2.2,0.6-4.5,1-6.7c0.3-1.6,1.3-2.3,3-2.3s3.4,0,5.1,0
		c1.5,0,2.4,1,2.3,2.4c-0.2,2.2-0.5,4.3-0.9,6.5c-0.3,1.8-1.3,2.4-3.1,2.4C9.7,66.5,8.9,66.5,8.1,66.5L8.1,66.5z'
        />
        <path
          fill={secondaryColor2}
          d='M23.7,66.5c-1,0-1.9,0-2.9,0c-1.5-0.1-2.5-1.1-2.3-2.6c0.3-2,0.5-4.1,0.9-6.1c0.3-1.8,1.4-2.6,3.2-2.6
		c1.7,0,3.4,0,5,0c1.5,0,2.5,1.1,2.4,2.5c-0.2,2.1-0.5,4.1-0.8,6.1s-1.3,2.7-3.3,2.7C25.2,66.5,24.4,66.5,23.7,66.5L23.7,66.5z'
        />
        <path
          fill={secondaryColor2}
          d='M54.7,66.5c-1,0-1.9,0-2.9,0c-1.4-0.1-2.4-1-2.3-2.3c0.2-2.3,0.6-4.6,1-6.8c0.3-1.6,1.4-2.2,3-2.2
		c1.7,0,3.4,0,5.1,0c1.5,0,2.4,0.9,2.3,2.4c-0.2,2.2-0.4,4.3-0.8,6.5c-0.3,1.8-1.3,2.4-3.1,2.4C56.3,66.5,55.5,66.5,54.7,66.5
		L54.7,66.5z'
        />
        <path
          fill={secondaryColor2}
          d='M12.8,34.9c-0.9,0-1.8,0-2.8,0c-1.4,0-2.4-0.9-2.3-2.3c0.2-2.3,0.6-4.6,1-6.8c0.3-1.6,1.3-2.2,3-2.2
		s3.4,0,5.1,0c1.6,0,2.5,1,2.4,2.6c-0.2,2-0.5,4-0.8,6s-1.3,2.7-3.3,2.7C14.4,34.9,13.6,34.9,12.8,34.9z'
        />
        <path
          fill={secondaryColor2}
          d='M26,50.7c-1,0-1.9,0-2.9,0c-1.4-0.1-2.4-1.1-2.2-2.5c0.3-2,0.5-4.1,0.9-6.1c0.3-1.9,1.4-2.7,3.3-2.7
		c1.7,0,3.4,0,5,0c1.5,0,2.4,1.1,2.3,2.6c-0.2,2-0.5,4-0.8,6s-1.2,2.7-3.3,2.7C27.6,50.7,26.8,50.7,26,50.7L26,50.7z'
        />
        <path
          fill={secondaryColor2}
          d='M28.4,34.9c-1,0-1.9,0-2.9,0c-1.5,0-2.5-1.1-2.3-2.6c0.3-2,0.6-4,0.9-6c0.3-1.9,1.4-2.7,3.3-2.7
		c1.7,0,3.4,0,5,0c1.5,0,2.5,1.1,2.3,2.5c-0.2,2.1-0.5,4.1-0.8,6.1c-0.3,1.9-1.3,2.6-3.2,2.6C30,34.9,29.2,34.9,28.4,34.9z'
        />
        <path
          fill={secondaryColor2}
          d='M10.5,50.7c-1,0-1.9,0-2.9,0c-1.4,0-2.3-0.9-2.2-2.3c0.2-2.3,0.5-4.7,1-6.9c0.4-1.5,1.3-2.1,2.9-2.1
		c1.7,0,3.4,0,5.1,0c1.6,0,2.6,1.1,2.4,2.7c-0.2,2-0.5,3.9-0.8,5.9s-1.3,2.7-3.4,2.7C12,50.7,11.3,50.7,10.5,50.7z'
        />
        <path
          fill={secondaryColor2}
          d='M57.1,50.7c-1,0-2,0-3,0c-1.3-0.1-2.2-0.9-2.1-2.2c0.2-2.4,0.5-4.7,1.1-7.1c0.4-1.5,1.3-2.1,2.8-2.1
		c1.7,0,3.4,0,5.1,0c1.7,0,2.6,1,2.5,2.6C63.3,44,63.1,46,62.7,48c-0.3,1.8-1.3,2.5-3.2,2.6C58.7,50.7,57.9,50.7,57.1,50.7
		L57.1,50.7z'
        />
        <path
          fill={secondaryColor2}
          d='M39.1,66.5c-1,0-1.9,0-2.9,0c-1.5-0.1-2.4-1.2-2.2-2.6c0.3-2,0.6-4.1,0.9-6.1c0.3-1.8,1.4-2.6,3.2-2.6
		c1.7,0,3.3,0,5,0c1.6,0,2.5,1,2.4,2.6c-0.2,2.1-0.5,4.2-0.8,6.3c-0.3,1.7-1.3,2.5-3.1,2.5C40.8,66.5,40,66.5,39.1,66.5L39.1,66.5z'
        />
        <path
          fill={secondaryColor2}
          d='M59.5,34.9c-1,0-1.9,0-2.9,0c-1.4,0-2.3-0.9-2.2-2.2c0.2-2.4,0.5-4.7,1.1-7.1c0.4-1.4,1.3-2,2.8-2
		c1.7,0,3.4,0,5.1,0s2.6,1,2.4,2.7c-0.2,2-0.5,4-0.8,6c-0.3,1.9-1.3,2.6-3.2,2.7C61.1,34.9,60.3,34.9,59.5,34.9z'
        />
      </g>
    </svg>
  );
};

export default InternalSettings;
