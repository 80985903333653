import React from 'react';

import pallete from '../../pallete';

const MySpace = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <ellipse {...st0} cx='16' cy='5.6' rx='11.9' ry='2.8' />
        <path
          {...st1}
          d='M27.9,19.7c0,1.5-5.3,2.8-11.9,2.8S4.1,21.2,4.1,19.7'
        />
        <path
          {...st1}
          d='M27.9,12.6c0,1.5-5.3,2.8-11.9,2.8S4.1,14.2,4.1,12.6'
        />
        <path
          {...st0}
          d='M27.9,26.4c0,1.5-5.3,2.8-11.9,2.8S4.1,27.9,4.1,26.4'
        />
        <line {...st0} x1='4.1' y1='5.6' x2='4.1' y2='26.4' />
        <line {...st0} x1='27.9' y1='5.6' x2='27.9' y2='26.4' />
      </g>
    </svg>
  );
};

export default MySpace;
