import React from 'react';

import pallete from '../pallete';

const ChevronDown = ({
  color = pallete.backgroundWhite,
  height = 6,
  width = 12,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.41 0.693115L6 3.79135L10.59 0.693115L12 1.64694L6 5.70576L-6.16331e-08 1.64694L1.41 0.693115Z'
        fill={color}
      />
    </svg>
  );
};

export default ChevronDown;
