import React, { createContext, useReducer } from 'react';

import {
  getLanguageDirection,
  getToken,
  removeToken,
  setToken,
} from '../../utils';
import { DEFAULT_LOCALE, LOCALE_OPTIONS } from '../config/constants';

const getCurrentLanguage = () => {
  const language = JSON.parse(localStorage.getItem('language') || '{}');

  if (language?.value) {
    return {
      ...language,
      direction: getLanguageDirection(language.value),
    };
  }

  const defaultLanguage = LOCALE_OPTIONS.find(
    (el) => el.value === DEFAULT_LOCALE,
  );
  if (defaultLanguage) return defaultLanguage;
  return LOCALE_OPTIONS[0];
};

const initialState = {
  user: null,
  isAuth: false,
  databases: null,
  companySelected: 'Roof',
  navLabelSelected: 'default',
  hasToken: !!getToken(),
  currentLanguage: getCurrentLanguage(),
  objects: undefined,
  expertMode: {},
  loading: false,
};

const StoreContext = createContext(initialState);
const DispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      setToken(action.payload.token);
      return {
        ...state,
        user: action.payload.user,
        isAuth: true,
        hasToken: true,
      };

    case 'SET_USER':
      if (action.payload) {
        return {
          ...state,
          user: action.payload,
          isAuth: true,
          hasToken: true,
        };
      }
      return state;

    case 'UPDATE_USER':
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case 'LOGOUT':
      removeToken();
      localStorage.removeItem('space');
      sessionStorage.removeItem('location');
      return {
        ...state,
        user: null,
        databases: null,
        isAuth: false,
        hasToken: false,
        loading: false,
      };
    case 'SET_EXPERT_MODE':
      return {
        ...state,
        expertMode: { ...state.expertMode, ...action.payload },
      };
    case 'SET_LANGUAGE':
      localStorage.setItem('language', JSON.stringify(action.payload));
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case 'SET_OBJECTS':
      return {
        ...state,
        objects: action.payload,
      };
    case 'SET_COMPANY':
      return {
        ...state,
        companySelected: action.payload,
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'FINISH_LOADING':
      return {
        ...state,
        loading: false,
      };
    default:
      throw new Error();
  }
};

const StoreProvider = ({ children }) => {
  const [store, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    </DispatchContext.Provider>
  );
};

export { DispatchContext, StoreContext, StoreProvider };
