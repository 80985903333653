import React from 'react';

import pallete from '../../pallete';

const ManageUserSuggestions = ({
  color = pallete.primary,
  color2 = pallete.secondary1,
  height = 17,
  width = 17,
}) => {
  const st0 = {
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  const st1 = {
    stroke: color2,
    strokeWidth: 1.5,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    fill: 'none',
  };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <path
          {...st0}
          d='M27,23.8H13.8l-7,6.5L7,23.8H5c-1.8,0-3.2-1.4-3.2-3.2V4.9c0-1.8,1.4-3.2,3.2-3.2H27c1.8,0,3.2,1.4,3.2,3.2
		v15.7C30.3,22.3,28.8,23.8,27,23.8z'
        />
        <g>
          <path
            {...st1}
            d='M20.8,9.5c0.3,2-0.5,3.7-1.9,4.8c-0.6,0.5-0.9,1.2-0.9,2v2.1c0,0.5-0.4,1-1,1H15c-0.5,0-1-0.4-1-1v-2.1
			c0-0.8-0.3-1.6-1-2.1c-1.2-0.9-2-2.3-2-3.9c0-3,2.8-5.4,5.9-4.8C19,6,20.5,7.6,20.8,9.5z'
          />
          <line {...st1} x1='14.1' y1='16.4' x2='17.9' y2='16.4' />
        </g>
      </g>
    </svg>
  );
};

export default ManageUserSuggestions;
